import { connect } from 'react-redux';
import { AppState } from '../../state/AppState';
import { Button, Stack, Typography, Box, Grid, Divider, CircularProgress, Alert } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate, useParams } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { getGuestOrderDetails } from '../../store/actions/CheckoutActions';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { getFormatedNumber } from '../../utilities/numberFormat';
import { replaceComma } from '../../services/HelperFunctions';
import {useGoogleMapsApi} from '../../utilities/hooks/useGoogleMapsApi';
import { GoogleMap, Marker } from '@react-google-maps/api';

const OrderConfirmation = (props: { organizationSettings?: any, organization: any, orderGet: any }) => {
    const navigate = useNavigate();
    const {id} = useParams();
    const { organizationSettings, organization, orderGet } = props;

    
    const [orderData, setOrderData] = useState<any>(null);
    const [isLoading,setIsloading] = useState(false);
    const [error, setError] = useState(false)
    const [coords, setCoords] = useState({ lat: 39.6247, lng: -104.9043 });




const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;




    useEffect(()=> {
        setIsloading(true)
        orderGet(id || -1).then((res: any)=> {
            
            setIsloading(false)
            setOrderData(res?.data)
            if(!res?.data){
                setError(true)
            }
        })
    }, [])

    useEffect(() => {
        const fetchGeocode = async (url:any) => {
            
          try {
            const response = await fetch(url);
            const data = await response.json();
    
            if (data.results && data.results.length > 0) {
              const { lat, lng } = data.results[0].geometry.location;
              setCoords({ lat, lng });
            } else {
              console.error("No results found for the given address");
            }
          } catch (error) {
            console.error("Error fetching geocode:", error);
          }
        };
    
        let geocodeUrl;
    
        if (orderData?.type === "Pickup") {
          const fullAddress = `${organization?.address1}, ${organization?.city}, ${organization?.stateProvince}, ${organization?.country}`;          
          geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(fullAddress)}&key=${apiKey}`;
        } else if (orderData?.type === "Delivery") {
          const deliveryAddress = `${orderData?.orderDelivery?.address1}, ${orderData?.orderDelivery?.city}, ${orderData?.orderDelivery?.stateProvince}`;
          geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(deliveryAddress)}&key=${apiKey}`;
        } else if (orderData?.type === "Event"){
            const eventDeliveryAddress = `${orderData?.orderDelivery?.address1}, ${orderData?.orderDelivery?.city}, ${orderData?.orderDelivery?.stateProvince}`;
            geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(eventDeliveryAddress)}&key=${apiKey}`;
          }
    
        if (geocodeUrl) {
          fetchGeocode(geocodeUrl);
        }
      }, [orderData?.type, organization, orderData?.orderDelivery, orderData?.orderDeliveryAddress, apiKey])

      const { apiLoaded } = useGoogleMapsApi();
            
      const shouldShowMap = apiLoaded;
      

    return <>
    {isLoading && <Stack sx={{p: 10}} alignItems={'center'} justifyContent={'center'}><CircularProgress color='warning' /></Stack>}
    {!isLoading && !error && <Box>
        <Button
            sx={{ position: 'absolute', left: 0, color: '#000000' }}
            onClick={() => window.print()
            }>
            <PrintIcon fontSize='small' />
        </Button>
        <Stack direction={'column'} alignItems={'center'} justifyContent={'center'} p={5}>
            <Typography variant='h4'>Thank You!</Typography>
            <Typography>Your order has been confirmed — <strong>Order #{orderData?.organizationOrderId}</strong></Typography>
            <Typography mt={2} sx={{ textAlign: { xs: 'center', sm: 'center' } }}>{organizationSettings?.ordersConfirmationMessage}</Typography>
        </Stack>
        <Grid container px={{ xs: 0, md: 8 }}>
            <Grid item xs={12} md={6} px={{ xs: 5, md: 8 }}>
                {orderData?.type==="Pickup" && <Grid item xs={12} sm={12}>
                    <Grid container>
                        <Grid item xs={12} sm={12} sx={{ textAlign: { xs: 'center', md: 'left' }, borderBottom: '1px solid #b7b7b7' }}>
                            <Typography variant='h6'>Pickup Information</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} mt={2}>
                            <Typography align='center'>Estimated Pickup Time: {moment(orderData?.requestedDateTime).format('D MMM YYYY, h.mma')}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {/* <Typography align='center' sx={{ color: 'red' }}>
                                {`displayTime = !!requestedDateTime && requestedDateTime > estimatedTime ? requestedDateTime : estimatedTime`}
                            </Typography> */}
                        </Grid>
                        <Grid container xs={12} sm={12} mt={2} mb={2}>
                            <Stack flex={1} maxHeight={800} maxWidth={800}>
                                {shouldShowMap ? (
                                    <GoogleMap center={coords} zoom={15} mapContainerStyle={{ flex: 1, width: "100%" }}>
                                        {coords && <Marker key={coords.lat} position={coords} />}
                                        </GoogleMap>
                                        ) : (
                                        <Box display="flex" flex={1} alignItems="center" justifyContent="center" width={1}>
                                        <CircularProgress />
                                        </Box>
                                        )}
                            </Stack>
                            <Grid item xs={12} md={6} sx={{ textAlign: { xs: 'center', md: 'left', paddingLeft: '20px' } }}>
                                <Stack mt={{ xs: 2, md: 0 }} pl={{ xs: 2, md: 0 }}>
                                    <Typography>{organization?.name}</Typography>
                                    <Typography>{`${organization?.address1 ? `${replaceComma(organization?.address1)},`: ""} ${organization?.address2 ? `${organization?.address2}`: ""}`}</Typography>
                                    <Typography>{organization?.city ? `${organization?.city},`: ""} {organization?.stateProvince ? `${organization?.stateProvince},`: ""} {organization?.postalCode ? `${organization?.postalCode}`:""}</Typography>
                                    <Typography>{organization?.contactPhone}</Typography>
                                    {/* <Typography mt={1}>Guest Count: </Typography> */}
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}
                {orderData?.type==="Delivery" && <Grid item xs={12} sm={12}>
                    <Grid container>
                        <Grid item xs={12} sm={12} sx={{ textAlign: { xs: 'center', md: 'left' }, borderBottom: '1px solid #b7b7b7' }}>
                            <Typography variant='h6'>Delivery Information</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} mt={2}>
                            <Typography align='center'>Estimated Delivery Time:  {moment(orderData?.requestedDateTime).format('D MMM YYYY, h.mma')}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {/* <Typography align='center' sx={{ color: 'red' }}>
                                {`displayTime = !!requestedDateTime && requestedDateTime > estimatedTime ? requestedDateTime : estimatedTime`}
                            </Typography> */}
                        </Grid>
                        <Grid container xs={12} sm={12} mt={2} mb={2}>
                                <Stack flex={1} maxHeight={800} maxWidth={800}>
                                    {shouldShowMap ? (
                                    <GoogleMap center={coords} zoom={15} mapContainerStyle={{ flex: 1, width: "100%" }}>
                                        {coords && <Marker key={coords.lat} position={coords} />}
                                    </GoogleMap>
                                    ) : (
                                    <Box display="flex" flex={1} alignItems="center" justifyContent="center" width={1}>
                                        <CircularProgress />
                                    </Box>
                                    )}
                                </Stack>
                            <Grid item xs={12} md={6} sx={{ textAlign: { xs: 'center', md: 'left', paddingLeft: '20px' } }}>
                                <Stack mt={{ xs: 2, md: 0 }} pl={{ xs: 2, md: 0 }}>
                                    <Typography>{orderData?.customerName}</Typography>
                                    <Typography>{`${orderData?.orderDelivery?.address1 ? `${orderData?.orderDelivery?.address1},`: ""} ${orderData?.orderDelivery?.address2 ? `${orderData?.orderDelivery?.address2}`: ""}`}</Typography>
                                    <Typography>{orderData?.orderDelivery?.city ? `${orderData?.orderDelivery?.city},`: ""} {orderData?.orderDelivery?.stateProvince ? `${orderData?.orderDelivery?.stateProvince},`: ""} {orderData?.orderDelivery?.postalCode ? `${orderData?.orderDelivery?.postalCode}`:""}</Typography>
                                    <Typography>{orderData?.customerPhone}</Typography>
                                    {/* <Typography mt={1}>Guest Count: </Typography> */}
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>}
                {orderData?.type==="Event" && <Grid item xs={12} sm={12} >
                    <Grid container>
                        <Grid item xs={12} sm={12} sx={{ textAlign: { xs: 'center', md: 'left' }, borderBottom: '1px solid #b7b7b7' }}>
                            <Typography variant='h6'>Event Information</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={6} sm={6}>
                                    <Typography>Event Name</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography align='right'>{orderData?.orderEventDetails?.eventName || ""}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={6} sm={6}>
                                    <Typography>Date of Event</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography align='right'>{moment(orderData?.orderEventDetails?.eventStartTime).format('D MMM YYYY')}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={6} sm={6}>
                                    <Typography>Start Time</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography align='right'>{moment(orderData?.orderEventDetails?.eventStartTime).format('h.mm a')}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={6} sm={6}>
                                    <Typography>End Time</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography align='right'>{moment(orderData?.orderEventDetails?.eventEndTime).format('h.mm a')}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={6} sm={6}>
                                    <Typography>Guest Count</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography align='right'>{orderData?.expectedGuestCount}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={6} sm={6}>
                                    <Typography>Theme</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography align='right'>{orderData?.orderEventDetails?.theme?.name}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={6} sm={6}>
                                    <Typography>Category</Typography>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography align='right'>{orderData?.orderEventDetails?.category?.name}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container xs={12} sm={12} mt={2}   style={{ height: "250px", }}>
                            <Stack flex={1} maxHeight={800} maxWidth={800}>
                            {shouldShowMap ? (
                            <GoogleMap center={coords} zoom={15} mapContainerStyle={{ flex: 1, width: "100%" }}>
                            {coords && <Marker key={coords.lat} position={coords} />}
                            </GoogleMap>
                            ) : (
                            <Box display="flex" flex={1} alignItems="center" justifyContent="center" width={1}>
                                <CircularProgress />
                            </Box>
                            )}
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>}
            </Grid>
            <Grid item xs={12} md={6} px={{ xs: 5, md: 10 }} mt={{ xs: 5, md: 0 }}>
                <Grid container>
                    <Grid item xs={12} sm={12} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                        <Typography variant='h6'>Order Summary</Typography>
                    </Grid>
                </Grid>
                {orderData?.orderItems?.length>0 && <>
                <Grid item xs={12} sm={12}>
                    <Grid container>
                        <Grid item xs={8} sm={8}>
                            <Typography sx={{ color: '#B7B7B7', fontSize: { xs: '18px' }, mb: 0 }}> Name</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Typography sx={{ color: '#B7B7B7', fontSize: { xs: '18px' }, mb: 0 }}>Qty</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Typography sx={{ color: '#B7B7B7', fontSize: { xs: '18px' }, mb: 0 }} align='right'>Price</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
                {orderData?.orderItems?.map((item: any) => {
                        return (
                            <Grid item xs={12} sm={12} key={item?.id}>
                                <Grid container>
                                <Grid item xs={8} sm={8}>
                                    <Typography noWrap>{item?.name}</Typography>
                                    <Box>
                                        {item?.orderItemModifiers?.map((modifier: any) => {
                                            return (
                                                <div key={modifier?.id}>
                                                    <Typography sx={{fontSize: "17px !important"}}>{modifier.name}</Typography>
                                                    <Typography sx={{lineHeight:'16px',mb:1}}>
                                                        {modifier?.options?.map((option: any, index: number) => {
                                                            return (
                                                                <span key={option?.id} style={{fontSize:"12px"}}>
                                                                    {option?.name}
                                                                    {option?.subOptions && option.subOptions.length > 0 && (
                                                                        <span style={{fontSize:"12px"}}>
                                                                            {" ("}
                                                                            {option.subOptions.map((subOption: any, subIndex: number) => (
                                                                                <span key={subOption?.id}>
                                                                                    {subOption?.name}
                                                                                    {subIndex < option.subOptions.length - 1 ? ', ' : ''}
                                                                                </span>
                                                                            ))}
                                                                            {")"}
                                                                        </span>
                                                                    )}
                                                                    {index < modifier.options.length - 1 ? ', ' : ''}
                                                                </span>
                                                            );
                                                        })}
                                                    </Typography>
                                                </div>
                                            );
                                        })}
                                    </Box>
                                </Grid>
                <Grid item xs={2} sm={2}>
                    <Typography>{item?.quantity}</Typography>
                </Grid>
                <Grid item xs={2} sm={2}>
                    <Typography align="right">${getFormatedNumber(item?.totalPrice)}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
})}
                </>}
                <Divider />
                {/* <Grid item xs={12} sm={12}>
                    <Grid container>
                        <Grid item xs={10} sm={10}>
                            <Typography>Room Rate</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Typography align='right'>${getFormatedNumber(orderData?.roomRate)}</Typography>
                        </Grid>
                    </Grid>
                </Grid> */}
                <Grid item xs={12} sm={12}>
                    <Grid container>
                        <Grid item xs={10} sm={10}>
                            <Typography>Subtotal</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Typography align='right'>${getFormatedNumber(orderData?.subTotal)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container>
                        <Grid item xs={10} sm={10}>
                            <Typography>Service Charge</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Typography align='right'>${getFormatedNumber(orderData?.serviceCharge)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container>
                        <Grid item xs={10} sm={10}>
                            <Typography>Gratuity</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Typography align='right'>${getFormatedNumber(orderData?.gratuity)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid container>
                        <Grid item xs={10} sm={10}>
                            <Typography>Tax</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Typography align='right'>${getFormatedNumber(orderData?.taxTotal)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item xs={12} sm={12}>
                    <Grid container>
                        <Grid item xs={10} sm={10}>
                            <Typography>Delivery Charge</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Typography align='right'>${getFormatedNumber(orderData?.serviceCharge)}</Typography>
                        </Grid>
                    </Grid>
                </Grid> */}
                {organizationSettings?.enableAddTip &&
                <Grid item xs={12} sm={12}>
                    <Grid container>
                        <Grid item xs={10} sm={10}>
                            <Typography>Tip</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Typography align='right'>${getFormatedNumber(orderData?.tip)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                }
                {orderData?.deliveryFee > 0 &&
                 <Grid item xs={12} sm={12}>
                    <Grid container>
                        <Grid item xs={10} sm={10}>
                            <Typography>Delivery Fee</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Typography align='right'>${getFormatedNumber(orderData?.deliveryFee || 0)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                }
                {orderData?.surcharge!==0 && <Grid item xs={12} sm={12}>
                    <Grid container>
                        <Grid item xs={10} sm={10}>
                            <Typography>Surcharge:</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Typography align='right'>${getFormatedNumber(orderData?.surcharge || 0)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>}
                <Divider />
                <Grid item xs={12} sm={12}>
                    <Grid container>
                        <Grid item xs={10} sm={10}>
                            <Typography>Order Total</Typography>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Typography align='right'>${getFormatedNumber(orderData?.total)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container px={{ xs: 0, md: 8 }}>
                <Grid item xs={12} sm={12}>
                    <Grid container sx={{ textAlign: { xs: 'center', md: 'left' } }} mt={5}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='h6'>Notes</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{orderData?.notes || "No Notes"}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container px={{ xs: 0, md: 8 }}>
                <Grid item xs={12} sm={12}>
                    <Grid container mt={5} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                        <Grid item xs={12} sm={12}>
                            <Typography variant='h6'>Cancellation Policy</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{organizationSettings?.ordersCancellationPolicy}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Grid container textAlign={'center'} p={5}>
                        <Grid item xs={12} sm={12}>
                            <Button
                                variant='contained'
                                color='secondary'
                                style={{ borderRadius: '50px', marginTop: 20 }}
                                onClick={() => navigate('/')}
                            >
                                BACK TO SHOPPING
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    </Box>}
    {!isLoading && error && <Stack spacing={6} sx={{p:4,pt:8,alignItems:'center'}}>
        <Box sx={{textAlign: 'center',fontSize:'30px'}}> 
                Order Not Found in New Location
            </Box>
        <Box sx={{textAlign: 'center'}}> 
            <Typography>
                You have changed the location of this Graze program.
            </Typography>
            <Typography>
                The order number you are looking for does not exist in this new location.
            </Typography>
            </Box>
                <Button
                variant='contained'
                color='layoutColor'
                style={{  marginTop: 70 ,borderRadius:'8px',width:'320px',fontSize:'18px'}}
                onClick={() => navigate('/')}>
                    Place order
                </Button>
    </Stack>}
    </>
}

const mapStateToProps = (state: AppState) => ({
    organizationSettings: state?.organizations?.organizationSettings,
    organization: state?.organizations?.organization,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({
        orderGet: getGuestOrderDetails,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrderConfirmation as any);