import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    CircularProgress,
    FormControl,
    Grid,
    Hidden,
    IconButton,
    MenuItem,
    Snackbar,
    SnackbarContent,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { Theme, lighten } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import * as AppActions from '../../store/actions/App.Actions';
import * as ContactActions from '../../store/actions/Contact.Actions';
import * as OrderActions from '../../store/actions/Order.Actions';
import * as PaymentActions from '../../store/actions/Payment.Action';
// import { getEventPickLists } from '../../store/actions/PickListActions';
import { getRooms } from '../../store/actions/RoomActions';
import { getSettingAreas } from '../../store/actions/SettingAreasActions';

import * as LocalStorage from '../../utilities/LocalStorage';

// import { withRouter } from 'react-router-dom';
// import { RouteComponentProps } from 'react-router-dom';
import {
    ExpandMoreOutlined,
    CheckBox, CheckBoxOutlineBlank,
    Close,
    ClearOutlined,
    DeleteOutlined,
    PersonOutlineOutlined,
} from '@mui/icons-material';
import {
    Checkbox
} from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
// import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
// import TextareaAutosize from '@mui/material/TextareaAutosize';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { UserState } from 'redux-oidc';
import SimpleReactValidator from 'simple-react-validator';
import { v4 as uuidv4 } from 'uuid';
import AddressAutocomplete from '../../components/AddressAutocomplete';
import ItemQtyDropdown from '../../components/ItemQtyDropdown';
import { DialogActions, DialogContent, DialogTitle } from '../../components/dialog/DialogTitle';
import IAddress from '../../models/IAddress';
import IContact from '../../models/IContact';
import ICustomer from '../../models/ICustomer';
import IMenuCategoryItem from '../../models/IMenuCategoryItem';
import IOrder from '../../models/IOrder';
import IOrderItem from '../../models/IOrderItem';
import IOrganizationState from '../../models/IOrganization';
import IPaymentTransaction from '../../models/IPaymentTransaction';
import IRoom from '../../models/IRoom';
import ISetting from '../../models/ISetting';
import ISetupStyle from '../../models/ISetupStyle';
import { formatMoney } from '../../pipes/PriceFormatter';
import { handleAddressSearchDone } from '../../services/Address';
import {  normalizePhoneInput, replaceComma, showString } from '../../services/HelperFunctions';
import { checkIfOpenUsingHoursandScheduledTime, getDay, getFormattedHolidays, getWeeklySchedule, isClosed, roundToNext15Min } from '../../services/IsClosed';
import { AppState } from '../../state/AppState';
import { IApplicationProps, openProductDetailPageModel } from '../../store/actions/App.Actions';
import { createGuestOrder, getCalculatedPayment, getPaymentCardDetails, getUndeliverablePostalCodes, postCreatePaymentLink, postPaymentForOrder, getCalculatedEstimatedTime, getSetupIntentDetail } from '../../store/actions/CheckoutActions';
import { UpsertAddress } from '../../store/actions/CustomerActions';
import { selectedCategoryMenuProduct } from '../../store/actions/Menu.Action';
import { getFormatedNiceDate } from '../../utilities/GetFormatedNiceDate';
import { withRouter } from '../../utilities/withRouter';
import AddressSelector from './AddressSelector';
import './ProceedToCheckout.css';
import { getCheckedOptions, getReorderModifiersPrice, transformArrayFromModifiers } from '../../utilities/modifiers';
import { getEventPicklistsOptions, getPickListsByOrganization } from '../../store/actions/Organization.Actions';
import { Auth0WithOutRedirect } from '../../services/Utilities';
import useGrazeAuth0Lock from '../../utilities/hooks/useGrazeAuth0Lock';
// import { useAuth0 } from '@auth0/auth0-react';
import TimeSelection from '../../components/TimeSelector';
import { Link } from '@mui/material';
import axios, { AxiosError, AxiosResponse } from 'axios';
import SkipifyIntegration from '../../components/SkipifyIntegration';
const classNames = require('classnames');

// const couponErrorMessage = 'Invalid or Inactive Coupon';

const OrderTypeButton = styled(Button)<ButtonProps>(({ theme }) => ({
    minWidth: '100px',
    width: '140px',
    color: '#000000',
    borderRadius: '16px',
    textTransform: 'none',
    padding: '24px 20px',
    cursor: 'pointer',
}));

const StyledRadioButton = styled(Radio)({
    color: '#c0c0c0',
    '&.Mui-checked': {
        color: '#000000',
    },
    "& .MuiSvgIcon-root": {
        height: 26,
        width: 26,
    },
});

const CustomScrollbarBox = styled(Box)({
    maxHeight: '260px',
    overflowY: 'auto',
    width: '100%',
    padding: '12px',
    '&::-webkit-scrollbar': {
      width: '0.3em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)'
    }
  });

const StyledTextField = styled(TextField)({
    '& fieldset': {
        borderRadius: '8px',
    },
    '& .MuiInputBase-input': { 
        fontSize: '18px',
    },
    '& .MuiFormLabel-root': {
        fontSize: '18px',
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root': {
        fontSize: '18px',
    },
});

const StyledTextareaAutosize = styled(TextareaAutosize)(
    ({ theme }) => `
    border-radius: 8px;
    padding: 10px 10px;
  `,
);

const StyledPayButton = styled(Button)({
    textTransform: 'none',
    padding: '16px',
    width: '100%',
    height: '100%',
    minWidth: '50%',
    borderRadius: '12px',
});

const styles = (theme: Theme) => ({
    root: {
        margin: '32px 20px',
        padding: '18px',
    },
    gridContent: {
        padding: 10,
    },
    secondGridContainer: {
        border: '1px solid #ccc',
        left: 'auto',
        height: '91vh',
    },
    productDetailsItem: {
        // width: '70%',
    },
    proceedToCheckout: {
        color: 'white',
        width: '60%',
        borderRadius: '50px',
        backgroundColor: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main
        }
    },
    modifyProduct: {
        textDecoration: 'none',
        fontSize: '18px',
        paddingLeft: '8px',
        cursor: 'pointer',
        color: 'white',
        backgroundColor: '#121212',
    },
    cartEmpty: {
        height: '30vh',
        background: "url('/static/images/shopping-bag-empty.png') no-repeat center center",
        color: '#000000',
        backgroundSize: '',
        overflow: 'hidden'
    },
    cartIteamElement: {
        fontSize: '12px'
    },
    marginBottom20PX: {
        marginBottom: '20px',
    },
    totalPriceContainer: {
        padding: 5,
    },
    marginBottom5: {
        marginBottom: '5px'
    },
    fullWidth: {
        width: '100%',
    },
    productTotalPrice: {
        'text-align': 'right',
    },
    cartHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 0px 10px',
        minHeight: '52px',
    },
    orderTitle: {
        fontSize: '15px',
        fontWeight: 700,
        marginBottom: '5px',
    },
    title: {
        fontSize: '15px',
        fontWeight: 700,
    },
    orderTitleRight: {
        fontSize: '15px',
        fontWeight: 700,
        marginBottom: '5px',
        textAlign: 'right' as 'right',
    },
    couponContainer: {
        display: 'flex',
        marginTop: 15,
    },
    couponContainerMobile: {
        display: 'flex',
    },
    couponField: {
        marginTop: 2,
        maxWidth: 170,
    },
    couponFieldInput: {
        '& input': {
            padding: '5px 10px 0px',
            fontSize: 15,
        },
    },
    couponButton: {
        minWidth: 'initial',
        margin: '2px 0px 0px 5px',
        padding: '3px 0px 0px',
        fontSize: 14,
        height: 22,
        width: 53,
    },
    couponError: {
        color: theme.palette.error.main,
        margin: '2px 9px 0',
    },
    couponErrorMobile: {
        color: theme.palette.error.main,
        margin: '9px 9px 5px',
    },
    textareaBoxSize: {
        minWidth: '100%',
        maxWidth: '100%',
        border: '2px solid #cccc'
    },
    orderTotal: {
        'text-align': 'right',
    },
    paymentDialog: {
    },
    dialogTitle: {
        padding: 0,
        height: '25px',
    },

    iframeRoot: {
        backgroundColor: '#fff',
        border: 'none',
        minHeight: '850px',
        // marginTop: '-5px',
        width: '100%',
        overflowY: 'auto',
        "@media (max-width: 432px)": {
            // width: 'calc(100vw - 95px)',
            height: '85vh',
            // marginTop: '20px',
        },
        "@media only screen and (min-width: 768px)": {
            width: '100%',
            // height: '72vh',
            marginTop: '-15px',
            minHeight: 'calc(80vh - 64px)',
        },
    },
    dialogContent: {
        padding: 0,
        "@media (min-width: 1200px)": {
            display: 'flex',
            justifyContent: 'center',
        },
        "@media (min-width: 768px)": {
            display: 'flex',
            justifyContent: 'center',
        },
    },
    closeButton: {
        padding: 0,
    },
    label: {
        paddingLeft: '42px',
        fontSize: '20px',
    },
    scheduleDetails: {
        display: 'flex',
        // paddingLeft: '42px',
        [theme.breakpoints.down('xs')]: {
            display: 'inline-block',
        },
    },
    datetimeField: {
        minWidth: '225px',
        marginRight: '20px',
        [theme.breakpoints.down('xs')]: {
            paddingBottom: '10px',
        },
    },
    detetimeFieldInput: {
        color: theme.palette.error.main,
    },
    deliveryTime: {
        minWidth: 181,
    },
    keyboardButtonProps: {
        color: '#000',
    },
    orderMessage: {
        textAlign: 'center' as 'center',
        margin: '5px auto',
        maxWidth: 912,
        color: theme.palette.error.main,
        fontSize: 23,
        padding: 15,
        borderRadius: 15,
        backgroundColor: '#dcdcdc',
    },
    selectedDateErrorMessage: {
        color: theme.palette.error.main,
        margin: '10px auto',
        fontSize: 18,
        textAlign: 'center' as 'center',
    },
    deleteButton: {
        color: '#000000',
    },
    companyLogo: {
        maxHeight: 40,
        maxWidth: 109,
        backgroundColor: '#f3f3f6',
    },
    deliveryNote: {
        marginBottom: 20,
    },
    formControlLabel: {
        paddingTop: '5px'
    },
    expansionPanel: {
        background: 'initial',
        border: 'none',
        boxShadow: 'none',
        paddingBottom: 15,
        '&::before': {
            content: 'none',
        },
    },
    expansionPanelSummary: {
        padding: 0,
        maxWidth: 'fit-content',
        justifyContent: 'start',
        color: '#0000ef',
    },
    expansionPanelSummarySubtitle: {
        marginBottom: 0,
    },
    expansionArrow: {
        color: '#242424',
        padding: 0,
        marginLeft: -10,
        fontSize: 30,
    },
    expansionPanelContent: {
        padding: 0,
    },
    itemNotesContainer: {
        textAlign: 'left' as 'left',
    },
    itemNotes: {
        fontSize: 18,
        textTransform: 'none' as 'none',
    },
    errorMessage: {
        color: '#ff0000',
        fontSize: '16px',
        paddingRight: '10px',
        marginLeft: 8,
        transition: '1s',
        opacity: 0,
        marginTop: 3,
    },
    qtyErrorMessageActive: {
        opacity: 1,
    },
    subtitle: {
        marginTop: 15,
    },
    cartScrollablePanel: {
        maxHeight: 450,
        overflowY: 'auto' as 'auto',
        overflowX: 'hidden' as 'hidden',
        paddingBottom: 10,
        // "@media screen and (max-width: 1366px)": {
        //     maxHeight: 550,
        // },
        // "@media screen and (min-width: 1920px)": {
        //     maxHeight: 600,
        // },
    },
    qtyDropdown: {
        padding: 0,
        margin: '0 !important',
        fontSize: '18px !important',
        '& > div': {
            fontSize: '18px',
        },
    },
    dropdownOptions: {
        fontSize: '18px',
    },
});

interface IPageProps extends IApplicationProps {
    router: any
    classes: any;
    orderGet: (orderId: number) => Promise<IOrder>;
    orderPut: (order: IOrder, placeOrder?: boolean) => any;
    orderSetDefault: () => void;
    // onlineOrdersEnabled: (date?: Date) => Promise<any>;
    applyCoupon: (orderId: number, couponCode: string) => Promise<IOrder>;
    removeCoupon: (orderId: number) => Promise<IOrder>;
    contactPost: (contact: IContact) => IContact;
    shopingCart: any;
    isLoggedIn: boolean,
    isGuestLogin: boolean;
    locationId: string;
    oidc: UserState;
    customer: ICustomer;
    order: IOrder | any;
    settings: { [key: string]: ISetting };
    rooms: IRoom[]
    eventPickLists: any[]
    makePayment: (orderId: any) => any;
    orderRemoveItem: (itemId: number) => Promise<IOrder>;
    selectedCategoryMenuProduct: (menuCategoryItem: IMenuCategoryItem | any, orderItem: IOrderItem) => void;
    getSettingAreas: () => void;
    getRooms: () => void;
    getEventPickLists: () => void;
    upsertAddress: (address: IAddress) => Promise<IAddress>;
    createOrderGuest: any;
    getPaymentCardDetails: (payload: any) => any;
    getSetupIntentDetails: (payload: any) => any;
    postCreatePaymentLink: (payload: any, processorType: any) => any;
    clearCart: () => void
    organizationSettings: any;
    organizationOrdersOpenSchedule: any,
    postPaymentForOrder: (payload: any, processorType: any) => any;
    appUserDetails: any;
    user: any;
    organizations: IOrganizationState
    getUserByEmailId: (payload: any) => any;
    registerUser: (payload: any) => any;
    fetchCalculatedPayment: (body: any) => any;
    getPickListOptionsFromAPI: (pickListName: any) => any;
    getPickLists: (organizationID: number | string) => any;
    fetchCalculatedEstimatedTime: (body: any) => any;
    fetchUndeliverablePostalCodes: () => any
    organization: any;
    addressesGet: any;
    userPersonalDetails: any;
    createNewAddress:any
    pickListByOrganization:any
  
}

interface IPageState {
    companyLogo: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;
    phoneNumber: string | undefined;
    email: string | undefined;
    addressName: string | undefined;
    address1: string | undefined;
    city: string | undefined;
    county: string | undefined;
    postalCode: string | undefined;
    stateProvince: string | undefined;
    country: string | undefined;
    notes: string | undefined;
    openPayMethodModel: boolean;
    openOpenHoursModel: boolean;
    openPolicyModel: boolean;
    paymentURL: string;
    paymentDialogHeight: any;
    orderType?: 'Delivery' | 'Pickup' | 'Event';
    scheduleForLater: boolean;
    showAsap?: boolean;
    selectedDate: Date;
    selectedDateError: string;
    eventSelectedEndDate: Date;
    orderMinimum: number;
    orderMinimumMessage: string;
    onlineOrderClosedMessage: string;
    undeliverableZoneMessage: string;
    onlineOrderEnabled?: boolean;
    throttlingBuffer?: number;
    throttlingOverloadMessage: string;
    estimatedTimeCheck?: Date;
    estimatedReadyTimeMax: Date | null;
    orderSaveRequest: boolean;
    couponCode: string;
    couponError: boolean;
    guest: boolean;
    newAddress: boolean;
    saveNewAddress: boolean;
    latitude: number | undefined;
    longitude: number | undefined;
    companyName: string;
    eventName: string;
    dateOfEvent: string;
    expectedGuestCount?: number;
    eventStartTime: string;
    eventEndTime: string;
    theme: string;
    category: string;
    eventNotes: string;
    selectedRoom?: IRoom | null;
    selectedSetupStyle?: ISetupStyle | null;
    detailsExpended: boolean;
    newOrderQuantity: { [key: number]: number };
    onlineOrderTermsMessage: string,
    qtyError: any;
    operation: string;
    creatingOrder: boolean;
    forceLogin: boolean,
    paymentPayload: any;
    paymentConfirmation: any;
    laterSchedDate: any,
    minimumPickupDate:any,
    calculateEstimatedTimeLoading: any,
    eventDate: any,
    eventStart: any,
    eventEnd: any,
    appUserState: any;
    cardDetailsLoader: boolean,
    totalTax: number,
    serviceChargeTotal: number,
    gratuityTotal: number,
    total: number,
    subtotal: number,
    calculatePaymentLoading: boolean,
    orderCreateError: boolean,
    orderCreateErrorMessage: any,
    savedAddresses: any,
    selectedAddressId: any,
    undeliverablePostalCodes: any,
    undeliverableDistances: any,
    undeliverableTravelTime: any,
    deliveryFee: any,
    loadPaymentUrl: boolean,
    pickListThemeOptions: any,
    pickListOperationOptions: any;
    pickListCategoryOptions: any;
    pickListByOrganization: any;
    distanceInMiles: any,
    travelTimeInMins: any,
    // siteAddress1: string | undefined;
    // siteCity: string | undefined;
    // sitePostalCode: string | undefined;
    // siteStateProvince: string | undefined;
    // siteAddressName: string | undefined;
    calculatedEstimatedTimeFromAPI: any;
    saveAddress: boolean;
    pickupDeliveryTime:any;
    paymentDeclineError: string | null;
    skipifyGuid: string;
    skipifyPayButtonLoading: boolean;
    creatingOrderWithSkipify: boolean;
    skipifyResponse: any;
    skipifySuccessOrderID: string | null;
    skipifyClose: boolean;
    skipifyPopupOpen: boolean
    selectedOption:any
    selectedNames:any
    orderPickListOptions:any
}

function Auth0Provider({ children }: {children: any}) {
    const { isAuthenticated, isLoading, user} =  useGrazeAuth0Lock();
    useEffect(()=>{
        if(!isAuthenticated && !isLoading && !user){
            Auth0WithOutRedirect.show();
        }
    }, [isAuthenticated, isLoading, user])
    return children;
}

const LoginUser = (props: any) => {
    const { organizationSettings } = props;

    return (
        <Box onClick={() => Auth0WithOutRedirect.show()}
            sx={{
                marginBottom: '20px',
                padding: '6px 15px',
                border: '1px solid',
                borderColor: organizationSettings?.layoutColor,
                borderRadius: '100px',
                fontSize: '14px',
                cursor: 'pointer',
                backgroundColor: '#fff',
                display: 'flex',
                width: 'fit-content',
                marginLeft: 'auto',
            }}
        >
            <Stack direction="row" alignItems="center" ml={"auto"}>
                <PersonOutlineOutlined color="secondary" sx={{ fontSize: '22px', marginRight: '5px' }} />
                <Typography sx={{ fontSize: { sm: '14px' } }}>Sign in</Typography>
            </Stack>
        </Box>
    )
};

class ProceedToCheckout extends React.Component<IPageProps, IPageState> {
    public validator: SimpleReactValidator = new SimpleReactValidator();
    public cardFormValidator: SimpleReactValidator = new SimpleReactValidator();
    public calculateEstimatedTimeTimer: any = null;
    public timeOutList: any = null;
    public paymentPayloadObject: any = null;
    public timer: NodeJS.Timeout | null = null;
    
    constructor(props: any) {
        super(props);
    
        this.state = {
            orderCreateError: false,
            orderCreateErrorMessage: "",
            companyLogo: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            addressName: '',
            address1: '',
            city: '',
            county: '',
            postalCode: '',
            stateProvince: '',
            country: 'United States',
            notes: '',
            openPayMethodModel: false,
            openOpenHoursModel: false,
            openPolicyModel: false,
            paymentURL: process.env.REACT_APP_PAYMENT_URL || '',
            paymentDialogHeight: '100vh',
            orderType: LocalStorage.get(LocalStorage.keys.orderType) || "Pickup",
            scheduleForLater: false,
            showAsap: false,
            selectedDate: new Date(),
            selectedDateError: '',
            eventSelectedEndDate: moment(new Date()).add({ hours: 1 }).toDate(),
            orderMinimum: 0,
            orderMinimumMessage: '',
            onlineOrderClosedMessage: '',
            undeliverableZoneMessage: '',
            onlineOrderEnabled: true,
            throttlingBuffer: 0,
            throttlingOverloadMessage: '',
            estimatedReadyTimeMax: new Date(),
            orderSaveRequest: false,
            couponCode: '',
            couponError: false,
            guest: true,
            newAddress: false,
            saveNewAddress: false,
            latitude: 0,
            longitude: 0,
            companyName: '',
            eventName: '',
            dateOfEvent: '',
            expectedGuestCount: 1,
            eventStartTime: '',
            eventEndTime: '',
            theme: '',
            category: '',
            eventNotes: '',
            selectedRoom: null,
            selectedSetupStyle: null,
            detailsExpended: false,
            newOrderQuantity: {},
            onlineOrderTermsMessage: '',
            qtyError: {},
            operation: '',
            creatingOrder: false,
            forceLogin: false,
            paymentPayload: null,
            paymentConfirmation: null,
            laterSchedDate: new Date(),
            minimumPickupDate: new Date(),
            calculateEstimatedTimeLoading: false,
            eventDate: new Date(),
            eventStart: new Date(),
            eventEnd: new Date(new Date().getTime() + 15 * 60000),
            appUserState: null,
            cardDetailsLoader: true,
            totalTax: 0,
            serviceChargeTotal: 0,
            gratuityTotal: 0,
            total: 0,
            subtotal: 0,
            calculatePaymentLoading: false,
            savedAddresses: null,
            selectedAddressId: "",
            undeliverablePostalCodes: null,
            undeliverableDistances: null,
            undeliverableTravelTime: null,
            deliveryFee: 0,
            loadPaymentUrl: false,
            pickListThemeOptions: null,
            pickListCategoryOptions: null,
            pickListByOrganization: null,
            pickListOperationOptions: null,
            distanceInMiles: 0,
            travelTimeInMins: 0,
            // siteAddress1: "",
            // siteCity: "",
            // sitePostalCode: "",
            // siteStateProvince: "",
            // siteAddressName:"",
            calculatedEstimatedTimeFromAPI: null,
            saveAddress:false,
            pickupDeliveryTime:moment(new Date()).format('HH:mm'),
            paymentDeclineError: null,
            skipifyGuid: '',
            skipifyPayButtonLoading: false,
            creatingOrderWithSkipify: false,
            skipifyResponse: null,
            skipifySuccessOrderID: null,
            skipifyClose: false,
            skipifyPopupOpen: false,
            selectedOption: '',
            selectedNames: '',
            orderPickListOptions: []
        }
        
        this.handleRadioChange = this.handleRadioChange.bind(this);
        
    }

    
    

    public handleRadioChange(event: any) {
        const scheduleForLater = !this.state.scheduleForLater;

        this.setState({ scheduleForLater, laterSchedDate: new Date() });
    }

    public handleDateChange = async (moment: any) => {
        const { estimatedReadyTimeMax } = this.state;
        let selectedDate = new Date(moment);
        const invalidDate = isNaN(selectedDate.getTime());
        let selectedDateError = '';

        if (invalidDate) {
            return;
        }

        if (estimatedReadyTimeMax && selectedDate < estimatedReadyTimeMax) {
            selectedDate = estimatedReadyTimeMax;
            selectedDateError = `Earliest ready time is ${getFormatedNiceDate(selectedDate)}`
        }

        const onlineOrderEnabled = true;
        const throttlingBuffer = 0;
        // const { onlineOrderEnabled, throttlingBuffer } = scheduleForLater
        //     ? await this.props.onlineOrdersEnabled(selectedDate)
        //     : await this.props.onlineOrdersEnabled(estimatedReadyTimeMax ? estimatedReadyTimeMax : undefined);

        if (!onlineOrderEnabled) {
            selectedDateError = `Kitchen is closed on ${getFormatedNiceDate(selectedDate)}`;
        }

        this.setState({ selectedDate, selectedDateError, onlineOrderEnabled, throttlingBuffer });
    };

    private checkUserProfile = (): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            const contactInfo = this.getContactInfoFromLocalStorage();
            if (contactInfo) {
                this.setState({
                    ...this.state,
                    ...contactInfo,
                }, () => resolve(null));
                return;

            } else {
                const { customer } = this.props;
                if (!customer || customer.firstName === 'Guest') {
                    this.setState({
                        ...this.state,
                    }, () => resolve(null));
                    return;
                }

                this.setState({
                    firstName: showString(customer?.firstName),
                    lastName: showString(customer.lastName),
                    phoneNumber: showString(customer.phone),
                    email: showString(customer.email),
                }, () => resolve(customer));
            }
        });

    }

    

    private onAddressSelected = (address: IAddress) => {
        return new Promise((resolve, reject) => {
            this.setState({
                orderSaveRequest: false,
                estimatedTimeCheck: new Date(),
                addressName: address.name,
                address1: replaceComma(address.address1),
                city: address.city,
                postalCode: address.postalCode,
                stateProvince: address.stateProvince,
                latitude: address.latitude,
                longitude: address.longitude,
                newAddress: false,
            }, async () => {
                const order = await this.saveOrder();
                this.calculateEstimatedTime();

                resolve(order);
            });
        });
    }

    private onNewAddressSelected = () => {
        this.setState({
            newAddress: true,
            addressName: '',
        });
    }

    private handlePhone = ({ target: { value } }: any) => {
        this.setState({ phoneNumber: normalizePhoneInput(value, this.state?.phoneNumber ? this.state?.phoneNumber : '') });
    };

    private handleMessage = async (event: any) => {
        if (!event.data || !event.data.id) {
            return;
        }

        switch (event.data.id) {
            case 'hpp-done':
                this.handleClosePayMethodModel();
                const order: any = await this.saveOrder(true);
                this.orderSetDefault(order?.id);
                break;
            case 'set-height':
                this.setState({ paymentDialogHeight: event.data.height });
                break;
            default:
                break;
        }
    }

    private onPayment = (orderId: any) => {
        this.props.makePayment(orderId).then((url: any) => {
            this.setState({ paymentURL: url });
        });
    }

    private onhandleChange = (event: any, fieldName: string) => {
        const value = event.target.value;
        this.setState({ [fieldName]: value } as IPageState, () => {
            this.setContactInfoOnLocalStorage();

            if (fieldName === 'eventStartTime' || fieldName === 'dateOfEvent') {
                this.handleDateChange(`${this.state.dateOfEvent} ${this.state.eventStartTime}`);
            }

            if (fieldName === 'deliveryTime') {
                this.handleDateChange(`${this.state.selectedDate.toLocaleDateString()} ${value}`);
            }
        });
    }

    private handleRoomChange = (event: React.ChangeEvent<{ value: any }>) => {
        const { rooms } = this.props;
        const selectedRoomId = event.target.value as number;
        const selectedRoom = rooms?.find(room => room.id === selectedRoomId);
        this.setState({
            selectedRoom,
            selectedSetupStyle: null,
            orderSaveRequest: true,
        }, this.setContactInfoOnLocalStorage);
    }

    private handleSetupStyleChange = (event: React.ChangeEvent<{ value: any }>) => {
        const { selectedRoom } = this.state;
        const selectedSetupStyleId = event.target.value as number;
        const selectedRoomSetupStyle = selectedRoom?.roomSetupStyles?.find(style => style.setupStyleId === selectedSetupStyleId);
        this.setState({
            selectedSetupStyle: selectedRoomSetupStyle?.setupStyle,
        }, this.setContactInfoOnLocalStorage);
    }

    private handleValueChange = (value: any, fieldName: string) => {
        this.setState({ [fieldName]: value } as IPageState, this.setContactInfoOnLocalStorage)
    }

    private handleClickOpenPayMethodModel = () => {
        this.setState({ openPayMethodModel: true })
    };

    private handleClosePayMethodModel = () => {
        this.setState({ openPayMethodModel: false, paymentPayload: null })
    };


    private handleCloseOpenHoursModel = () => {
        this.setState({ openOpenHoursModel: false })
    };

    private handleClosePolicyModel = () => {
        this.setState({ openPolicyModel: false })
    };

    private onSaveNewAddressChecked = () => {
        this.setState({
            saveNewAddress: !this.state.saveNewAddress,
        });
    }

    private saveOrder = async (placeOrder: boolean = false): Promise<IOrder | null> => {
        const {
            firstName,
            lastName,
            phoneNumber,
            email,
            addressName,
            address1,
            postalCode,
            city,
            stateProvince,
            notes,
            orderType,
            scheduleForLater,
            selectedDate,
            estimatedReadyTimeMax,
            saveNewAddress,
            guest,
            latitude,
            longitude,
            companyName,
            eventName,
            dateOfEvent,
            expectedGuestCount,
            eventStartTime,
            eventEndTime,
            theme,
            category,
            eventNotes,
            selectedRoom,
            selectedSetupStyle,
            operation,
        } = this.state;
        const { order, orderPut, locationId } = this.props;

        try {
            const updatedOrder: IOrder = {
                ...order,
                asap: !scheduleForLater,
                requestedDateTime: scheduleForLater ? selectedDate : estimatedReadyTimeMax,
                locationId: locationId ? locationId : order.locationId,
                customerName: `${firstName} ${lastName}`,
                customerPhone: phoneNumber,
                customerEmail: email,
                notes,
                type: orderType,
                orderStatus: null,
                operation,
                orderEventDetails: {
                    ...order.orderEventDetails,
                    expectedGuestCount,
                }
            }

            if ((orderType === 'Delivery' || orderType === 'Event')
                && !!address1) {
                updatedOrder.orderDelivery = {
                    ...order.orderDelivery,
                    notes,
                    name: addressName,
                    address1,
                    city,
                    stateProvince,
                    postalCode,
                    latitude,
                    longitude
                };
            }

            if (orderType === 'Event') {
                updatedOrder.orderEventDetails = {
                    ...order.orderEventDetails,
                    companyName,
                    eventName,
                    dateOfEvent,
                    expectedGuestCount,
                    eventStartTime,
                    eventEndTime,
                    theme,
                    category,
                    eventNotes,
                    banquetRoomId: selectedRoom?.id,
                    banquetRoom: selectedRoom?.name,
                    setupStyle: selectedSetupStyle?.name,
                };
            }

            if (placeOrder) {
                this.resetContactInfoOnLocalStorage();

                if (saveNewAddress && !guest) {
                    const newAddress: IAddress = {
                        address1,
                        city,
                        stateProvince,
                        postalCode,
                        default: false,
                        latitude,
                        longitude,
                        name: addressName,
                    };

                    this.props.upsertAddress(newAddress);
                }
            }

            const { newOrderQuantity } = this.state;
            const newQuantityItemIds = Object.keys(newOrderQuantity);
            if (newQuantityItemIds.length) {
                newQuantityItemIds.forEach((key: any) => {
                    const newQuantity = newOrderQuantity[key]
                    const updatedItem = updatedOrder?.orderItems?.find(item => item.id === Number(key));
                    if (updatedItem) {
                        updatedItem.quantity = newQuantity;
                    }
                });
            }

            return orderPut(updatedOrder, placeOrder);
        } catch (e) {
            console.error(e);
        }

        return null;
    }

    private orderSetDefault = (orderId: number) => {
        this.props.orderSetDefault();
        LocalStorage.save(LocalStorage.keys.lastOnlineOrder, orderId);
        this.props.router?.navigate(`/order/${orderId}`);
    }

    public countValue: number = 1;
    private payNow = async (event: any) => {
        this.setState({ paymentDeclineError: null });
        event.preventDefault();
        const {
            undeliverablePostalCodes,
            undeliverableDistances,
            undeliverableTravelTime,
            orderType,
            savedAddresses,
            selectedAddressId,
            firstName,
            lastName,
            email,
            address1,
            postalCode,
            city,
            stateProvince,
            notes,
            distanceInMiles,
            travelTimeInMins,
            laterSchedDate,
            eventDate,
            eventStart,
            orderPickListOptions
        } = this.state;
        const { organizationSettings, organization, appUserDetails } = this.props;
        //testing  enable this line
        //this.handleClickOpenPayMethodModel();
        // const { onlineOrderEnabled } = await this.props.onlineOrdersEnabled(selectedDate);
        // if (!onlineOrderEnabled) {
        //     this.setState({ onlineOrderEnabled });
        //      return;
        // }
        const start = new Date(moment(eventDate).format('YYYY-MM-DD') + moment(eventStart).utc().format('THH:mm:ss.SSS[Z]'))
        const selectedAddress = savedAddresses?.find((address: any) => { return address?.id === selectedAddressId });
        const invalidPostalCode = selectedAddress ? undeliverablePostalCodes?.find((code: string) => code === selectedAddress?.postalCode) : undeliverablePostalCodes?.find((code: string) => code === postalCode);
        const invalidDistanceInMiles = undeliverableDistances?.find((dist: string) => distanceInMiles >= Number(dist));
        const invalidDriveDuration = undeliverableTravelTime?.find((time: string) => travelTimeInMins >= Number(time));
        if (orderType === "Delivery" && (invalidPostalCode || invalidDistanceInMiles || invalidDriveDuration)) {
            this.setState({ orderCreateError: true, orderCreateErrorMessage: this.props.organizationSettings?.ordersUndeliverableMessage })
        }
        else {
            if(moment(moment(orderType === "Event" ? start : laterSchedDate).format()).isBefore(moment(moment().format()))){
                this.setState({ orderCreateError: true, orderCreateErrorMessage: "Please select a Date and Time in the future"})
            }
            else if(moment(moment(orderType === "Event" ? start : laterSchedDate).format()).isBefore(moment(this.state.calculatedEstimatedTimeFromAPI))){
                this.setState({ orderCreateError: true, orderCreateErrorMessage: `The earliest available time to place an order is ${moment(this.state.calculatedEstimatedTimeFromAPI).format("MM/DD/YYYY hh:mm A")}`})
            }
            else{
                let processorType = 0;
                if(organizationSettings?.merchantAccount?.includes('t1_') || organizationSettings?.merchantAccount?.includes('p1_') ) {
                    processorType = 5;
                }
                if(organizationSettings?.merchantAccount?.includes('acct_')  ) {
                    processorType = 3;
                }
                this.setState({ loadPaymentUrl: true });
                const payload = {
                    "amountToCharge": Math.round(this.state.total * 100),
                    "availablePaymentTypes": "cc",
                    "ccEmail": "sonny.elhamahmy@gmail.com",
                    "backgroundColor": "#8CB4D2",
                    "paymentNumber": uuidv4(),
                    "payor_address": {
                        "line1": address1 ? address1 : "123 South Main",
                        "city": city ? city : "Naples",
                        "country": "US",
                        "postal_code": postalCode ? postalCode : "34102",
                        "region": stateProvince ? stateProvince : "F1"
                    },
                    "payerEmail": appUserDetails?.emailAddress ? appUserDetails.emailAddress : email,
                    "payer": `${firstName} ${lastName}`,
                    "dueDate": moment().add(1, 'd').toDate(),
                    "encodedLogo": "",//organizationSettings?.grazeLogo  should be encoded image not as url 
                    "encodedLogoPosition": "left",
                    "expirationDate": moment().add(2, 'd').toDate(),
                    "invoiceIntroductionMessage": "Thank you for choosing HORIZON for your recent order. Please click the <br />\"Authorize Now\" button below to authorize and store your credit card for future payments on your order.",
                    "invoiceMessage": "If you have any question regarding this credit card authorization, please call our office. Thanks again for doing business with HORIZON.<br /><br />Regards,<br /><br />Your HORIZON Team",
                    "invoiceNumber": uuidv4(),
                    "accountId": organizationSettings?.merchantAccount ? organizationSettings.merchantAccount : "1b6de8fb-203f-4984-9bdc-059f9c11e10c",
                    "merchantName": organization?.name,
                    "paymentLinkType": 0,
                    "zipCode": selectedAddress?.postalCode,
                    "productName": "Caterease Online Order",
                    "replyEmail": organizationSettings?.contactEmailAddress,
                    "sendEmail": true,
                    "senderName": organization?.name,
                    "showCardOnFileDialog": true,
                    "showTip": organizationSettings?.enableAddTip,
                    "textColor": "#000000",
                    "transaction_details": {
                        "itemized_receipt": [],
                        "note": notes
                    },
                    "serialNumber": organizationSettings?.serialNumber,
                    "applySurcharge": organizationSettings?.applySurcharge,
                    "surchargePercent": organizationSettings?.surchargePercentage *100,
                    "showSuggestedTip": organizationSettings?.enableAddTip,
                    "tipPct1": organizationSettings?.tipPercent1 * 10000,
                    "tipPct2": organizationSettings?.tipPercent2 * 10000,
                    "tipPct3": organizationSettings?.tipPercent3 * 10000,
                    "tipBackgroundColor": organizationSettings?.layoutColor && lighten(organizationSettings?.layoutColor, 0.5),
                    "submitButtonBackgroundColor": organizationSettings?.layoutColor,
                    "orderPickListOptions": orderPickListOptions || [],
                };
                await this.props.postCreatePaymentLink(payload, processorType).then(async (response: any) => {
                    if (response) {
                        this.countValue = 1;
                        const iFrameUrl = response.iframeUrl ? response.iframeUrl + '&viewType=graze' : '';
                        this.setState({ paymentURL: iFrameUrl, loadPaymentUrl: false });
                        this.handleClickOpenPayMethodModel();
                        var self = this;
                        if(processorType==3) {
                            window.addEventListener('message', async function (e) {
                               
                                //Call 
                                ///api/hpay/completegraze?pi_guid=' + pi_guid + '&acct_id=' + merchantId
                                //return payment payload"
                                let stringValue = `${e?.data}`;
                                //Call Hpay complete 
                                if (e?.data && stringValue?.includes("setupIntent"))  {
                                    const tempString = JSON.parse(e.data);
                                    await self.getSetupIntent({...tempString.setupIntent, totalAmount: tempString.totalAmount , tipAmount: tempString.tipAmount} )
                                }
                               
                               
                            }, false);
    
                        }  else {
                            window.addEventListener('message', async function (e) {
                                let stringValue = `${e?.data}`;
                               // if (e?.data && stringValue?.includes("paymentMethodId") && !organizationSettings?.applySurcharge) {
                                if (e?.data && stringValue?.includes("paymentMethodId") ) {
                                    const dataValue = JSON.parse(e.data);
                                    self?.setState({ paymentPayload: dataValue, cardDetailsLoader: true});
                                    if(self.countValue === 1) {
                                        self.countValue = 2;
                                        await self.getCardDetailsAndPayment(dataValue);
                                    }
                                } else if (organizationSettings?.applySurcharge && stringValue?.includes("idPayment")) {
                                    if (e?.data) {
                                        const dataValue = JSON.parse(e.data);
                                        self?.setState({ paymentPayload: dataValue}, () => {
                                            if(self.countValue===1) {
                                                self.countValue = 2;
                                                self.makePaymentWithSurcharge();
                                            }
                                        });
                                        //await  self.getCardDetailsAndPayment(dataValue);
                                    }
                                }
                            }, false);
    
                        }
                        
                    }
                })
            }   
        }
    };


    private getSetupIntent = async (setupIntentObject: any, count: number = 0) => {
        const payload = {
            pi_guid: setupIntentObject.id,
            setup_intent_client_secret: setupIntentObject.client_secret,
            setup_intent: setupIntentObject.id,
            acct_id: this.props.organizationSettings?.merchantAccount,
            tipAmount: setupIntentObject?.tipAmount,
            totalAmount: setupIntentObject?.totalAmount
        }
        await this.props.getSetupIntentDetails(payload).then(async (res: any) => {
            console.log(res)
            if ( res.status== 200) {
                const payload = {...res.payload, 
                    merchantId : this.props.organizationSettings.merchantAccount

                };
                this.setState({ paymentPayload: payload, cardDetailsLoader: true});
                await this.getCardDetailsAndPayment( payload);
                
            }
           
        }).catch((err: any) => {
            this.setState({ cardDetailsLoader: false });
        })
    }

    private getCardDetailsAndPayment = async (paymentObject: any, count: number = 0) => {
        this.setState({ cardDetailsLoader: true });
        const { user } = this.props;
        const { firstName, lastName, email, } = this.state;
        const payload = {
            paymentMethodId: paymentObject?.paymentMethodId,
            zipCode: paymentObject?.zipCode,
            currency: paymentObject?.currency,
            payerEmail: user?.email ? user?.email : email,
            nameOnCard: user?.name ? user?.name : firstName + ' ' + lastName,
            merchantId : this.props.organizationSettings.merchantAccount
        };
        await this.props.getPaymentCardDetails(payload).then(async (res: any) => {
            if (res && res.id) {
               // if (this.props.organizationSettings?.applySurcharge) {
                //    this.setState({ paymentConfirmation: res, cardDetailsLoader: false });
                //}
                //else {
                    this.setState({ paymentConfirmation: res, cardDetailsLoader: false  }, () => {
                        this.makePayment();
                    })
                //}
            } else {
                if (count <= 2) {
                    count++;
                    await this.getCardDetailsAndPayment(this.state.paymentPayload, count);
                }
            }
        }).catch((err: any) => {
            this.setState({ cardDetailsLoader: false });
        })
    }

    private checkAppUserAvailable = async () => {
        const { appUserDetails, user, organizations } = this.props;
        if (!appUserDetails && user) {
            await this.props.getUserByEmailId(user.email).then(async (response: any) => {
                if (!response.successful) {
                    const payload: any = [
                        {
                            "organizationId": organizations?.organization?.id,
                            //   "defaultAddressId": 0,
                            "organizationDomainName": organizations?.organization?.domainName,
                            "userName": user.nickname,
                            "firstName": user.name,
                            "lastName": user.name,
                            "emailAddress": user.email,
                            "userType": "User"
                        }
                    ];
                    await this.props.registerUser(payload).then((res: any) => {
                        this.setState({ appUserState: Array.isArray(res.data) ? res.data[0] : res.data }, () => { });
                    }).catch((err: any) => {
                        console.error("::::::: error ", err);
                    });
                } else {
                    this.setState({ appUserState: Array.isArray(response.data) ? response.data[0] : response.data });
                }
            }).catch((err: any) => {
                console.error("::::::: error ", err);
            });

        }

    }

    public getOrderDeliveryAddress = () => {
        const {
            orderType,
            address1,
            // siteAddress1,
            // siteCity,
            // siteStateProvince,
            // siteAddressName,
            // sitePostalCode,
            city,
            stateProvince,
            postalCode,
            country,
            addressName,
            savedAddresses,
            selectedAddressId,
            distanceInMiles,
            travelTimeInMins,
            county
        } = this.state;
        if (orderType === "Delivery") {
            if (savedAddresses?.length > 0) {
                const selectedAddress = savedAddresses?.find((address: any) => { return address?.id === selectedAddressId });
                return {
                    name: selectedAddress?.name || addressName,
                    address1: replaceComma( selectedAddress?.address1 || address1),
                    address2: selectedAddress?.address2,
                    city: selectedAddress?.city || city,
                    stateProvince: selectedAddress?.stateProvince || stateProvince,
                    postalCode: selectedAddress?.postalCode || postalCode,
                    country: selectedAddress?.country || "United States",
                    "estimatedDriveDuration": travelTimeInMins,
                    "distanceInMiles": distanceInMiles,
                    county: selectedAddress?.county || county || this.props.organization?.county
                }
            }
            else {
                return {
                    address1: replaceComma(address1), 
                    name:addressName,
                    city,
                    stateProvince,
                    postalCode,
                    country: country || "United States",
                    "estimatedDriveDuration": travelTimeInMins,
                    "distanceInMiles": distanceInMiles,
                    county: county || this.props.organization?.county
                }
            }

        }
        else if(orderType === "Event"){
            const selectedAddress = savedAddresses?.find((address: any) => { return address?.id === selectedAddressId });
            if (!selectedAddress && !address1) {
                return null;
            }
            return {
                address1 : replaceComma(selectedAddress?.address1 || address1) ,
                city : selectedAddress?.city || city,
                stateProvince : selectedAddress?.stateProvince || stateProvince,
                name: selectedAddress?.name || addressName,
                postalCode : selectedAddress?.postalCode || postalCode,
                country: country || "United States",
                "estimatedDriveDuration": travelTimeInMins,
                "distanceInMiles": distanceInMiles,
                county: county || this.props.organization?.county
            }
        }
        return null;
    }

    public getCounty = () => {
        const {
            orderType,
            address1,
            stateProvince,
            savedAddresses,
            selectedAddressId,
            county
        } = this.state;
        const {organization} = this.props;

        if (orderType === "Delivery") {
            if (savedAddresses?.length > 0) {
                const selectedAddress = savedAddresses?.find((address: any) => { return address?.id === selectedAddressId });
                return {
                    county: selectedAddress?.county || county,
                    state: selectedAddress?.stateProvince || stateProvince,
                }
            }
            else {
                return {
                    county,
                    state:stateProvince,
                }
            }

        }
        else if (orderType === "Pickup"){
            return {
                county:organization?.county,
                state:organization?.stateProvince
            }
        }
        else if(orderType === "Event"){
            const selectedAddress = savedAddresses?.find((address: any) => { return address?.id === selectedAddressId });
            if (!selectedAddress && !address1) {
                return {
                    county:organization?.county,
                    state:organization?.stateProvince
                }
            }
            return {
                county: selectedAddress?.county || county,
                state : selectedAddress?.stateProvince || stateProvince,
            }
        }
        return null;
    }

    private makePayment = async () => {
        const {
            firstName,
            lastName,
            email,
            paymentPayload,
            paymentConfirmation,
        } = this.state;
        const { organizationSettings} = this.props;
        this.setState({ creatingOrder: true });
        const payloadConfirmPayment = JSON.parse(JSON.stringify(paymentPayload));
        payloadConfirmPayment.tip = paymentPayload?.tipAmount;
        payloadConfirmPayment.amount = Math.round(paymentPayload?.amount ? paymentPayload?.amount : paymentPayload?.totalPaid,);
        if (paymentPayload.nameOnCard === '{NAMEONCARD}') {
            payloadConfirmPayment.nameOnCard = firstName + ' ' + lastName;
        }
        if (paymentPayload.payerEmail === '{EMAIL}') {
            payloadConfirmPayment.payerEmail = email;
        }
        if (paymentPayload.payment_method?.credit_card?.card_holder?.holder_name === '{NAMEONCARD}') {
            payloadConfirmPayment.payment_method.credit_card.card_holder.holder_name = firstName + ' ' + lastName;
        }
        if (paymentPayload.payment_method?.credit_card?.card_holder?.email === '{EMAIL}') {
            payloadConfirmPayment.payment_method.credit_card.card_holder.email = email;
        }
        await this.checkAppUserAvailable();
        
        let processorType = 0;
        if(organizationSettings?.merchantAccount?.includes('t1_') || organizationSettings?.merchantAccount?.includes('p1_') ) {
            processorType = 5;            
        }
        if(organizationSettings?.merchantAccount?.includes('acct_')  ) {
            processorType = 3;
        }
        payloadConfirmPayment.auto_capture = true;
        payloadConfirmPayment.account_id=organizationSettings?.merchantAccount;
        await this.props.postPaymentForOrder(payloadConfirmPayment, processorType).then(async (res: any) => {
            if (res.error_code) {
                this.setState({ creatingOrder: false, paymentPayload:null, paymentDeclineError: res.error_message});
            } else {
                const paymentTransaction: IPaymentTransaction = {                    
                    cCTransactionId: res?.idPayment ? res?.idPayment : res?.id,
                    paymentProcessorTransactionId: res?.idPayment ? res?.idPayment : res?.id,
                    // orderId?: number,
                    transactionState: 3,
                    transactionType: 'Online',
                    paymentMethod: 'Credit Card',
                    paymentMethodId: res.payment_method?.id,
                    cardIssuer: paymentConfirmation?.credit_card?.issue ? paymentConfirmation?.credit_card?.issue.charAt(0).toUpperCase() +  paymentConfirmation?.credit_card?.issue.slice(1) : '',
                  
                    maskedCardNumber: paymentConfirmation?.credit_card?.display_name ? paymentConfirmation?.credit_card?.display_name : '',
                    madeBy: '',
                    receivedBy: '',
                    comment: paymentConfirmation?.status ? paymentConfirmation?.status : paymentPayload.status,
                    cCPostal: paymentConfirmation?.credit_card?.card_holder?.address?.postal_code ? paymentConfirmation?.credit_card?.card_holder?.address?.postal_code : paymentPayload.zipCode,
                    cCStatus: res.status,
                    cCAuthCode: res.authorization_code,
                    totalPaid: res.amount/100,
                    totalTip: payloadConfirmPayment?.tip ? payloadConfirmPayment.tip/100 : 0,
                    totalRefund: 0,
                    totalSurcharge:payloadConfirmPayment?.surchargeAmount ? payloadConfirmPayment?.surchargeAmount :0,
        
                    dateApplied: new Date()
                };
              await  this.createOrderGuestWithTransactionDetails(paymentTransaction);
            }
        }).catch((err: any) => {
            this.setState({ creatingOrder: false });
            console.error("::::::: error", err);
        })
    }
    private makePaymentWithSurcharge = async () => {
        const {
            firstName,
            lastName,
            email,
            paymentPayload,
            paymentConfirmation,
        } = this.state;
        this.setState({ creatingOrder: true });
        const valueFromPayload = JSON.parse(JSON.stringify(paymentPayload));
        const payloadConfirmPayment = valueFromPayload ? valueFromPayload : {};
        payloadConfirmPayment.tip = (paymentPayload?.tipAmount );
        payloadConfirmPayment.amount = Math.round(paymentPayload.amount);
        if (paymentPayload.nameOnCard === '{NAMEONCARD}') {
            payloadConfirmPayment.nameOnCard = firstName + ' ' + lastName;
        }
        if (paymentPayload.payerEmail === '{EMAIL}') {
            payloadConfirmPayment.payerEmail = email;
        }

        await this.checkAppUserAvailable();
        const paymentTransaction: IPaymentTransaction = {
            cCTransactionId: paymentPayload?.idPayment,
            paymentProcessorTransactionId: paymentPayload?.idPayment,
            transactionState: 3,
            transactionType: 'Online',
            paymentMethod: 'Credit Card',
            paymentMethodId: paymentPayload?.paymentMethodId,
            cardIssuer: paymentConfirmation?.credit_card?.issue ? paymentConfirmation?.credit_card?.issue : paymentPayload?.issue.charAt(0).toUpperCase() +  paymentPayload?.issue.slice(1),
            maskedCardNumber: paymentConfirmation?.credit_card?.display_name ? paymentConfirmation?.credit_card?.display_name : paymentPayload?.display_name,
            madeBy: '',
            receivedBy: '',
            comment: paymentConfirmation?.status ? paymentConfirmation?.status : paymentPayload?.status,
            cCPostal: paymentConfirmation?.credit_card?.card_holder?.address?.postal_code ? paymentConfirmation?.credit_card?.card_holder?.address?.postal_code :paymentPayload?.zipCode,
            cCStatus: paymentPayload?.status,
            cCAuthCode: paymentPayload?.authorization_code,
            totalPaid: paymentPayload?.amount ? paymentPayload?.amount/100 : paymentPayload?.totalPaid,
            totalTip: payloadConfirmPayment?.tip ? payloadConfirmPayment?.tip : paymentPayload?.totalTip,
            totalRefund: 0,
            dateApplied: new Date(),
            totalSurcharge:paymentPayload?.surchargeAmount ? paymentPayload?.surchargeAmount :0
        };
       await this.createOrderGuestWithTransactionDetails(paymentTransaction);
    }


    private createOrderGuestWithTransactionDetails = async (paymentTransaction: IPaymentTransaction) => {
        const {
            firstName,
            lastName,
            phoneNumber,
            email,
            notes,
            orderType,
            companyName,
            addressName,
            address1,
            city,
            stateProvince,
            postalCode,
            country,
            savedAddresses,
            saveAddress,
            // siteAddress1,
            // siteAddressName,
            // siteCity,
            // sitePostalCode,
            // siteStateProvince,
            eventName,
            expectedGuestCount,
            eventDate,
            eventStart,
            eventEnd,
            laterSchedDate,
            appUserState,
            eventNotes,
            category,
            theme,
            county
        } = this.state;
        const { organizations,organizationSettings } = this.props;
        const orderItems: any[] = [];
        this.props.order?.orderItems?.forEach((orderItem: IOrderItem) => {
            delete orderItem.menuCategoryItem;
            //orderItem.organization = { id: appUserState?.organizationId ? appUserState?.organizationId : organizations?.organization?.id };
            orderItem.organization = { id:  organizations?.organization?.id };
            orderItems.push({
                notes: orderItem?.notes,
                orderItemModifiers: transformArrayFromModifiers(orderItem?.orderItemModifiers),
                menuCategoryItemId: orderItem?.menuCategoryItemId, 
                quantity: orderItem?.quantity
            });
        })
        const start = moment(eventDate).format('YYYY-MM-DD') + moment(eventStart).utc().format('THH:mm:ss.SSS[Z]');
        const end = moment(eventDate).format('YYYY-MM-DD') + moment(eventEnd).utc().format('THH:mm:ss.SSS[Z]');
        const payload = [{
            //organizationId: appUserState?.organizationId ? appUserState?.organizationId : organizations?.organization?.id,
            organizationId: organizations?.organization?.id,
            "orderItems": orderItems,
            "orderRevenueTypeRates": [],
            orderType,
            userId: appUserState?.id ? appUserState.id : null,
            "orderStatusType": "New",
            customerFirstName: firstName,
            customerLastName: lastName,
            customerPhone: phoneNumber,
            customerEmail: email,
            notes: orderType==="Event" ? eventNotes : notes,
            "isAsap": false,
            "requestedDateTime": orderType === 'Event' ? start : moment(laterSchedDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            "orderDeliveryAddress": this.getOrderDeliveryAddress(),
            "expectedGuestCount": orderType === 'Event' ? expectedGuestCount : (organizationSettings?.enableGuestCount ? expectedGuestCount : 0),
            orderPaymentTransactions: [paymentTransaction],
            "orderEventDetails": orderType === "Event" ? {
                "categoryId": category || null,
                "themeId": theme || null,
                "company": companyName,
                "eventName": eventName,
                "eventStartTime": start,
                "eventEndTime": end
            } : null,
            user: {
                //organizationId: appUserState?.organizationId ? appUserState?.organizationId : organizations?.organization?.id,
                organizationId: organizations?.organization?.id,
                id: appUserState?.id ? appUserState.id : null,
            }
        }];
        const addressPayload = [{  
            name: addressName ,
            address1: replaceComma(address1),
            city,
            county,
            stateProvince,
            postalCode,
            country:country || "United States",
            isDefault: savedAddresses?.length === 0 ? true : false    
        }]
        // const siteAddressPayload = [{  
        //     name: siteAddressName,
        //     address1 : siteAddress1,
        //     city : siteCity,
        //     stateProvince : siteStateProvince,
        //     postalCode : sitePostalCode,
        //     country:country || "United States",
        //     isDefault: savedAddresses?.length === 0 ? true : false    
        // }]
        if(this.state.creatingOrder) {
            await this.props.createOrderGuest(payload).then(async (response: any) => {
                this.setState({ creatingOrder: false })
                if (response?.status === 200) {
                    if (saveAddress){
                        await this.props.createNewAddress(addressPayload)
                    }
                    this.props.clearCart();
                    this.props.router?.navigate(`/order-confirmed/${response?.data[0]?.id}`);
                    localStorage.removeItem("order")
                }
                else {
                    const messageParts = response?.message.split(".\n");
                    const errorMessage = messageParts.length > 1 ? messageParts[1] : response?.message;
                    this.setState({ orderCreateError: true, orderCreateErrorMessage: errorMessage==="You can't create an order in the past" ? "Please select a Date and Time in the future": errorMessage, creatingOrder: false, openPayMethodModel: false, paymentPayload: null })
                }
                // if (response && Array.isArray(response.data)) {
                //     this.setState({ creatingOrder: false })
                //     this.props.clearCart();
                //     this.props.router?.navigate(`/order-confirmed/${response?.data[0]?.id}`);
                //     localStorage.removeItem("order")
                // } else {
                //     console.error("::::::: response create Order", response);
                //     this.setState({ creatingOrder: false });
                // }
            }).catch((err: any) => {
                this.setState({ creatingOrder: false });
                console.error("::::::: error create Order", err);
            });
        }
       
    };

    private createOrderGuestWithSkipifyTransactionDetails = async (paymentTransaction: IPaymentTransaction) => {
        const {
            firstName,
            lastName,
            phoneNumber,
            email,
            notes,
            orderType,
            companyName,
            addressName,
            address1,
            city,
            stateProvince,
            postalCode,
            country,
            savedAddresses,
            saveAddress,
            eventName,
            expectedGuestCount,
            eventDate,
            eventStart,
            eventEnd,
            laterSchedDate,
            appUserState,
            eventNotes,
            category,
            theme,
            county,
            orderPickListOptions
        } = this.state;
        const { organizations,organizationSettings } = this.props;
        const orderItems: any[] = [];
        this.props.order?.orderItems?.forEach((orderItem: IOrderItem) => {
            delete orderItem.menuCategoryItem;
            orderItem.organization = { id:  organizations?.organization?.id };
            orderItems.push({
                notes: orderItem?.notes,
                orderItemModifiers: transformArrayFromModifiers(orderItem?.orderItemModifiers),
                menuCategoryItemId: orderItem?.menuCategoryItemId, 
                quantity: orderItem?.quantity
            });
        })
        const start = moment(eventDate).format('YYYY-MM-DD') + moment(eventStart).utc().format('THH:mm:ss.SSS[Z]');
        const end = moment(eventDate).format('YYYY-MM-DD') + moment(eventEnd).utc().format('THH:mm:ss.SSS[Z]');
        const payload = [{
            organizationId: organizations?.organization?.id,
            "orderItems": orderItems,
            "orderRevenueTypeRates": [],
            orderType,
            userId: appUserState?.id ? appUserState.id : null,
            "orderStatusType": "New",
            customerFirstName: firstName,
            customerLastName: lastName,
            customerPhone: phoneNumber,
            customerEmail: email,
            notes: orderType==="Event" ? eventNotes : notes,
            "isAsap": false,
            "requestedDateTime": orderType === 'Event' ? start : moment(laterSchedDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            "orderDeliveryAddress": this.getOrderDeliveryAddress(),
            "expectedGuestCount": orderType === 'Event' ? expectedGuestCount : (organizationSettings?.enableGuestCount ? expectedGuestCount : 0),
            orderPaymentTransactions: [paymentTransaction],
            "orderPickListOptions": orderPickListOptions || [],
            "orderEventDetails": orderType === "Event" ? {
                "categoryId": category || null,
                "themeId": theme || null,
                "company": companyName,
                "eventName": eventName,
                "eventStartTime": start,
                "eventEndTime": end
            } : null,
            user: {
                organizationId: organizations?.organization?.id,
                id: appUserState?.id ? appUserState.id : null,
            }
        }];
        const addressPayload = [{  
            name: addressName ,
            address1: replaceComma(address1),
            city,
            county,
            stateProvince,
            postalCode,
            country:country || "United States",
            isDefault: savedAddresses?.length === 0 ? true : false    
        }]
        await this.props.createOrderGuest(payload).then(async (response: any) => {
            if (response?.status === 200) {
                if (saveAddress){
                    await this.props.createNewAddress(addressPayload)
                }
                this.setState({
                    creatingOrderWithSkipify: false,
                    skipifyResponse: null
                })
                this.props.clearCart();
                localStorage.removeItem("order")
                this.setState({ skipifySuccessOrderID: response?.data[0]?.id })
            }
            else {
                const messageParts = response?.message.split(".\n");
                const errorMessage = messageParts.length > 1 ? messageParts[1] : response?.message;
                this.setState({ creatingOrderWithSkipify: false, orderCreateError: true, orderCreateErrorMessage: errorMessage==="You can't create an order in the past" ? "Please select a Date and Time in the future": errorMessage, creatingOrder: false, openPayMethodModel: false, paymentPayload: null, skipifyResponse: null })
            }
        })
    };

    private payLater = async () => {
        const {
            firstName,
            lastName,
            phoneNumber,
            email,
            notes,
            orderType,
            undeliverablePostalCodes,
            undeliverableDistances,
            undeliverableTravelTime,
            postalCode,
            selectedAddressId,
            savedAddresses,
            saveAddress,
            addressName,
            address1,
            city,
            stateProvince,
            country,
            companyName,
            // siteAddressName,
            // siteAddress1,
            // siteCity,
            // sitePostalCode,
            // siteStateProvince,
            eventName,
            expectedGuestCount,
            eventDate,
            eventStart,
            eventEnd,
            laterSchedDate,
            eventNotes,
            category,
            theme,
            distanceInMiles,
            travelTimeInMins,
            county,
            orderPickListOptions
        } = this.state;
        const { organizationSettings } = this.props;
        const start = new Date(moment(eventDate).format('YYYY-MM-DD') + moment(eventStart).utc().format('THH:mm:ss.SSS[Z]'))
        const selectedAddress = savedAddresses?.find((address: any) => { return address?.id === selectedAddressId });
        const invalidPostalCode = selectedAddress ? undeliverablePostalCodes?.find((code: string) => code === selectedAddress?.postalCode) : undeliverablePostalCodes?.find((code: string) => code === postalCode);
        const invalidDistanceInMiles = undeliverableDistances?.find((dist: string) => distanceInMiles >= Number(dist));
        const invalidDriveDuration = undeliverableTravelTime?.find((time: string) => travelTimeInMins >= Number(time));
        if (orderType === "Delivery" && (invalidPostalCode || invalidDistanceInMiles || invalidDriveDuration)) {
            this.setState({ orderCreateError: true, orderCreateErrorMessage: this.props.organizationSettings?.ordersUndeliverableMessage })
        }
        else {
            if(moment(moment(orderType === "Event" ? start : laterSchedDate).format()).isBefore(moment(moment().format()))){
                this.setState({ orderCreateError: true, orderCreateErrorMessage: "Please select a Date and Time in the future"})
            }
            else if(moment(moment(orderType === "Event" ? start : laterSchedDate).format()).isBefore(moment(this.state.calculatedEstimatedTimeFromAPI))){
                this.setState({ orderCreateError: true, orderCreateErrorMessage: `The earliest available time to place an order is ${moment(this.state.calculatedEstimatedTimeFromAPI).format("MM/DD/YYYY hh:mm A")}`})
            }
            else{
                this.setState({ creatingOrder: true })
                await this.checkAppUserAvailable();
                const orderItems: any[] = [];

                const { organizations, appUserDetails } = this.props;
                const userDetails: any = this.state.appUserState ? this.state.appUserState : appUserDetails;

                this.props.order.orderItems.forEach((orderItem: IOrderItem) => {
                    delete orderItem.menuCategoryItem;
                    orderItem.organization = { id:organizations?.organization?.id };
                    orderItems.push({
                        notes: orderItem?.notes,
                        orderItemModifiers: transformArrayFromModifiers(orderItem?.orderItemModifiers),
                        menuCategoryItemId: orderItem?.menuCategoryItemId, 
                        quantity: orderItem?.quantity
                    });
                })
                const start = moment(eventDate).format('YYYY-MM-DD') + moment(eventStart).utc().format('THH:mm:ss.SSS[Z]');
                const end = moment(eventDate).format('YYYY-MM-DD') + moment(eventEnd).utc().format('THH:mm:ss.SSS[Z]');
                const payload = [{
                    organizationId: organizations?.organization?.id,
                    "orderItems": orderItems,
                    "orderRevenueTypeRates": [],
                    orderType,
                    "orderStatusType": "New",
                    customerFirstName: firstName,
                    customerLastName: lastName,
                    customerPhone: phoneNumber,
                    customerEmail: email,
                    notes: orderType==="Event" ? eventNotes : notes,
                    "isAsap": false,
                    "requestedDateTime": orderType === 'Event' ? start : moment(laterSchedDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
                    "orderDeliveryAddress": this.getOrderDeliveryAddress(),
                    "expectedGuestCount": orderType === 'Event' ? expectedGuestCount : (organizationSettings?.enableGuestCount ? expectedGuestCount : 0),
                    userId: userDetails?.id,
                    "orderPickListOptions": orderPickListOptions || [],
                    "orderEventDetails": orderType === "Event" ? {
                        "categoryId": category || null,
                        "themeId": theme || null,
                        "company": companyName,
                        "eventName": eventName,
                        "eventStartTime": start,
                        "eventEndTime": end
                    } : null,
                    user: {
                        organizationId:organizations?.organization?.id,
                        id: userDetails?.id,
                    }
                }]
                const addressPayload = [{  
                    name: addressName,
                    address1: replaceComma(address1),
                    city,
                    stateProvince,
                    county,
                    postalCode,
                    country:country || "United States",
                    isDefault: savedAddresses?.length === 0 ? true : false    
                }]
                // const siteAddressPayload = [{  
                //     name: siteAddressName,
                //     address1 : siteAddress1,
                //     city : siteCity,
                //     stateProvince : siteStateProvince,
                //     postalCode : sitePostalCode,
                //     country:country || "United States",
                //     isDefault: savedAddresses?.length === 0 ? true : false    
                // }]
                this.props.createOrderGuest(payload).then(async (res: any) => {
                    this.setState({ creatingOrder: false })
                    if (res?.status === 200) {
                        if (saveAddress){
                            await this.props.createNewAddress(addressPayload)
                        }
                        this.props.clearCart();
                        this.props.router?.navigate(`/order-confirmed/${res?.data[0]?.id}`);
                        localStorage.removeItem("order")
                    }
                    else {
                        const messageParts = res?.message.split(".\n");
                        const errorMessage = messageParts.length > 1 ? messageParts[1] : res?.message;
                        this.setState({ orderCreateError: true, orderCreateErrorMessage: errorMessage==="You can't create an order in the past" ? "Please select a Date and Time in the future": errorMessage })
                    }

                }).catch((err: any) => {
                    this.setState({ creatingOrder: false })
                });
            }
        }
    }

    private skipifySuccess = async () => {
        this.setState({
            creatingOrderWithSkipify: true
        })
        const { skipifyResponse } = this.state;

        

        const pollPaymentDetails = async (retries = 50, delay = 2000) => {
            for (let i = 0; i < retries; i++) {
                try {
                    const response: any = await this.getCartPaymentDetailsSkipify();
                    const data = response?.data;
    
                    if (data?.pspTransactionId) { // Wait until pspTransactionId is available
                        return data;
                    }
                } catch (error) {
                    console.error("Error fetching payment details:", error);
                }
    
                await new Promise((resolve) => setTimeout(resolve, delay));
            }
            throw new Error("Timeout waiting for pspTransactionId.");
        };

        try {
            const paymentDetails = await pollPaymentDetails(); // Poll until pspTransactionId is available

            this.createOrderGuestWithSkipifyTransactionDetails({
                cCTransactionId: paymentDetails.pspTransactionId,
                paymentProcessorTransactionId: paymentDetails.pspTransactionId,
                transactionState: 3,
                transactionType: 'Online',
                paymentMethod: 'Credit Card',
                cardIssuer: skipifyResponse?.data?.customerInfo?.paymentMethod?.cardBrand,
                maskedCardNumber: skipifyResponse?.data?.customerInfo?.paymentMethod?.last4CardNumber,
                madeBy: skipifyResponse?.data?.customerInfo?.email,
                comment: 'skipify',
                cCPostal: skipifyResponse?.data?.billingAddress?.zipCode,
                cCStatus: 'Completed',
                paymentMethodId: 't1_tok_00000000000000000',
                cCAuthCode: '',
                totalPaid: skipifyResponse?.data?.total?.value / 100 || 0,
                totalTip: (skipifyResponse?.data?.tipAmount?.value / 100) || 0,
                totalRefund: 0,
                dateApplied: new Date(),
                totalSurcharge: skipifyResponse?.data?.fees.find((fee: any) => fee.type === "surcharge")?.value / 100 || 0
            });

        } catch (error) {
            console.error("Failed to retrieve pspTransactionId:", error);
        }
    }

    private payAtStore = async (event: any) => {
        event.preventDefault();
        // const { selectedDate } = this.state;
        // const { onlineOrderEnabled } = await this.props.onlineOrdersEnabled(selectedDate);
        const onlineOrderEnabled = true;
        if (!onlineOrderEnabled) {
            this.setState({ onlineOrderEnabled });
            return;
        }

        if (this.props.isLoggedIn || this.props.isGuestLogin) {
            if (this.validator.allValid()) {
                const order: any = await this.saveOrder(true);
                this.orderSetDefault(order.id);
            } else {
                this.validator.showMessages();
                this.forceUpdate();
            }
        } else {
            this.props.handleClickOpenSignInModel();
        }
    };

    private setOrderType = (orderType: 'Delivery' | 'Pickup' | 'Event') => {
        this.setState({ orderType }, () => {
            // LocalStorage.save(LocalStorage.keys.orderType, orderType);
            // this.setState({ orderSaveRequest: true, scheduleForLater: false }, () => {
            //     this.calculateEstimatedTime();
            // });
            this.setState({
                address1: '',
                city: '',
                country: '',
                postalCode: '',
                stateProvince: '',
                distanceInMiles: 0,
                travelTimeInMins: 0
            }, () => {
                this.getCalculatedDistanceAndTravelTime()
            })
        });
    }

    private backToProductEdit = (product: IOrderItem | any) => {
        this.props.selectedCategoryMenuProduct(product?.menuCategoryItem, product);
        this.props.openProductDetailPageModel();
    }

    private selectAddress = async (place: any) => {
        await handleAddressSearchDone(place, this.handleValueChange);

        if (place === null) {
            this.setState({ address1: '', city: '', stateProvince: '', postalCode: '' }, () => {
                this.calculateEstimatedTime();
            });
        } else {
            this.setState({
                orderSaveRequest: true,
                addressName: !!place && !!place.structured_formatting && !!place.structured_formatting.main_text
                    ? place.structured_formatting.main_text
                    : this.state.addressName
            });
        }

        this.setContactInfoOnLocalStorage();
    }

    private getContactInfoFromLocalStorage = () => {
        return LocalStorage.get<any>(LocalStorage.keys.contactInfo);
    }

    private setContactInfoOnLocalStorage = () => {
        const {
            firstName,
            lastName,
            phoneNumber,
            email,
            address1,
            city,
            postalCode,
            stateProvince,
            notes,
            companyName,
            eventName,
            dateOfEvent,
            expectedGuestCount,
            eventStartTime,
            eventEndTime,
            theme,
            category,
            eventNotes,
            selectedRoom,
            selectedSetupStyle,
            detailsExpended,
            operation,
        } = this.state;
        const contactInfo = {
            firstName,
            lastName,
            phoneNumber,
            email,
            address1,
            city,
            postalCode,
            stateProvince,
            notes,
            companyName,
            eventName,
            dateOfEvent,
            expectedGuestCount,
            eventStartTime,
            eventEndTime,
            theme,
            category,
            eventNotes,
            selectedRoom,
            selectedSetupStyle,
            detailsExpended,
            operation,
        };
        LocalStorage.save(LocalStorage.keys.contactInfo, contactInfo);
    }

    private resetContactInfoOnLocalStorage = () => {
        LocalStorage.remove(LocalStorage.keys.contactInfo);
    }

    private mapOrderType = (orderType: string): 'Delivery' | 'Pickup' | 'Event' => {
        switch (orderType) {
            case 'delivery':
                return 'Delivery'
            case 'pickup':
                return 'Pickup';
            case 'event':
                return 'Event';
            default:
                return 'Delivery';
        }
    }

    private getConfiguredOrdertype = (): 'Delivery' | 'Pickup' | 'Event' => {
        const { settings } = this.props;
        const storedOrderType = LocalStorage.get<'Delivery' | 'Pickup'>(LocalStorage.keys.orderType);
        const settingOrderType = !!settings.GRAZE_ORDER_TYPE ? settings.GRAZE_ORDER_TYPE.value : {};

        if (!!settingOrderType.preserveUserChoice &&
            !!storedOrderType &&
            !!settingOrderType.enabledOrderType[storedOrderType.toLowerCase()]
        ) {
            return storedOrderType;
        }

        return this.mapOrderType(settingOrderType.defaultOrderType);
    }

    private completeOrderSaveRequest = (): Promise<IOrder> => {
        return new Promise((resolve, reject) => {
            this.setState({ orderSaveRequest: false, estimatedTimeCheck: new Date() }, async () => {
                const order: any = await this.saveOrder();
                this.calculateEstimatedTime();

                resolve(order);
            });
        });
    }

    private orderRemoveItem = async (id: number): Promise<IOrder> => {
        return new Promise((resolve, reject) => {
            this.setState({ orderSaveRequest: false, estimatedTimeCheck: new Date() }, async () => {
                const order = await this.props.orderRemoveItem(id);
                this.calculateEstimatedTime();

                resolve(order);
                const items = localStorage.getItem("order");
                if (items) {
                    const orderParsed = JSON.parse(items)
                    if (orderParsed?.orderItems?.length === 1) {
                        localStorage.removeItem("order")
                    }
                }
            });
        });
    }

    public calculateEstimatedTime = () => {
        const { order } = this.props;
        const { orderType, scheduleForLater, estimatedTimeCheck, address1, throttlingBuffer } = this.state;
        const throttlingBufferMs = !!throttlingBuffer && throttlingBuffer > 0 ? throttlingBuffer * 60 * 1000 : 0;
        let estimatedReadyTime: any;
        if (order) {
            estimatedReadyTime = new Date(order?.estimatedReadyTimeMin ? order.estimatedReadyTimeMin : order.estimatedReadyTimeMax).getTime();
        }

        const estimatedDriveDurationMs =
            orderType === 'Delivery'
                && !!address1
                && !!order.orderDelivery
                && !!order.orderDelivery.estimatedDriveDuration
                && !!order.orderDelivery.address1
                ? order.orderDelivery.estimatedDriveDuration * 1000
                : 0;

        const dateCheckOffset = estimatedTimeCheck ? Date.now() - estimatedTimeCheck.getTime() : Date.now();
        const estimatedReadyTimeMax = new Date(estimatedReadyTime + estimatedDriveDurationMs + throttlingBufferMs + dateCheckOffset);
        const selectedDate = scheduleForLater && estimatedReadyTimeMax <= this.state.selectedDate ? this.state.selectedDate : estimatedReadyTimeMax;

        this.setState({ estimatedReadyTimeMax }, () => this.handleDateChange(selectedDate));
    }

    private renderOrderMinimumMessage = (orderMinimumNotMet: boolean, orderMinimumMessage: string, orderMinimum: number) => {
        const { classes } = this.props;
        return orderMinimumNotMet && orderMinimumMessage &&
            <Grid item={true} xs={12} sm={12}>
                <div className={classes.orderMessage}>
                    {orderMinimumMessage.replace('{orderMinimum}', `$${formatMoney(orderMinimum)}`)} <NavLink to="/">Continue Shopping </NavLink>
                </div>
            </Grid>
    }

    private renderOnlineOrderClosedMessage = (onlineOrderEnabled: boolean | undefined, onlineOrderClosedMessage: string) => {
        const { classes } = this.props;
        return onlineOrderEnabled === false && onlineOrderClosedMessage &&
            <Grid item={true} xs={12} sm={12}>
                <div className={classes.orderMessage}>
                    {onlineOrderClosedMessage}
                </div>
            </Grid>
    }

    private renderOnlineOrderTermsMessage = (onlineOrderClosedMessage: string, onlineOrderTermsMessage: string) => {
        const { classes } = this.props;

        return !onlineOrderClosedMessage && onlineOrderTermsMessage &&
            <Grid item={true} xs={12} sm={12}>
                <div className={classes.orderMessage}>
                    {onlineOrderTermsMessage}
                </div>
            </Grid>
    }

    private renderUndeliverableZoneMessage = (undeliverableZone: boolean, undeliverableZoneMessage: string) => {
        const { classes } = this.props;

        return undeliverableZone && !!undeliverableZoneMessage &&
            <Grid item={true} xs={12} sm={12}>
                <div className={classes.orderMessage}>
                    {undeliverableZoneMessage}
                </div>
            </Grid>
    }

    private renderThrottlingOverloadMessage = (throttlingOverload: boolean, throttlingOverloadMessage: string) => {
        const { classes } = this.props;

        return throttlingOverload && !!throttlingOverloadMessage &&
            <Grid item={true} xs={12} sm={12}>
                <div className={classes.orderMessage}>
                    {throttlingOverloadMessage}
                </div>
            </Grid>
    }

    private renderSaveToProfile = () => {
        const { classes } = this.props;
        const { guest, saveNewAddress } = this.state;

        return !guest &&
            <Grid item={true} xs={12} sm={4}>
                <FormControlLabel
                    classes={{ label: classes.formControlLabel }}
                    label={
                        <span>
                            Save to profile
                        </span>
                    }
                    control={
                        <Checkbox
                            checked={saveNewAddress}
                            size='small'
                            icon={<CheckBoxOutlineBlank className={classes.sizeIcon} />}
                            checkedIcon={<CheckBox className={classes.sizeIcon} />}
                            onClick={() => this.onSaveNewAddressChecked()}
                        />
                    }
                />

            </Grid>
    }

    private couponButtonClick = async (removeCouponState: boolean) => {
        const { applyCoupon, removeCoupon, order } = this.props;
        const { couponCode } = this.state;

        let updatedOrder: any = null;
        if (removeCouponState) {
            updatedOrder = await removeCoupon(order.id);
            if (!!updatedOrder) {
                this.setState({
                    couponCode: '',
                    couponError: false,
                });
            }
        } else {
            updatedOrder = await applyCoupon(order.id, couponCode);
            if (!updatedOrder) {
                this.setState({
                    couponCode: (order.coupon?.code ? order.coupon.code : ''),
                    couponError: true,
                });
            }
        }
    }

    private onUpdateItemQuantity = (orderItem: IOrderItem, newQuantity: number) => {
        const configuredMinQty = orderItem?.menuCategoryItem?.menuItem?.minimumQuantity;
        const qtyError: any = { ...this.state.qtyError };

        if (configuredMinQty && configuredMinQty > newQuantity) {
            qtyError[orderItem.id] = `Min qty: ${configuredMinQty}`;
            newQuantity = configuredMinQty;
        }

        const newOrderQuantity = { ...this.state.newOrderQuantity };
        newOrderQuantity[orderItem.id] = newQuantity;

        this.setState({
            ...this.state,
            newOrderQuantity,
            qtyError,
        }, () => {
            this.setState({ orderSaveRequest: true });
            setTimeout(() => {
                this.setState({
                    qtyError: {},
                })
            }, 5000);
        });
    }

    private goToDashboard = () => {
        this.props.router?.navigate('/dashboard');
    }

    public componentWillMount = () => {
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            validators: {
                address: {
                    message: 'The :attribute must be a valid address.',
                    rule: (val: string, validator: any): boolean => {
                        return validator?.helpers?.testRegex(val, /^[a-zA-Z0-9\s,'-.#:]*$/i)
                    }
                },
                noSpecialChar: {
                    message: 'The :attribute must be without special characters.',
                    rule: (val: string, validator: any) => {
                        return validator?.helpers?.testRegex(val, /^[a-zA-Z0-9\s,'-.!:]*$/i)
                    }
                },
            },
        });
        this.cardFormValidator = new SimpleReactValidator({ autoForceUpdate: this });
    }

    public componentDidMount = async () => {
        const { order, appUserDetails, fetchUndeliverablePostalCodes, organizations } = this.props;
        
        // this.props.getSettingAreas();
        // this.props.getRooms();
        // this.props.getEventPickLists();
        // await this.props.orderGet(order.id);
        // await this.checkUserProfile();
        if (appUserDetails) {
            this.setState({ appUserState: appUserDetails });
        }
        
        if (order?.coupon?.code) {
            this.setState({ couponCode: order.coupon.code });
        }

        if (this.props.isGuestLogin && !this.props.utility.openSignInModel) {
            this.props.handleClickOpenSignInModel();
        }
        if(this.props.organizationOrdersOpenSchedule){
            this.getCalculatedEstimatedTimeFromAPI()
        }

        this.setState({ orderSaveRequest: true });

        window.addEventListener('message', this.handleMessage);
        this.calculateEstimatedTimeTimer = setInterval(
            () => this.calculateEstimatedTime(),
            1 * 60 * 1000
        );

        this.updateUserType();
        this.props.addressesGet().then((res: any) => {
            if (res?.status === 200) {
                this.setState({ savedAddresses: res?.data, selectedAddressId: res?.data?.length > 0 ? res?.data?.find((addrs: any) => addrs.isDefault === true)?.id : "" })
            }
            if (typeof res === "string") {
                this.setState({ savedAddresses: [] })
                Auth0WithOutRedirect.show();
            }
        })
        fetchUndeliverablePostalCodes().then((res: any) => {
            if (res?.status === 200) {
                this.setState({ undeliverablePostalCodes: res?.data?.postalCodes, undeliverableDistances: res?.data?.distances, undeliverableTravelTime: res?.data?.driveDurations })
            }
            if (typeof res === "string") {
                this.setState({ undeliverablePostalCodes: [], undeliverableDistances: [], undeliverableTravelTime: [] })
            }
        })
        this.props.getPickListOptionsFromAPI("Category").then((res: any)=> {
            this.setState({
                pickListCategoryOptions: res?.data || []
            })
        })
        this.props.getPickListOptionsFromAPI("Theme").then((res: any)=> {
            this.setState({
                pickListThemeOptions: res?.data || []
            })
        })
        this.props.getPickListOptionsFromAPI("Operation").then((res: any) => {
            this.setState({
                pickListOperationOptions: res?.data || []
            })
        })
        this.props.getPickLists(organizations?.organization?.id).then((res: any) => {
            this.setState({
                pickListByOrganization: res?.data || []
            });
        });
        
        
        await this.loadGoogleMapsAPI();

        if(this.state.orderType === "Delivery"){
            this.getCalculatedPaymentsFromAPI();
        }
    }

private loadGoogleMapsAPI = (): Promise<void> => {
    const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    return new Promise((resolve, reject) => {
        if (window.google) {
            resolve(); // Google Maps API is already loaded
        } else {
            const existingScript = document.getElementById('googleMaps');
            if (!existingScript) {
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`;
                script.id = 'googleMaps';
                document.body.appendChild(script);

                script.onload = () => {
                    resolve();
                };
                script.onerror = (error) => {
                    reject(error);
                };
            } else {
                existingScript.onload = () => resolve();
            }
        }
    });
};

    private updateUserType() {
        const { oidc } = this.props;

        this.setState({ guest: (oidc?.user?.profile?.preferred_username === 'GuestUser' && oidc?.user?.profile?.email === 'support@horizonbusinessservices.com') });
    }

    public componentWillUnmount() {
        window.removeEventListener('message', this.handleMessage);
        clearInterval(this.calculateEstimatedTimeTimer);
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    private getCalculatedPaymentsFromAPI() {
        if (this.props.order?.orderItems?.length === 0) {
            this.setState({ totalTax: 0, total: 0, subtotal: 0, serviceChargeTotal: 0, gratuityTotal: 0, deliveryFee: 0 });
        }
        else {
            this.setState({ calculatePaymentLoading: true });
            const ordItems = this.props.order?.orderItems?.map(
                (item: any)=> {
                    return {
                    menuCategoryItemId: item?.menuCategoryItemId,
                    quantity: item?.quantity,
                    orderItemModifiers: transformArrayFromModifiers(item?.orderItemModifiers),
                    notes: item?.notes
                    }
                }
            );
            const body = {
                orderItems: ordItems,
                additionalChargeSources: this.state.orderType === "Delivery" ? {
                deliveryPostalCode: this.getOrderDeliveryAddress()?.postalCode,
                estimatedDriveDuration: this.state.travelTimeInMins,
                distanceInMiles: this.state.distanceInMiles
                }: {
                },
                orderRevenueTypeCounty: this.getCounty()
            }
            this.props.fetchCalculatedPayment(body).then((res: any)=> {
                this.setState({
                    calculatePaymentLoading: false,
                    totalTax: res?.data?.taxTotal,
                    total: res?.data?.total,
                    subtotal: res?.data?.subTotal,
                    serviceChargeTotal: res?.data?.serviceCharge,
                    gratuityTotal: res?.data?.gratuity,
                    deliveryFee: res?.data?.deliveryFee
                }, () => {
                    this.callSkipifyEndpoint();
                });
            })
        }
    }

    private getCalculatedEstimatedTimeFromAPI() {
        if (this.props.order?.orderItems?.length === 0) {
            this.setState({
                laterSchedDate: new Date(),
                minimumPickupDate: new Date(),
                eventDate:new Date(),
                eventStart:new Date(),
                pickupDeliveryTime:new Date(), 
                eventEnd: new Date(new Date().getTime() + 15 * 60000),
                calculatedEstimatedTimeFromAPI: null
            })
        }
        else {
            this.setState({ calculateEstimatedTimeLoading: true });
            const orderItems = this.props.order?.orderItems?.map(
                (item: any)=> {
                    return {
                        menuCategoryItemId: item?.menuCategoryItemId, 
                        quantity: item?.quantity,
                    }
                }
            );
            this.props.fetchCalculatedEstimatedTime({orderItems}).then((res: any)=> {
                this.setState({
                    laterSchedDate:  moment(this.getNextAvailableTime(res?.data,this.props.organizationOrdersOpenSchedule,this.props.organizationSettings)).toDate(),
                    minimumPickupDate:moment(res?.data).toDate(),
                    pickupDeliveryTime:moment(this.getNextAvailableTime(res?.data,this.props.organizationOrdersOpenSchedule,this.props.organizationSettings)).toDate(),
                    eventDate:moment(this.getNextAvailableTime(res?.data,this.props.organizationOrdersOpenSchedule,this.props.organizationSettings)).toDate(),
                    eventStart:moment(res?.data).toDate(),
                    eventEnd:new Date(moment(res?.data).toDate().getTime() + 15 * 60000),
                    calculatedEstimatedTimeFromAPI: moment(res?.data).format()
                }, () => {
                    this.setState({
                        calculateEstimatedTimeLoading: false, 
                    })
                });
            })
        }
    }

    private getCalculatedDistanceAndTravelTime(){
        const {orderType, address1,city,stateProvince,postalCode, savedAddresses} = this.state;
        const {organization} = this.props;
        const selectedAddress = savedAddresses?.find((address: any) => { return address?.isDefault });

        if(orderType==="Delivery"  && window.google){
            const service = new google.maps.DistanceMatrixService();
            const request = {
                origins: [`${organization?.address1}, ${organization?.address2 ? `${organization?.address2}, ` : ""}${organization?.city}, ${organization?.stateProvince} ${organization?.postalCode}` || ""],
                destinations: [`${address1}, ${city}, ${stateProvince} ${postalCode}` || `${selectedAddress?.address1} ,${selectedAddress?.city}, ${selectedAddress?.stateProvince} ${selectedAddress.postalCode}` || ""],
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
            };
            service.getDistanceMatrix(request).then((response: any) => {
                const distance = response.rows[0].elements[0].distance.value; // Distance in meters
                const distanceInMiles = distance * 0.000621371; // Convert to miles
                const duration = response.rows[0].elements[0].duration.value; // Duration in Seconds
                const durationInMins = duration / 60; // Convert to Minutes
                this.setState({
                    distanceInMiles: Math.round(distanceInMiles),
                    travelTimeInMins: Math.round(durationInMins),
                }, ()=> {
                    this.getCalculatedPaymentsFromAPI();
                })
            }).catch((err: any)=> {
                this.setState({
                    distanceInMiles: 0,
                    travelTimeInMins: 0
                }, ()=> {
                    this.getCalculatedPaymentsFromAPI();
                })
            });
        }
        else{
            this.setState({
                distanceInMiles: 0,
                travelTimeInMins: 0
            }, ()=> {
                this.getCalculatedPaymentsFromAPI();
            })
        }
    }


    public async componentDidUpdate(prevProps: IPageProps, prevState: IPageState) {
        if (this.state.orderType !== prevState.orderType) {
            this.setState({ saveAddress: false })
            if (this.state.orderType === "Delivery" || this.state.orderType === "Event") {
                // if (this.state.savedAddresses?.length > 0) {
                //         const selectedAddress = this.state.savedAddresses?.find((address: any) => { return address?.isDefault });
                //         this.setState({
                //             selectedAddressId: selectedAddress?.id,
                //             address1: selectedAddress?.address1,
                //             city: selectedAddress?.city,
                //             stateProvince: selectedAddress?.stateProvince,
                //             postalCode: selectedAddress?.postalCode,
                //         })
                // }
            }
        }
if (prevProps.pickListByOrganization !== this.props.pickListByOrganization) {
    const hasRequiredForEvent = this.props.pickListByOrganization?.some((item: any) => item?.isRequiredForEvent);
    this.setState({ detailsExpended: hasRequiredForEvent });
}
if (prevProps.pickListByOrganization !== this.props.pickListByOrganization) {
    const hasRequiredForDelivery = this.props.pickListByOrganization?.some((item: any) => item?.isRequiredForDelivery);
    this.setState({ detailsExpended: hasRequiredForDelivery });
}
if (prevProps.pickListByOrganization !== this.props.pickListByOrganization) {
    const hasRequiredForPickup = this.props.pickListByOrganization?.some((item: any) => item?.isRequiredForPickup);
    this.setState({ detailsExpended: hasRequiredForPickup });
}
        if (this.state.postalCode !== prevState.postalCode) {
            this.getCalculatedPaymentsFromAPI()
        }
        if (this.state.address1 !== prevState.address1) {
            this.getCalculatedDistanceAndTravelTime()
        }
        if (this.props.order !== prevProps.order) {
            // this.getCalculatedEstimatedTimeFromAPI();
            this.getCalculatedDistanceAndTravelTime();
        }
        // if(this.props.order !== prevProps.order && this.props.order.total===prevProps.order?.total){
        //     this.getCalculatedEstimatedTimeFromAPI();
        // }
        if(this.props.organizationOrdersOpenSchedule !== prevProps.organizationOrdersOpenSchedule){
            this.getCalculatedEstimatedTimeFromAPI()
        }
        if (this.props.organizationSettings !== prevProps.organizationSettings) {
            if (this.props.organizationSettings?.defaultOrderType === "Delivery") {
                this.setState({ orderType: "Delivery" })
            }
            else if (this.props.organizationSettings?.defaultOrderType === "Event") {
                this.setState({ orderType: "Event" })
            }
            else {
                this.setState({ orderType: "Pickup" })
            }
        }
        const { customer, settings, order, userPersonalDetails } = this.props;
        const { estimatedReadyTimeMax, orderSaveRequest, throttlingBuffer } = this.state;

        if (prevProps.order?.coupon?.code !== order?.coupon?.code && !!order?.coupon?.code) {
            this.setState({ couponCode: order.coupon.code });
        }

        if (orderSaveRequest && !!this.props.order.id && this.state.orderType !== null) {
            await this.completeOrderSaveRequest();
        }

        if (prevState.estimatedReadyTimeMax === undefined && estimatedReadyTimeMax) {
            this.setState({ selectedDate: estimatedReadyTimeMax });
        }

        if (prevProps.customer !== customer) {
            this.checkUserProfile();
        }

        if (prevProps.settings !== settings) {
            const companyLogo = this.props.organizationSettings?.grazeLogo || "/static/images/logos/defaultLogo.svg";
            const orderMinimum = settings.GRAZE_ORDER_MINIMUM
                ? parseFloat(settings.GRAZE_ORDER_MINIMUM.value.replace(',', '.'))
                : 0;
            const orderMinimumMessage = settings.GRAZE_ORDER_MINIMUM_MESSAGE ? settings.GRAZE_ORDER_MINIMUM_MESSAGE.value : '';
            const onlineOrderClosedMessage = settings.GRAZE_ONLINE_ORDERS_CLOSED_MESSAGE ? settings.GRAZE_ONLINE_ORDERS_CLOSED_MESSAGE.value : '';
            const undeliverableZoneMessage = settings.GRAZE_ORDER_UNDELIVERABLE_ZONE ? settings.GRAZE_ORDER_UNDELIVERABLE_ZONE.value : '';
            const throttlingOverloadMessage = settings.GRAZE_ORDER_THROTTLING_MESSAGE ? settings.GRAZE_ORDER_THROTTLING_MESSAGE.value : '';
            const showAsap = settings.GRAZE_CHECKOUT_ASAP ? settings.GRAZE_CHECKOUT_ASAP.value === false : true;
            // const orderType = this.getConfiguredOrdertype();
            const onlineOrderTermsMessage = settings.GRAZE_ORDER_TERMS_CUSTOM_MESSAGE ? settings.GRAZE_ORDER_TERMS_CUSTOM_MESSAGE.value : '';

            this.setState({
                companyLogo,
                showAsap,
                // orderType,
                // scheduleForLater: showAsap ? this.state.scheduleForLater : true,
                scheduleForLater: true,
                orderMinimum,
                orderMinimumMessage,
                onlineOrderClosedMessage,
                undeliverableZoneMessage,
                throttlingOverloadMessage,
                onlineOrderTermsMessage,
                firstName: this.state.firstName || (userPersonalDetails?.firstName || ''),
                lastName: this.state.lastName || (userPersonalDetails?.lastName || ''),
                phoneNumber: this.state.phoneNumber || (userPersonalDetails?.phoneNumber || ''),
                email: this.state.email || (userPersonalDetails?.emailAddress || ''),
            });
        }

        if (throttlingBuffer && throttlingBuffer > 0 && prevState.throttlingBuffer !== throttlingBuffer) {
            this.calculateEstimatedTime();
        }

        if (prevProps.oidc?.user?.profile?.email !== this.props.oidc?.user?.profile?.email) {
            this.updateUserType();
        }
        if(prevProps.organizationSettings !== this.props.organizationSettings && prevProps.organizationSettings?.useSkipify !== this.props.organizationSettings?.useSkipify){
            if(this.props.organizationSettings?.useSkipify){
                this.callSkipifyEndpoint();
            }
        }
        if(!this.state.creatingOrderWithSkipify && this.state.skipifyClose){
            if(this.state.skipifySuccessOrderID){
                this.props.router?.navigate(`/order-confirmed/${this.state.skipifySuccessOrderID}`);
            }
        }
        if (this.state.skipifyPopupOpen && !prevState.skipifyPopupOpen) {
            // If skipifyPopupOpen changed to true, start a 5-minute timer
            this.timer = setTimeout(() => {
              window.location.reload();
            }, 300000);
        } else if (!this.state.skipifyPopupOpen && prevState.skipifyPopupOpen) {
            // If skipifyPopupOpen changed to false, clear the timer
            if (this.timer) {
              clearTimeout(this.timer);
              this.timer = null;
            }
        }
        
    }

    public getNextAvailableTime(laterSchedDate: any, organizationOrdersOpenSchedule: any, organizationOrders: any) {
        let nextAvailableDate = moment(laterSchedDate);
        let attempts = 0;
        let isFirstDay = true;
      
        while (true) {
          const dayOfWeek = nextAvailableDate.format('dddd').toLowerCase();
          const daySettings = organizationOrdersOpenSchedule[dayOfWeek];
      
          if (organizationOrders.ordersHolidays.find((holiday:any) => moment(holiday.holidayDate).isSame(nextAvailableDate, 'day'))) {
            nextAvailableDate.add(1, 'days').startOf('day');
            attempts++;
            if (attempts > 7) {
              console.log("Unable to find an open slot within a week.");
              return laterSchedDate;
            }
            continue;
          }
      
          if (!daySettings.closedAllDay) {
            const openingTime = moment(new Date(`${new Date().toISOString().split('T')[0]}T${daySettings?.openFrom}Z`));
            const closingTime = moment(new Date(`${new Date().toISOString().split('T')[0]}T${daySettings?.openTo}Z`));
      
            if (closingTime.hour() < openingTime.hour()) {
              closingTime.add(1, 'days');
            }
      
            openingTime.year(nextAvailableDate.year()).month(nextAvailableDate.month()).date(nextAvailableDate.date());
            closingTime.year(nextAvailableDate.year()).month(nextAvailableDate.month()).date(nextAvailableDate.date());
      
            if (isFirstDay) {
              if (nextAvailableDate.isBetween(openingTime, closingTime, null, '[)')) {
                return nextAvailableDate.format('YYYY-MM-DDTHH:mm:ss');
              } else if (nextAvailableDate.isBefore(openingTime)) {
                return roundToNext15Min(openingTime).format('YYYY-MM-DDTHH:mm:ss');
              }
              isFirstDay = false;
            } else {
              return roundToNext15Min(openingTime).format('YYYY-MM-DDTHH:mm:ss');
            }
          }
      
          nextAvailableDate.add(1, 'days').startOf('day');
          attempts++;
          if (attempts > 7) {
            console.log("Unable to find an open slot within a week.");
            return laterSchedDate;
          }
        }
      }
    

    private payDisabled = () => {
        const {
            firstName,
            lastName,
            phoneNumber,
            email,
            address1,
            stateProvince,
            postalCode,
            city,
            orderType
        } = this.state;
        if (orderType === "Delivery") {
            if ((!address1 || !city || !stateProvince || !postalCode) && this.state.selectedAddressId === "") {
                return true
            }
        }
        if (firstName === "" || lastName === "" || phoneNumber === "" || email === "") {
            return true;
        }
        return false
    }

    private postCartToSkipify = (body: any): Promise<AxiosResponse | AxiosError> => {
        const grazeApiKey = process.env.REACT_APP_GRAZE_API_KEY;
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_PAYMENT_URL}/skipify/cart`, body,
                {
                    headers: {
                      'Graze-Api-Key': grazeApiKey,
                      'Content-Type': 'application/json',
                    },
                }
            )
                .then((res: AxiosResponse) => {
                    resolve(res)
                })
                .catch((err: AxiosError) => {
                    reject(err)
                })
        })
    }

    private getCartPaymentDetailsSkipify = (): Promise<AxiosResponse | AxiosError> => {
        const grazeApiKey = process.env.REACT_APP_GRAZE_API_KEY;
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_PAYMENT_URL}/skipify/cart/${this.state.skipifyGuid}`,
                {
                    headers: {
                      'Graze-Api-Key': grazeApiKey,
                      'Content-Type': 'application/json',
                    },
                }
            )
                .then((res: AxiosResponse) => {
                    resolve(res)
                })
                .catch((err: AxiosError) => {
                    reject(err)
                })
        })
    }

    private callSkipifyEndpoint = () => {
        if(this.props.organizationSettings?.useSkipify){
            this.setState({ skipifyPayButtonLoading: true })
            const cardRefId = uuidv4();
            const skipifyCartItems = this.props.order.orderItems.map((item: any) => {
                return {
                    itemId: `${item?.id}`,
                    title: item?.name,
                    quantity: item?.quantity,
                    productType: "physical",
                    price: {
                    value: Math.round(item?.price * 100),
                    uom: "USD"
                    },
                    image: {
                    url: item?.menuCategoryItem?.imageUrl,
                    alt: ""
                    }
                }
            })
            if(skipifyCartItems?.length > 0){
                const skipifyPayload = {
                    id: uuidv4(),
                    skipifyMerchantId: this.props.organizationSettings.skipifyMerchantID,
                    cartReferenceId: cardRefId,
                    cartPayload: JSON.stringify([...skipifyCartItems, 
                        {
                            itemId: "00000",
                            title: "Service Charge",
                            quantity: 1,
                            productType: "service",
                            price: {
                                value: Math.round(this.state.serviceChargeTotal * 100),
                                uom: "USD"
                            },
                            image: {
                                url: "",
                                alt: ""
                            }
                        },
                        {
                            itemId: "00000",
                            title: "Gratuity",
                            quantity: 1,
                            productType: "service",
                            price: {
                                value: Math.round(this.state.gratuityTotal * 100),
                                uom: "USD"
                            },
                            image: {
                                url: "",
                                alt: ""
                            }
                        },
                        {
                            itemId: "00000",
                            title: "Delivery Fee",
                            quantity: 1,
                            productType: "service",
                            price: {
                                value: Math.round(this.state.deliveryFee * 100),
                                uom: "USD"
                            },
                            image: {
                                url: "",
                                alt: ""
                            }
                        }
                    ]),
                    taxAmount: Math.round(this.state.totalTax * 100),
                    currency: "USD"
                }
                this.postCartToSkipify(skipifyPayload).then((res) => {
                    this.setState({
                        skipifyGuid: cardRefId,
                        skipifyPayButtonLoading: false
                    })
                })
            }
        }
    }

    public render() {
        const {
            companyLogo,
            firstName,
            lastName,
            email,
            phoneNumber,
            address1,
            addressName,
            postalCode,
            city,
            // siteCity,
            // sitePostalCode,
            // siteAddress1,
            saveAddress,
            // siteAddressName,
            // siteStateProvince,
            stateProvince,
            notes,
            openPayMethodModel,
            openOpenHoursModel,
            openPolicyModel,
            selectedDateError,
            orderType,
            showAsap,
            onlineOrderClosedMessage,
            undeliverableZoneMessage,
            onlineOrderEnabled,
            throttlingBuffer,
            throttlingOverloadMessage,
            estimatedReadyTimeMax,
            companyName,
            eventName,
            eventDate,
            eventStart,
            eventEnd,
            /*      addressName,
                 selectedDate,
                 eventSelectedEndDate,
                 orderMinimum,
                 orderMinimumMessage,
                 dateOfEvent,
                 eventStartTime,
                 couponError,
                 guest,
                 newAddress,
                 operation, */

            expectedGuestCount,
            eventEndTime,
            theme,
            category,
            operation,
            eventNotes,
            // selectedRoom,
            // selectedSetupStyle,
            detailsExpended,
            pickListCategoryOptions,
            pickListByOrganization,
            pickListThemeOptions,
            pickListOperationOptions,
            onlineOrderTermsMessage,
            qtyError,
            paymentPayload,
            laterSchedDate,
            minimumPickupDate,
            paymentConfirmation,
            calculatePaymentLoading,
            totalTax,
            total,
            loadPaymentUrl,
            pickupDeliveryTime,
            subtotal,
            serviceChargeTotal,
        } = this.state;
        const { classes, order, organizationSettings, organizationOrdersOpenSchedule, organization } = this.props; //settings,
        // const payLater = settings.GRAZE_CHECKOUT_PAY_LATER && settings.GRAZE_CHECKOUT_PAY_LATER.value === false;
        // const payNow = settings.GRAZE_CHECKOUT_PAY_NOW && settings.GRAZE_CHECKOUT_PAY_NOW.value === false;
        // const settingOrderType = !!settings.GRAZE_ORDER_TYPE ? settings.GRAZE_ORDER_TYPE.value : {};
        // const pickupOrderTypeEnabled = settingOrderType.enabledOrderType ? settingOrderType.enabledOrderType.pickup : false;
        // const deliveryOrderTypeEnabled = settingOrderType.enabledOrderType ? settingOrderType.enabledOrderType.delivery : false;
        // const eventOrderTypeEnabled = settingOrderType.enabledOrderType ? settingOrderType.enabledOrderType.event : false;
        this.validator.purgeFields();
        this.cardFormValidator.purgeFields();
        const isOrderEmpty = !order || !order.orderItems || order.orderItems.length === 0;
        const orderMinimumNotMet = subtotal + serviceChargeTotal + totalTax < organizationSettings?.ordersMinimumTotal;
        // const deliveryWithoutAddress = orderType === 'Delivery' && (!order.orderDelivery || !order.orderDelivery.address1 || !address1);
        const undeliverableZone = (orderType === 'Delivery' && !!order.orderDelivery && order.orderDelivery.deliveryCharge === null)
        /* const applyCouponDisabled =
            orderMinimumNotMet
            || onlineOrderEnabled !== true
            || throttlingBuffer === -1
            || !!selectedDateError
            || !!order.placedDateTime
            || undeliverableZone; */

        // const orderDisabled = applyCouponDisabled || deliveryWithoutAddress;
        // const removeCouponState = !!order?.coupon && order.coupon.code === couponCode;
        // const showOperation = settings.GRAZE_CHECKOUT_ENABLE_OPERATION && settings.GRAZE_CHECKOUT_ENABLE_OPERATION.value === false;
        // const showCouponCode = settings.GRAZE_CHECKOUT_COUPON_CODE && settings.GRAZE_CHECKOUT_COUPON_CODE.value === false;
        // const showCouponCode = false;
        const start = moment(eventDate).format('YYYY-MM-DD') + moment(eventStart).utc().format('THH:mm:ss.SSS[Z]')
        const daySettings = organizationOrdersOpenSchedule ? organizationOrdersOpenSchedule[getDay(orderType === 'Event' ? start : laterSchedDate)] : null;
        const openingTime = `${new Date().toISOString().split('T')[0]}T${daySettings?.openFrom}Z`;
        const closingTime = `${new Date().toISOString().split('T')[0]}T${daySettings?.openTo}Z`;
        const startLimit = moment(new Date(openingTime)).format('HH:mm');
        const endLimit = moment(new Date(closingTime)).format('HH:mm')
        const pickupTime = roundToNext15Min(moment(pickupDeliveryTime)).format('hh:mm A');





        const selectedDate = moment(laterSchedDate).format('YYYY-MM-DD');
        const formattedEventDate = moment(eventDate).format('YYYY-MM-DD');

        const daysSettings = organizationSettings?.ordersHolidays?.find(
        (holiday: any) =>  moment(holiday.holidayDate).format('YYYY-MM-DD') === selectedDate );

        const openingHours = daysSettings?.openFrom
        ?  moment.utc(`1970-01-01T${daysSettings.openFrom}Z`).local().format('HH:mm:ss') 
        : startLimit;

        const closingHours = daysSettings?.openTo
        ? moment.utc(`1970-01-01T${daysSettings.openTo}Z`).local().format('HH:mm:ss') 
        : endLimit;        

        const eventTimeSettings = organizationSettings?.ordersHolidays?.find((holiday: any) => {
            return moment(holiday.holidayDate).format('YYYY-MM-DD') === formattedEventDate;
        });
        
        const eventOpeningHours = eventTimeSettings?.openFrom
        ?  moment.utc(`1970-01-01T${eventTimeSettings.openFrom}Z`).local().format('HH:mm:ss') 
        : startLimit;

        const eventClosingHours = eventTimeSettings?.openTo
        ? moment.utc(`1970-01-01T${eventTimeSettings.openTo}Z`).local().format('HH:mm:ss') 
        : endLimit;     

        const schedule = getWeeklySchedule(organizationOrdersOpenSchedule);
        const formattedHolidays = getFormattedHolidays(organizationSettings);

        function isKitchenClosed() {
            const start = moment(eventDate).format('YYYY-MM-DD') + moment(eventStart).utc().format('THH:mm:ss.SSS[Z]')
            if (!organizationSettings?.enableOrders) {
                return true;
            }
            else {
                const daySettings = organizationOrdersOpenSchedule ? organizationOrdersOpenSchedule[getDay(orderType === 'Event' ? start : laterSchedDate)] : null;
                if (daySettings?.openAllDay) {
                    return false
                }
                return !checkIfOpenUsingHoursandScheduledTime(daySettings?.openFrom, daySettings?.openTo, moment(orderType === 'Event' ? start : new Date(laterSchedDate)).utc().format("HH:mm:ss"))
            }
        }

        const eventAdditionalDetails = pickListByOrganization?.some((item:any) => item?.isSelectedForEvent);
        const deliveryAdditionalDetails = pickListByOrganization?.some((item:any) => item?.isSelectedForDelivery);
        const pickupAdditionalDetails = pickListByOrganization?.some((item:any) => item?.isSelectedForPickup);

        const pickupOverride = pickListByOrganization?.filter((item: any) => item.isSelectedForPickup);
      
        const customName = pickupOverride?.map((item: any) => item?.customName) ;


        const deliveryOverrides = pickListByOrganization?.filter(
            (item: any) => item?.isSelectedForDelivery
          );
          
          const customNames = deliveryOverrides?.map((item: any) => item?.customName);

          const EventOverrides = pickListByOrganization?.filter(
            (item: any) => item?.isSelectedForEvent
          );
          const eventCustomNames = EventOverrides?.map((item: any) => item?.customName);


          return (
            <Auth0Provider>
                <div className={classes.root}>
                    {/* {forceLogin && this.loginPopUp()} */}
                    {organizationSettings && this.state.undeliverablePostalCodes ? <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} sm={7} className={classes.gridContent}>
                            <Grid container={true} spacing={4} >
                                <Container component="main" disableGutters={false}>
                                    <Box >
                                    {this.renderOnlineOrderClosedMessage(onlineOrderEnabled, onlineOrderClosedMessage)}
                                    {this.renderUndeliverableZoneMessage(undeliverableZone, undeliverableZoneMessage)}
                                    {this.renderThrottlingOverloadMessage(throttlingBuffer === -1, throttlingOverloadMessage)}
                                    {this.renderOnlineOrderTermsMessage(onlineOrderClosedMessage, onlineOrderTermsMessage)}
                                    {orderMinimumNotMet && (
                                        <Stack
                                            sx={{
                                                background: "#ffc700",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <Typography variant='caption'>{organizationSettings?.ordersMinimumMessage.replace('{orderMinimum}', `$${formatMoney(organizationSettings?.ordersMinimumTotal)}`)} <NavLink to="/">Continue Shopping</NavLink></Typography>
                                        </Stack>
                                    )}
                                        <Box sx={{marginTop: {xs: '15%', md: 0}}}>
                                            <Typography gutterBottom={true} mb={2} mt={2} sx={{ fontSize: { sm: '20px' }, fontWeight: 600 }}>Order Type</Typography>
                                        </Box>
                                    <Stack gap={3} direction='row'>
                                        {organizationSettings?.enablePickupOrders &&
                                            <OrderTypeButton disableRipple={true}
                                                    sx={orderType === 'Pickup' ?
                                                        {
                                                            color: 'layoutColor.contrastText',
                                                            backgroundColor: organizationSettings?.layoutColor,
                                                            '&.MuiButton-root:hover': { backgroundColor: organizationSettings?.layoutColor }
                                                        } : {
                                                            backgroundColor: organizationSettings?.layoutColor && lighten(organizationSettings?.layoutColor, 0.5),
                                                            '&.MuiButton-root:hover': { backgroundColor: organizationSettings?.layoutColor && lighten(organizationSettings?.layoutColor, 0.5) }
                                                        }}
                                                onClick={() => this.setOrderType('Pickup')}
                                            >
                                                <Typography sx={{fontSize: {sm: '20px'}}}>Pickup</Typography>
                                            </OrderTypeButton>
                                        }
                                        {organizationSettings?.enableDeliveryOrders &&
                                                <OrderTypeButton disableRipple={true}
                                                    sx={orderType === 'Delivery' ?
                                                        {
                                                            color: 'layoutColor.contrastText',
                                                            backgroundColor: organizationSettings?.layoutColor,
                                                            '&.MuiButton-root:hover': { backgroundColor: organizationSettings?.layoutColor }
                                                        } : {
                                                            backgroundColor: organizationSettings?.layoutColor && lighten(organizationSettings?.layoutColor, 0.5),
                                                            '&.MuiButton-root:hover': { backgroundColor: organizationSettings?.layoutColor && lighten(organizationSettings?.layoutColor, 0.5) }
                                                        }}

                                                    onClick={() => this.setOrderType('Delivery')}
                                            >
                                                <Typography sx={{fontSize: {sm: '20px'}} }>Delivery</Typography>
                                            </OrderTypeButton>
                                        }
                                        {organizationSettings?.enableEventOrders && 
                                                <OrderTypeButton disableRipple={true}
                                                    sx={orderType === 'Event' ?
                                                        {
                                                            color: 'layoutColor.contrastText',
                                                            backgroundColor: organizationSettings?.layoutColor,
                                                            '&.MuiButton-root:hover': { backgroundColor: organizationSettings?.layoutColor }
                                                        } : {
                                                            backgroundColor: organizationSettings?.layoutColor && lighten(organizationSettings?.layoutColor, 0.5),
                                                            '&.MuiButton-root:hover': { backgroundColor: organizationSettings?.layoutColor && lighten(organizationSettings?.layoutColor, 0.5) },
                                                        }}
                                                onClick={() => this.setOrderType('Event')}
                                            >
                                                <Typography sx={{fontSize: {sm: '20px'}} }>Event</Typography>
                                            </OrderTypeButton>
                                        }
                                    </Stack>
                                    {orderType === 'Event'
                                        ? <>
                                            <Typography gutterBottom={true} sx={{ mt: 4, mb: 2, fontSize: { sm: '20px' }, fontWeight: 600 }}>Client Information</Typography>
                                            <Grid container={true} spacing={2} direction='row'>
                                                <Grid item={true} xs={12} sm={12}>
                                                    <StyledTextField
                                                        id="companyName"
                                                        label="Company (Optional)"
                                                        className={`${classes.marginBottom5} ${classes.fullWidth}`}
                                                        value={companyName}
                                                        variant="outlined"
                                                        onChange={(e: any) => this.onhandleChange(e, 'companyName')}
                                                    />
                                                </Grid>
                                                <Grid item={true} xs={12} sm={6}>
                                                    <StyledTextField
                                                        autoComplete="given-name"
                                                        name="firstName"
                                                        className={classes.marginBottom5}
                                                        required={true}
                                                        id="firstName"
                                                        label="First name"
                                                        value={firstName}
                                                        onChange={(e: any) => this.onhandleChange(e, 'firstName')}
                                                        onBlur={() => this.validator.message('firsrt name', typeof firstName === "string" ? firstName.trim() : firstName,
                                                            'required|noSpecialChar'
                                                        )}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                    />
                                                    {this.validator.message('first name', firstName, 'required|noSpecialChar')}
                                                </Grid>
                                                <Grid item={true} xs={12} sm={6}>
                                                    <StyledTextField
                                                        name="lastName"
                                                        autoComplete="family-name"
                                                        className={classes.marginBottom5}
                                                        required={true}
                                                        id="lastName"
                                                        value={lastName}
                                                        onChange={(e: any) => this.onhandleChange(e, 'lastName')}
                                                        onBlur={() => this.validator.message('last name', typeof lastName === "string" ? lastName.trim() : lastName,
                                                            'required|noSpecialChar'
                                                        )}
                                                        label="Last name"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                    />
                                                    {this.validator.message('last name', lastName, 'required|noSpecialChar')}
                                                </Grid>
                                                <Grid item={true} xs={12} sm={6}>
                                                    <StyledTextField
                                                        name="email"
                                                        autoComplete="email"
                                                        className={classes.marginBottom5}
                                                        required={true}
                                                        id="email"
                                                        value={email}
                                                        onChange={(e: any) => this.onhandleChange(e, 'email')}
                                                        onBlur={() => this.validator.showMessageFor('email')}
                                                        label="Email"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                    />
                                                    {this.validator.message('email', email, 'required|email')}
                                                </Grid>
                                                <Grid item={true} xs={12} sm={6}>
                                                    <StyledTextField
                                                        name="phoneNumber"
                                                        autoComplete="tel"
                                                        className={classes.marginBottom5}
                                                        required={true}
                                                        id="phoneNumber"
                                                        value={phoneNumber}
                                                        placeholder="(xxx) xxx-xxxx"
                                                        onChange={this.handlePhone}
                                                        onBlur={() => this.validator.message(
                                                            'phone number',
                                                            typeof phoneNumber === "string" ? phoneNumber.trim() : phoneNumber,
                                                            'required|phone'
                                                        )}
                                                        label="Phone Number"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                    />
                                                    {this.validator.message('phone number', phoneNumber, 'required|phone')}
                                                </Grid>
                                            </Grid>
                                            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                                <Typography gutterBottom={true} sx={{ mt: 4, mb: 2, fontSize: { sm: '20px' }, fontWeight: 600 }}>Event Information</Typography>
                                               
                                                <Box  sx={{display:'flex',alignItems:'center',gap:'15px'}}>
                                                    {organizationSettings?.ordersAdvanceNoticeHours > 0 &&
                                                    <Box onClick={()=>{this.setState({ openPolicyModel: true })}} sx={{ mt: 4, fontSize: { sm: '15px' }, fontWeight: 500 ,color:'#0000ef',cursor:'pointer'}}>
                                                        {'Store Policy'}
                                                    </Box>}
                                                    <Box onClick={()=>{this.setState({ openOpenHoursModel: true })}} sx={{ mt: 4, fontSize: { sm: '15px' }, fontWeight: 500 ,color:'#0000ef',cursor:'pointer'}}>
                                                        {'Store Hours'}
                                                    </Box>
                                                    {organizationSettings?.checkoutLinkName && organizationSettings?.checkoutLinkURL && (
                                                        <Link
                                                        href={organizationSettings.checkoutLinkURL.startsWith('http')
                                                            ? organizationSettings.checkoutLinkURL
                                                            : `https://${organizationSettings.checkoutLinkURL}`}
                                                        target="_blank"
                                                        sx={{ mt: 4, fontSize: { sm: '15px' }, fontWeight: 500, color: '#0000ef', cursor: 'pointer' }}
                                                        underline="none" 
                                                        >
                                                        {organizationSettings.checkoutLinkName}
                                                        </Link>)}
                                                </Box>
                                            </Box>
                                            <Grid container={true} spacing={2} direction='row'>
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <Grid item={true} xs={12} sm={12}>
                                                        <StyledTextField
                                                            id="eventName"
                                                            label="Event Name (Optional)"
                                                            className={`${classes.marginBottom5} ${classes.fullWidth}`}
                                                            value={eventName}
                                                            variant="outlined"
                                                            onChange={(e: any) => this.onhandleChange(e, 'eventName')}
                                                        />
                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={6}>
                                                        <DatePicker
                                                            label="Date Of Event"
                                                            value={this.state.eventDate}
                                                            onChange={(newValue) => this.setState({ eventDate: newValue })}
                                                            disablePast={true}
                                                            shouldDisableDate={(date) => {
                                                                const formattedDate = moment(date).format('YYYY-MM-DD'); 
                                                                const dayOfWeek = moment(date).format('dddd').toLowerCase();
                                                                const daySettings = organizationOrdersOpenSchedule ? organizationOrdersOpenSchedule[dayOfWeek] : null;
                                                                const isHoliday = organizationSettings?.ordersHolidays?.some((holiday:any) => moment(holiday.holidayDate).format('YYYY-MM-DD') === formattedDate && holiday.closedAllDay);
                                                                const isClosedAllDay = daySettings && daySettings.closedAllDay;
                                                            
                                                                return isHoliday || isClosedAllDay;
                                                              }}
                                                            minDate={minimumPickupDate ? minimumPickupDate : estimatedReadyTimeMax ? estimatedReadyTimeMax : new Date()}
                                                            slots={{
                                                                textField: TextField,
                                                            }}
                                                            slotProps={{
                                                                calendarHeader: {
                                                                    sx: {
                                                                        position: "relative",
                                                                        "& .MuiPickersArrowSwitcher-root": {
                                                                            width: 0,
                                                                        },
                                                                        "& .MuiPickersCalendarHeader-labelContainer": {
                                                                            mx: 'auto',
                                                                        },
                                                                        "& .MuiIconButton-edgeEnd": {
                                                                            position: "absolute",
                                                                            left: 0,
                                                                            top: 0,
                                                                            bottom: 0,
                                                                        },
                                                                        "& .MuiIconButton-edgeStart": {
                                                                            position: "absolute",
                                                                            right: 0,
                                                                            top: 0,
                                                                            bottom: 0,
                                                                        },
                                                                        "& .MuiPickersCalendarHeader-switchViewIcon": {
                                                                            display: "none",
                                                                        },
                                                                    }
                                                                },
                                                                openPickerButton: {
                                                                    sx: {
                                                                        "& .MuiSvgIcon-root": {
                                                                            height: "26px",
                                                                            width: "26px",
                                                                            color: "#000",
                                                                        },
                                                                    },
                                                                },
                                                                textField: {
                                                                    variant: "outlined",
                                                                    required: true,
                                                                    id: "dateOfEvent",
                                                                    name: "dateOfEvent",
                                                                    fullWidth: true,
                                                                    InputProps: {
                                                                        sx: {
                                                                            borderRadius: "8px",
                                                                            "&.MuiOutlinedInput-root": {
                                                                                fontSize: '18px',
                                                                            },
                                                                        },
                                                                    },
                                                                    InputLabelProps: {
                                                                        sx: {
                                                                            "&.MuiInputLabel-root": { fontSize: "18px" }
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                        />
                                                        <div className={classes.selectedDateErrorMessage}>
                                                            {selectedDateError}
                                                        </div>
                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={6}>
                                                        <StyledTextField
                                                            name="expectedGuestCount"
                                                            type="number"
                                                            className={classes.marginBottom5}
                                                            required={true}
                                                            id="expectedGuestCount"
                                                            value={expectedGuestCount}
                                                            onChange={(e: any) => this.onhandleChange(e, 'expectedGuestCount')}
                                                            label="Expected Guest Count"
                                                            InputLabelProps={{ shrink: true }}
                                                            fullWidth={true}
                                                            variant="outlined"
                                                            inputProps={{ style: { textAlign: 'left' } }}
                                                        />
                                                        {this.validator.message('expected guest count', expectedGuestCount, 'required|noSpecialChar')}
                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={12}>
                                                        {/* <TimePicker
                                                            label="Event Start Time"
                                                            value={this.state.eventStart}
                                                            onChange={(newValue) => {
                                                                this.setState({ eventStart: newValue, eventEnd: newValue.getTime() + 15 * 60000 });
                                                            }}
                                                            views={['hours', 'minutes']}
                                                            format="hh:mm a"
                                                            slots={{
                                                                textField: TextField,
                                                            }}
                                                            slotProps={{
                                                                layout: {
                                                                    sx: {
                                                                        "& .MuiDialogActions-root": {
                                                                            display: "none",
                                                                        },
                                                                    },
                                                                },
                                                                openPickerButton: {
                                                                    sx: {
                                                                        "& .MuiSvgIcon-root": {
                                                                            height: "26px",
                                                                            width: "26px",
                                                                            color: "#000",
                                                                        },
                                                                    },
                                                                },
                                                                textField: {
                                                                    variant: "outlined",
                                                                    required: true,
                                                                    id: "eventStartTime",
                                                                    name: "eventStartTime",
                                                                    fullWidth: true,
                                                                    InputProps: {
                                                                        sx: {
                                                                            borderRadius: "8px",
                                                                            "&.MuiOutlinedInput-root": {
                                                                                fontSize: '18px',
                                                                            },
                                                                        },
                                                                    },
                                                                    InputLabelProps: {
                                                                        sx: {
                                                                            "&.MuiInputLabel-root": { fontSize: "18px" }
                                                                        },
                                                                    },
                                                                },
                                                            }}

                                                        /> */}
                                                        <TimeSelection
                                                                    label={'Event Start Time'}
                                                                    startTime={eventOpeningHours} 
                                                                    endTime={eventClosingHours} 
                                                                    value={pickupTime} 
                                                                    outlined
                                                                    handleChange={(value) => {
                                                                        const [time, period] = value.split(' ');
                                                                        const [hours, minutes] = time.split(':');
                                                                        let dateTime = moment(laterSchedDate);

                                                                        const numericHours = parseInt(hours);
                                                                        const isPM = period === 'PM';
                                                                        const isNoon = numericHours === 12;
                                                                        
                                                                        const adjustedHours = isPM ? (isNoon ? 12 : numericHours + 12) : (isNoon ? 0 : numericHours);

                                                                        dateTime.hour(adjustedHours).minute(parseInt(minutes)).second(0);

                                                                        const newDate = dateTime.toDate();
                                                                    
                                                                        if (newDate.toISOString() !== eventStart.toISOString()) {
                                                                            this.setState({ eventStart: newDate,eventEnd: new Date(new Date().getTime() + 15 * 60000) }); 
                                                                        }
                                                                    }}
                                                                    />
                                                        <div className={classes.selectedDateErrorMessage}>
                                                            {selectedDateError}
                                                        </div>
                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={12}>
                                                        {/* <TimePicker
                                                            label="Event End Time"
                                                            value={this.state.eventEnd}
                                                            onChange={(newValue) => {this.setState({ eventEnd: newValue })}}
                                                            minTime={this.state.eventStart.getTime() + 15 * 60000}
                                                            views={['hours', 'minutes']}
                                                            format="hh:mm a"
                                                            slots={{
                                                                textField: TextField,
                                                            }}
                                                            slotProps={{
                                                                layout: {
                                                                    sx: {
                                                                        "& .MuiDialogActions-root": {
                                                                            display: "none",
                                                                        },
                                                                    },
                                                                },
                                                                openPickerButton: {
                                                                    sx: {
                                                                        "& .MuiSvgIcon-root": {
                                                                            height: "26px",
                                                                            width: "26px",
                                                                            color: "#000",
                                                                        },
                                                                    },
                                                                },
                                                                textField: {
                                                                    variant: "outlined",
                                                                    required: true,
                                                                    id: "eventEndTime",
                                                                    name: "eventEndTime",
                                                                    fullWidth: true,
                                                                    InputProps: {
                                                                        sx: {
                                                                            borderRadius: "8px",
                                                                            "&.MuiOutlinedInput-root": {
                                                                                fontSize: '18px',
                                                                            },
                                                                        },
                                                                    },
                                                                    InputLabelProps: {
                                                                        sx: {
                                                                            "&.MuiInputLabel-root": { 
                                                                                fontSize: "18px" 
                                                                            },
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                        /> */}
                                                        <TimeSelection
                                                                    label={'Event End Time'}
                                                                    startTime={moment(this.state.eventStart.getTime() + 15 * 60000).format('HH:mm')} 
                                                                    endTime={eventClosingHours} 
                                                                    outlined
                                                                    value={roundToNext15Min(moment(this.state.eventStart.getTime() + 15 * 60000)).format('hh:mm A')} 
                                                                    handleChange={(value) => {
                                                                        const [time, period] = value.split(' ');
                                                                        const [hours, minutes] = time.split(':');
                                                                        let dateTime = moment(laterSchedDate);

                                                                        const numericHours = parseInt(hours);
                                                                        const isPM = period === 'PM';
                                                                        const isNoon = numericHours === 12;
                                                                        
                                                                        const adjustedHours = isPM ? (isNoon ? 12 : numericHours + 12) : (isNoon ? 0 : numericHours);

                                                                        dateTime.hour(adjustedHours).minute(parseInt(minutes)).second(0);

                                                                        const newDate = dateTime.toDate();
                                                                        if (newDate.toISOString() !== eventEnd.toISOString()) {
                                                                            this.setState({ eventEnd: newDate }); 
                                                                        }
                                                                    
                                                                    }}
                                                                    />
                                                        {this.validator.message('event end time', eventEnd, 'required')}
                                                    </Grid>
                                                </LocalizationProvider >
                                            </Grid>
                                            {/* {organizationSettings?.enableLocation && <>
                                                    <Box sx={{mb: 2}}>
                                                        <Typography gutterBottom={true} sx={{ mt: 2, mb: 2, fontSize: { sm: '20px' }, fontWeight: 600 }}>Location</Typography>
                                                        <Grid container={true} spacing={2} direction='row'>
                                                            <Grid item={true} xs={12} sm={6}>
                                                                {pickListOperationOptions ? <StyledTextField
                                                                    name="operation"
                                                                    className={classes.marginBottom5}
                                                                    id="operation"
                                                                    label="Location"
                                                                    value={operation}
                                                                    onChange={(e: any) => this.onhandleChange(e, 'operation')}
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    required={false}
                                                                    select={true}
                                                                >
                                                                    {pickListOperationOptions?.sort((a: any, b: any) => a.sortOrder - b.sortOrder).map((operationOption: any) =>
                                                                        <MenuItem key={`operation-${operationOption.id}`} value={operationOption.id} sx={{ fontSize: { xs: '18px' } }}>
                                                                            {operationOption.name}
                                                                        </MenuItem>
                                                                    )}
                                                                </StyledTextField> :
                                                                    <Box>
                                                                        <CircularProgress color='secondary' size={20} />
                                                                    </Box>}
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                            </>} */}
                                            {orderType === 'Event' && <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between'}}> <Typography sx={{ mt: 4, mb: 2, fontSize: { sm: '20px' }, fontWeight: 600 }} gutterBottom={true}>Address</Typography>
                                            { this.state.selectedAddressId !== "" && 
                                                                <CloseIcon 
                                                                onClick={()=>this.setState({
                                                                selectedAddressId: '',
                                                                address1: '',
                                                                city: '',
                                                                stateProvince: '',
                                                                postalCode: '', })} 
                                                                sx={{fontSize: 24,color:'red',cursor:'pointer'}} />} </Box>}
                                            {orderType === 'Event' && this.state.selectedAddressId !== "" && this.state.savedAddresses && this.state.savedAddresses?.length > 0 && <Grid container={true} spacing={2} direction='row'>
                                                <AddressSelector
                                                    selectedAddressId={this.state.selectedAddressId}
                                                    addresses={this.state.savedAddresses}
                                                    onAddressSelect={(addressId: any) => {
                                                        if(addressId==="new"){
                                                            this.setState({
                                                                selectedAddressId: '' ,
                                                                addressName: '',
                                                                address1: '',
                                                                city: '',
                                                                stateProvince: '',
                                                                postalCode: '', 
                                                            })
                                                        }
                                                        else{
                                                            const selectedAddress = this.state.savedAddresses?.find((address: any) => { return address?.id === addressId });
                                                            this.setState({
                                                                selectedAddressId: addressId,
                                                                address1: selectedAddress?.address1,
                                                                city: selectedAddress?.city,
                                                                stateProvince: selectedAddress?.stateProvince,
                                                                postalCode: selectedAddress?.postalCode,
                                                            })
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            }
                                            {orderType === 'Event' && ((this.state.savedAddresses && (this.state.savedAddresses?.length === 0)) || this.state.selectedAddressId==="") && <>
                                            <Typography gutterBottom={true} sx={{ mt: 4, mb: 2, fontSize: {sm: '20px'}, fontWeight: 600 }}>Site Information</Typography>
                                                <Grid container={true} spacing={2} direction='row'>
                                                    <Grid item={true} xs={12} sm={12}>
                                                        <AddressAutocomplete
                                                            onPlaceSelect={(place: any) => {
                                                                    this.setState({
                                                                        addressName:place?.name,
                                                                        address1: place?.address,
                                                                        city: place?.city,
                                                                        county:place?.county,
                                                                        country: place?.country,
                                                                        postalCode: place?.postalCode,
                                                                        stateProvince: place?.state
                                                                    })
                                                            }}
                                                        />
         
                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={6}>
                                                        <StyledTextField
                                                            name="city"
                                                            autoComplete="address-level2"
                                                            className={classes.marginBottom5}
                                                            id="city"
                                                            value={city}
                                                            onChange={(e) => this.onhandleChange(e, 'city')}
                                                            label="City"
                                                            variant="outlined"
                                                            fullWidth={true}
                                                            type="text"
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={2}>
                                                        <StyledTextField
                                                            name="stateProvince"
                                                            autoComplete="address-level1"
                                                            className={classes.marginBottom5}
                                                            id="siteStateProvince"
                                                            value={stateProvince}
                                                            onChange={(e) => this.onhandleChange(e, 'stateProvince')}
                                                            label="State"
                                                            variant="outlined"
                                                            fullWidth={true}
                                                            type="text"
                                                            disabled
                                                        />

                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={4}>
                                                        <StyledTextField
                                                            name="postalCode"
                                                            autoComplete="postal-code"
                                                            className={classes.marginBottom5}
                                                            id="postalCode"
                                                            value={postalCode}
                                                            onChange={(e) => this.onhandleChange(e, 'postalCode')}
                                                            placeholder="e.g. 10002"
                                                            label="Postal code"
                                                            variant="outlined"
                                                            fullWidth={true}
                                                            type='text'
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={12}>
                                                        <StyledTextField
                                                            name="addressName"
                                                            autoComplete="postal-code"
                                                            className={classes.marginBottom5}
                                                            required={false}
                                                            id="addressName"
                                                            value={addressName}
                                                            onChange={(e) => this.onhandleChange(e, 'addressName')}
                                                            label="Address Name"
                                                            variant="outlined"
                                                            fullWidth={true}
                                                            type='text'
                                                        />
                                                    </Grid>
                                                    {this.props.isLoggedIn &&
                                                    <Grid item xs={12} sm={4}>
                                                        <FormControlLabel label={<>
                                                            <Typography sx={{ 'p':{fontSize: '16px !important'}}}>
                                                                    Save Address
                                                                </Typography>
                                                                </>}
                                                                control={
                                                                    <Checkbox
                                                                        color="secondary"
                                                                        checked={saveAddress}
                                                                        size='small'
                                                                        icon={<CheckBoxOutlineBlank  />}
                                                                        checkedIcon={<CheckBox />}
                                                                        onChange={(e) => this.setState({ saveAddress: e.target.checked })}
                                                                    />}
                                                        />
                                                    </Grid>
                                                    }
                                                </Grid>
                                                </>}
                                            {/* {!guest && <>
                                                <Typography gutterBottom={true} sx={{ mt: 4, mb: 2, fontSize: {sm: '20px'}, fontWeight: 600 }}>Site Information</Typography>
                                                <Grid container={true} spacing={2} direction='row'>
                                                    <AddressSelector
                                                        onAddressSelected={this.onAddressSelected}
                                                        onNewAddressSelected={this.onNewAddressSelected}
                                                        newAddressName={newAddress && addressName}
                                                    />
                                                </Grid>
                                            </>}
                                            {(guest || newAddress) && <>
                                                <Typography gutterBottom={true} sx={{ mt: 4, mb: 2, fontSize: {sm: '20px'}, fontWeight: 600 }}>Site Information</Typography>
                                                <Grid container={true} spacing={2} direction='row'>
                                                    <Grid item={true} xs={12} sm={12}>
                                                        <form autoComplete="off">
                                                            <AddressSearch
                                                                required={true}
                                                                id="address1"
                                                                label="Address"
                                                                className={classes.marginBottom5}
                                                                onBlur={() => this.validator.message(
                                                                    'address',
                                                                    typeof address1 === "string" ? address1.trim() : address1,
                                                                    'required|min:5',
                                                                )}
                                                                value={address1}
                                                                variant="outlined"
                                                                onPlaceSelect={this.selectAddress}
                                                                onDone={(e: any) => this.handleValueChange(e, 'address1')}
                                                            />
                                                        </form>
                                                        {this.validator.message('address', address1, 'required|min:5')}
                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={6}>
                                                        <StyledTextField
                                                            name="city"
                                                            autoComplete="address-level2"
                                                            className={classes.marginBottom5}
                                                            required={true}
                                                            id="city"
                                                            value={city}
                                                            onChange={(e) => this.onhandleChange(e, 'city')}
                                                            onBlur={() => this.validator.message('city', typeof city === "string" ? city.trim() : city,
                                                                'required|min:3'
                                                            )}
                                                            label="City"
                                                            fullWidth={true}
                                                            variant="outlined"
                                                            disabled={true}
                                                        />
                                                        {this.validator.message('city', city, 'required|min:3')}
                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={2}>
                                                        <StyledTextField
                                                            name="stateProvince"
                                                            autoComplete="address-level1"
                                                            id="stateProvince"
                                                            required={true}
                                                            value={stateProvince}
                                                            className={classes.marginBottom5}
                                                            onChange={(e) => this.onhandleChange(e, 'stateProvince')}
                                                            onBlur={() => this.validator.message('stateProvince', typeof stateProvince === "string" ? stateProvince.trim() : stateProvince,
                                                                'required|min:2|alpha_space'
                                                            )}
                                                            variant="outlined"
                                                            label="State"
                                                            fullWidth={true}
                                                            disabled={true}
                                                        />

                                                        {this.validator.message('stateProvince', stateProvince, 'required|min:2|alpha_space')}
                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={4}>
                                                        <StyledTextField
                                                            name="postalCode"
                                                            autoComplete="postal-code"
                                                            className={classes.marginBottom5}
                                                            required={true}
                                                            id="postalCode"
                                                            value={postalCode}
                                                            onChange={(e) => this.onhandleChange(e, 'postalCode')}
                                                            onBlur={() => this.validator.message('postal code', typeof postalCode === "number" ? postalCode : postalCode,
                                                                'required|min:5|max:10'
                                                            )}
                                                            placeholder="e.g. 10002"
                                                            label="Postal code"
                                                            variant="outlined"
                                                            fullWidth={true}
                                                            disabled={true}
                                                        />
                                                        {this.validator.message('postal code', postalCode, 'required|min:5|max:10')}
                                                    </Grid>
                                                    {this.renderSaveToProfile()}
                                                </Grid>
                                            </>} */}
                                            {eventAdditionalDetails && (
                                            <Box>
                                                <Accordion
                                                    sx={{ boxShadow: 'none' }}
                                                    className={classes.expansionPanel}
                                                    expanded={detailsExpended} 
                                                    onChange={() => {
                                                        this.setState({ detailsExpended: !detailsExpended });
                                                    }}>
                                                    <AccordionSummary
                                                        sx={{ p: 0 }}
                                                        className={classes.expansionPanelSummary}
                                                        expandIcon={
                                                            <ExpandMoreOutlined sx={{
                                                                marginLeft: '5px', 
                                                                width: '20px',
                                                                height: '20px',
                                                                color: organizationSettings?.layoutColor,
                                                                marginBottom: '5px',
                                                            }} />
                                                        }>
                                                        <Typography
                                                            className={classes.expansionPanelSummarySubtitle}
                                                            gutterBottom={true}
                                                            sx={{ fontSize: { sm: '20px' }, color: organizationSettings?.layoutColor }}
                                                        >
                                                            Additional Details
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails className={classes.expansionPanelContent}>
                                                        <Grid container={true} spacing={2} direction='row'>
                                                        {eventCustomNames.map((name: string, index: number) => {                                 
    if (!name) return null;
    const matchingEvent = EventOverrides.find((event:any) => event.customName === name);
    // Extract pickListOptions if available
    const pickListId = matchingEvent?.pickListId;
    const options = matchingEvent?.pickList?.pickListOptions || [];
    return (
      <Grid item xs={12} sm={6} key={index}>
        <StyledTextField
          name={name}
          id={`theme-${index}`}
          label={name}
          value={this.state.selectedNames[index] || ''}
          onChange={(e: any) => {
            const newSelectedOptions = [...this.state.selectedNames];
            newSelectedOptions[index] = e.target.value;
            this.setState({ selectedNames: newSelectedOptions });
            const selectedOptionId = e.target.value;
            if (selectedOptionId && pickListId) {


                
              const orderPickListOptions = this.state.orderPickListOptions || [];
              
              const existingIndex = orderPickListOptions.findIndex(
                (item: any) => item.pickListId === pickListId
              );
              
              if (existingIndex >= 0) {
                const updatedOptions = [...orderPickListOptions];
                updatedOptions[existingIndex] = {
                  pickListId: pickListId,
                  pickListOptionId: selectedOptionId
                };
                this.setState({ orderPickListOptions: updatedOptions });
              } else {
                this.setState({
                  orderPickListOptions: [
                    ...orderPickListOptions,
                    {
                      pickListId: pickListId,
                      pickListOptionId: selectedOptionId
                    }
                  ]
                });
              }
            }
            this.onhandleChange(e, name);
          }}
          fullWidth
          variant="outlined"
          required={false}
          select
        >
 {options.length > 0 ? (
              options.map((option: any) => (
                <MenuItem key={`menu-${option.id}`} value={option.id} sx={{ fontSize: { xs: "18px" } }}>
                  {option.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No options available</MenuItem>
            )}
        </StyledTextField>
      </Grid>
    );
  })}

                                                            {/* <Grid item={true} xs={12} sm={6}>
                                                                <StyledTextField
                                                                    name="banquetRoom"
                                                                    select={true}
                                                                    label="Banquet Room"
                                                                    className={classes.marginBottom5}
                                                                    id="banquetRoom"
                                                                    value={!!selectedRoom && selectedRoom.id}
                                                                    onChange={this.handleRoomChange}
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                >
                                                                    <MenuItem key={0} value={0}>
                                                                        None
                                                                    </MenuItem>
                                                                    {rooms?.map((room: any) =>
                                                                        <MenuItem key={room.id} value={room.id}>
                                                                            {room.name} (${formatMoney(room.rate)})
                                                                        </MenuItem>
                                                                    )}
                                                                </StyledTextField>
                                                            </Grid> */}
                                                            {/* <Grid item={true} xs={12} sm={6}>
                                                                <StyledTextField
                                                                    name="setupStyle"
                                                                    className={classes.marginBottom5}
                                                                    id="setupStyle"
                                                                    value={!!selectedSetupStyle && selectedSetupStyle.id}
                                                                    onChange={this.handleSetupStyleChange}
                                                                    label="Setup Style"
                                                                    select={true}
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    required={false}
                                                                >
                                                                    {!!selectedRoom && selectedRoom.roomSetupStyles?.map((style: any) =>
                                                                        <MenuItem key={style.setupStyleId} value={style.setupStyleId}>
                                                                            {style.setupStyle.name}
                                                                        </MenuItem>
                                                                    )}
                                                                </StyledTextField>
                                                            </Grid> */}
                                                            <Grid item={true} xs={12} sm={12}>
                                                                <StyledTextareaAutosize
                                                                    name="eventNotes"
                                                                    autoComplete="off"
                                                                    className={classes.textareaBoxSize}
                                                                    id="eventNotes"
                                                                    onChange={(e) => this.onhandleChange(e, 'eventNotes')}
                                                                    onBlur={() => this.validator.message(
                                                                        'event notes',
                                                                        typeof eventNotes === "string" ? eventNotes.trim() : eventNotes,
                                                                        'noSpecialChar',
                                                                    )}
                                                                    placeholder="Notes"
                                                                    minRows={5}
                                                                    maxRows={5}
                                                                    value={eventNotes}
                                                                />
                                                                {this.validator.message('eventNotes', eventNotes, 'noSpecialChar')}
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Box>
                                            )}
                                            <Grid container={true} spacing={2} direction='row'>
                                                <Grid item={true} xs={12} sm={12}>
                                                    <Typography variant="caption" component='div' gutterBottom={true}>
                                                        {organizationSettings?.ordersTermsMessage}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </>
                                        : <>
                                            {orderType === 'Delivery' && <Typography sx={{ mt: 4, mb: 2, fontSize: { sm: '20px' }, fontWeight: 600 }} gutterBottom={true}>Address</Typography>}
                                            {orderType === 'Delivery' && this.state.selectedAddressId !== "" && this.state.savedAddresses && this.state.savedAddresses?.length > 0 && <Grid container={true} spacing={2} direction='row'>
                                                <AddressSelector
                                                    selectedAddressId={this.state.selectedAddressId}
                                                    addresses={this.state.savedAddresses}
                                                    onAddressSelect={(addressId: any) => {
                                                        if(addressId==="new"){
                                                            this.setState({
                                                                selectedAddressId: '',
                                                                addressName:'',
                                                                address1: '',
                                                                city: '',
                                                                stateProvince: '',
                                                                postalCode: '',
                                                            })
                                                        }
                                                        else{
                                                            const selectedAddress = this.state.savedAddresses?.find((address: any) => { return address?.id === addressId });
                                                            this.setState({
                                                                selectedAddressId: addressId,
                                                                address1: replaceComma(selectedAddress?.address1),
                                                                city: selectedAddress?.city,
                                                                stateProvince: selectedAddress?.stateProvince,
                                                                postalCode: selectedAddress?.postalCode,
                                                            })
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            }
                                            {/* THIS IS JUST TO LOAD THE GOOGLE SERVICES AND IT SHOULD BE VISIBILITY HIDDEN ALWAYS */}
                                            <div style={{visibility: "hidden", height: 0}}>
                                            <AddressAutocomplete
                                                onPlaceSelect={(place: any) => {
                                                }}
                                            />
                                            </div>
                                            {/* ------------------ */}
                                            {orderType === 'Delivery' && ((this.state.savedAddresses && (this.state.savedAddresses?.length === 0)) || this.state.selectedAddressId==="") && <>

                                                <Grid container={true} spacing={2} direction='row'>
                                                    <Grid item={true} xs={12} sm={12}>
                                                        <AddressAutocomplete
                                                            onPlaceSelect={(place: any) => {
                                                                    this.setState({
                                                                        addressName:place?.name,
                                                                        address1: replaceComma(place?.address),
                                                                        city: place?.city,
                                                                        county:place?.county,
                                                                        country: place?.country,
                                                                        postalCode: place?.postalCode,
                                                                        stateProvince: place?.state
                                                                    })
                                                            }}
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={6}>
                                                        <StyledTextField
                                                            name="city"
                                                            autoComplete="address-level2"
                                                            className={classes.marginBottom5}
                                                            required={true}
                                                            id="city"
                                                            value={city}
                                                            onChange={(e) => this.onhandleChange(e, 'city')}
                                                            label="City"
                                                            variant="outlined"
                                                            fullWidth={true}
                                                            type="text"
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={2}>
                                                        <StyledTextField
                                                            name="stateProvince"
                                                            autoComplete="address-level1"
                                                            className={classes.marginBottom5}
                                                            required={true}
                                                            id="stateProvince"
                                                            value={stateProvince}
                                                            onChange={(e) => this.onhandleChange(e, 'stateProvince')}
                                                            label="State"
                                                            variant="outlined"
                                                            fullWidth={true}
                                                            type="text"
                                                            disabled
                                                        />

                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={4}>
                                                        <StyledTextField
                                                            name="postalCode"
                                                            autoComplete="postal-code"
                                                            className={classes.marginBottom5}
                                                            required={true}
                                                            id="postalCode"
                                                            value={postalCode}
                                                            onChange={(e) => this.onhandleChange(e, 'postalCode')}
                                                            placeholder="e.g. 10002"
                                                            label="Postal code"
                                                            variant="outlined"
                                                            fullWidth={true}
                                                            type='text'
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={12}>
                                                        <StyledTextField
                                                            name="addressName"
                                                            autoComplete="addressName"
                                                            className={classes.marginBottom5}
                                                            required={false}
                                                            id="addressName"
                                                            value={addressName}
                                                            onChange={(e) => this.onhandleChange(e, 'addressName')}
                                                            label="Address Name"
                                                            variant="outlined"
                                                            fullWidth={true}
                                                            type="text"
                                                        />
                                                    </Grid>
                                                    {this.props.isLoggedIn &&
                                                    <Grid item xs={12} sm={4}>
                                                        <FormControlLabel label={<>
                                                            <Typography sx={{ 'p':{fontSize: '16px !important'}}}>
                                                                    Save Address
                                                                </Typography>
                                                                </>}
                                                                control={
                                                                    <Checkbox
                                                                        color="secondary"
                                                                        checked={saveAddress}
                                                                        size='small'
                                                                        icon={<CheckBoxOutlineBlank  />}
                                                                        checkedIcon={<CheckBox />}
                                                                        onChange={(e) => this.setState({ saveAddress: e.target.checked })}
                                                                    />}
                                                        />
                                                    </Grid>
                                                    }
                                                </Grid>
                                                {/* <Grid container={true} spacing={2} direction='row'>
                                                    <Grid item={true} xs={12} sm={12}>
                                                        <form autoComplete="off">
                                                            <AddressSearch
                                                                required={true}
                                                                id="address1"
                                                                label="Address"
                                                                className={classes.marginBottom5}
                                                                onBlur={() => this.validator.message(
                                                                    'address',
                                                                    typeof address1 === "string" ? address1.trim() : address1,
                                                                    orderType === 'Delivery' ? 'required|min:5' : null,
                                                                )}
                                                                value={address1}
                                                                variant="outlined"
                                                                onPlaceSelect={this.selectAddress}
                                                                onDone={(e: any) => this.handleValueChange(e, 'address1')}
                                                            />
                                                        </form>
                                                        {orderType === 'Delivery' && this.validator.message('address', address1, 'required|min:5')}
                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={6}>
                                                        <StyledTextField
                                                            name="city"
                                                            autoComplete="address-level2"
                                                            className={classes.marginBottom5}
                                                            required={true}
                                                            id="city"
                                                            value={city}
                                                            onChange={(e) => this.onhandleChange(e, 'city')}
                                                            onBlur={() => this.validator.message('city', typeof city === "string" ? city.trim() : city,
                                                                'required|min:3'
                                                            )}
                                                            label="City"
                                                            fullWidth={true}
                                                            variant="outlined"
                                                            disabled={true}
                                                        />
                                                        {this.validator.message('city', city, 'required|min:3')}
                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={2}>
                                                        <StyledTextField
                                                            name="stateProvince"
                                                            autoComplete="address-level1"
                                                            id="stateProvince"
                                                            required={true}
                                                            value={stateProvince}
                                                            className={classes.marginBottom5}
                                                            onChange={(e) => this.onhandleChange(e, 'stateProvince')}
                                                            onBlur={() => this.validator.message('stateProvince', typeof stateProvince === "string" ? stateProvince.trim() : stateProvince,
                                                                'required|min:2|alpha_space'
                                                            )}
                                                            variant="outlined"
                                                            label="State"
                                                            fullWidth={true}
                                                            disabled={true}
                                                        />

                                                        {this.validator.message('stateProvince', stateProvince, 'required|min:2|alpha_space')}
                                                    </Grid>
                                                    <Grid item={true} xs={12} sm={4}>
                                                        <StyledTextField
                                                            name="postalCode"
                                                            autoComplete="postal-code"
                                                            className={classes.marginBottom5}
                                                            required={true}
                                                            id="postalCode"
                                                            value={postalCode}
                                                            onChange={(e) => this.onhandleChange(e, 'postalCode')}
                                                            onBlur={() => this.validator.message('postal code', typeof postalCode === "number" ? postalCode : postalCode,
                                                                'required|min:3|max:10'
                                                            )}
                                                            placeholder="e.g. 10002"
                                                            label="Postal code"
                                                            variant="outlined"
                                                            fullWidth={true}
                                                            disabled={true}
                                                        />
                                                        {this.validator.message('postal code', postalCode, 'required|min:3|max:10')}
                                                    </Grid>
                                                    {this.renderSaveToProfile()}
                                                </Grid> */}
                                            </>
                                            }
                                            {orderType === "Delivery" && !this.state.savedAddresses && <CircularProgress color='secondary' size={25} />}
                                            <Grid item={true} xs={12} sm={12} md={10} lg={8}>
                                                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                                <Typography gutterBottom={true} sx={{ mt: 4, fontSize: { sm: '20px' }, fontWeight: 600 }}>
                                                    {orderType === 'Pickup' ? 'Pickup Time' : 'Delivery Time'}
                                                </Typography>
                                                <Box  sx={{display:'flex',alignItems:'center',gap:'15px'}}>
                                                    {organizationSettings?.ordersAdvanceNoticeHours > 0 &&
                                                    <Box onClick={()=>{this.setState({ openPolicyModel: true })}} sx={{ mt: 4, fontSize: { sm: '15px' }, fontWeight: 500 ,color:'#0000ef',cursor:'pointer'}}>
                                                        {'Store Policy'}
                                                    </Box>}
                                                    <Box onClick={()=>{this.setState({ openOpenHoursModel: true })}} sx={{ mt: 4, fontSize: { sm: '15px' }, fontWeight: 500 ,color:'#0000ef',cursor:'pointer'}}>
                                                        {'Store Hours'}
                                                    </Box>
                                                    {organizationSettings?.checkoutLinkName && organizationSettings?.checkoutLinkURL && (
                                                        <Link
                                                        href={organizationSettings.checkoutLinkURL.startsWith('http')
                                                            ? organizationSettings.checkoutLinkURL
                                                            : `https://${organizationSettings.checkoutLinkURL}`}
                                                        target="_blank"
                                                        sx={{ mt: 4, fontSize: { sm: '15px' }, fontWeight: 500, color: '#0000ef', cursor: 'pointer' }}
                                                        underline="none" 
                                                        >
                                                        {organizationSettings.checkoutLinkName}
                                                        </Link>)}
                                                </Box>
                                                </Box>
                                            </Grid>
                                            {/* {organizationSettings && <Grid container={true} spacing={2} direction='row'>
                                                <Grid item={true} xs={12} sm={6}>
                                                    <FormControl component="fieldset">
                                                        {organizationSettings?.enableAsap !== null &&
                                                        <RadioGroup
                                                            aria-label="time"
                                                            name="time"
                                                            defaultValue={showAsap ? 'asap' : 'schedule'}
                                                            onChange={this.handleRadioChange}
                                                        >
                                                            {organizationSettings?.enableAsap &&
                                                                    <FormControlLabel
                                                                        value="asap"
                                                                        control={<StyledRadioButton
                                                                            size="small"
                                                                            checked={!this.state.scheduleForLater}
                                                                            disableRipple={true}
                                                                        />}
                                                                        label="ASAP"
                                                                        labelPlacement="end"
                                                                    />
                                                                }
                                                            {!isOrderEmpty && estimatedReadyTimeMax !== undefined && <>
                                                                {!this.state.scheduleForLater && organizationSettings?.enableASAP ?
                                                                    <div>
                                                                        <Typography sx={{pl: '42px', fontSize: {sm: '18px'}}}>
                                                                            {orderType === 'Delivery'
                                                                                ? 'Estimated Delivery Time'
                                                                                : 'Estimated Pickup Time'
                                                                            } {
                                                                                estimatedReadyTimeMax === null
                                                                                    ? 'is expired. Please refresh.'
                                                                                    : `by ${getFormatedNiceDate(estimatedReadyTimeMax)}`
                                                                            }
                                                                        </Typography>
                                                                    </div> : ''}
                                                            </>}
                                                            {!isClosed(organizationSettings, organizationOrdersOpenSchedule) && <FormControlLabel
                                                                value="schedule"
                                                                control={<StyledRadioButton
                                                                    size="small"
                                                                    checked={!organizationSettings?.enableAsap || this.state.scheduleForLater}
                                                                    disableRipple={true}
                                                                    sx={{
                                                                        '&, &.Mui-checked': {
                                                                            color: organizationSettings?.layoutColor,
                                                                        },
                                                                    }}
                                                                />}
                                                                label={<Typography sx={{fontSize: {sm: '20px'}}}>{orderType === 'Delivery'
                                                                    ? 'Estimated Delivery Time'
                                                                    : 'Estimated Pickup Time'}</Typography>}
                                                                labelPlacement="end"
                                                            />}
                                                        </RadioGroup>
                                                        }
                                                    </FormControl>
                                                </Grid>
                                            </Grid>} */}
                                            {!organizationSettings?.enableAsap || this.state.scheduleForLater ?
                                                <Grid container={true} >
                                                    {this.state.calculateEstimatedTimeLoading ? 
                                                    <Grid item xs={12}>
                                                        <Box sx={{pl: 5}}>
                                                            <CircularProgress color='secondary' size={20} />
                                                        </Box>
                                                    </Grid>
                                                    : 
                                                    <Grid item={true} xs={12} sm={6}>
                                                        <div className={classes.selectedDateErrorMessage}>
                                                            {/* {selectedDateError} */}
                                                            {/* {isKitchenClosed() && `Kitchen is closed at ${moment(laterSchedDate).format('h:mm A')}`} */}
                                                        </div>
                                                        <div className={classes.scheduleDetails}>
                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <Box sx={{ display: "flex", gap: 3 }}>
                                                                    <DatePicker
                                                                        label={orderType === 'Pickup' ? 'Pickup Date' : 'Delivery Date'}
                                                                        value={this.state.laterSchedDate}
                                                                        onChange={(newValue) => this.setState({ laterSchedDate: newValue })}
                                                                        disablePast={true}
                                                                        sx={{ minWidth: { xs: "120px", sm: "180px"}, mt: 'auto' }}
                                                                        slots={{
                                                                            textField: TextField,
                                                                        }}
                                                                        shouldDisableDate={(date) => {
                                                                            const formattedDate = moment(date).format('YYYY-MM-DD'); 
                                                                            const dayOfWeek = moment(date).format('dddd').toLowerCase();
                                                                            const daySettings = organizationOrdersOpenSchedule ? organizationOrdersOpenSchedule[dayOfWeek] : null;
                                                                            const isHoliday =  organizationSettings?.ordersHolidays?.some((holiday: any) => 
                                                                                moment(holiday.holidayDate).format('YYYY-MM-DD') === formattedDate && holiday.closedAllDay
                                                                            );
                                                                            const isClosedAllDay = daySettings && daySettings.closedAllDay;
                                                                        
                                                                            return isHoliday || isClosedAllDay;
                                                                          }}
                                                                        minDate={minimumPickupDate}
                                                                        slotProps={{
                                                                            calendarHeader: {
                                                                                sx: {
                                                                                    position: "relative",
                                                                                    "& .MuiPickersArrowSwitcher-root": {
                                                                                        width: 0,
                                                                                    },
                                                                                    "& .MuiPickersCalendarHeader-labelContainer": {
                                                                                        mx: 'auto',
                                                                                    },
                                                                                    "& .MuiIconButton-edgeEnd": {
                                                                                        position: "absolute",
                                                                                        left: 0,
                                                                                        top: 0,
                                                                                        bottom: 0,
                                                                                    },
                                                                                    "& .MuiIconButton-edgeStart": {
                                                                                        position: "absolute",
                                                                                        right: 0,
                                                                                        top: 0,
                                                                                        bottom: 0,
                                                                                    },
                                                                                    "& .MuiPickersCalendarHeader-switchViewIcon": {
                                                                                        display: "none",
                                                                                    },
                                                                                }
                                                                            },
                                                                            openPickerButton: {
                                                                                sx: {
                                                                                    "& .MuiSvgIcon-root": {
                                                                                        height: "26px",
                                                                                        width: "26px",
                                                                                        color: "#000",
                                                                                    },
                                                                                },
                                                                            },
                                                                            textField: {
                                                                                variant: "standard",
                                                                                required: true,
                                                                                id: "date-picker-dialog",
                                                                                name: "date-picker-dialog",
                                                                                fullWidth: true,
                                                                                InputProps: {
                                                                                    sx: {
                                                                                        borderRadius: "8px",
                                                                                        "& .MuiInputBase-input": {
                                                                                            fontSize: "18px",
                                                                                        },
                                                                                    },
                                                                                },
                                                                                InputLabelProps: {
                                                                                    sx: {
                                                                                        "&.MuiInputLabel-root": {
                                                                                            fontSize: "18px"
                                                                                        },
                                                                                    },
                                                                                },
                                                                            }
                                                                        }}
                                                                    />
                                                                    {/* <TimePicker
                                                                        label={orderType === 'Pickup' ? 'Pickup Time' : 'Delivery Time'}
                                                                        value={this.state.laterSchedDate}
                                                                        onChange={(newValue) =>{ 
                                                                            this.setState({ laterSchedDate: newValue })}
                                                                        }
                                                                        sx={{ minWidth: {xs: "50%", sm: "180px" }}}
                                                                        slots={{
                                                                            textField: TextField,
                                                                        }}
                                                                        slotProps={{
                                                                            layout: {
                                                                                sx: {
                                                                                    "& .MuiDialogActions-root": {
                                                                                        display: "none",
                                                                                    },
                                                                                },
                                                                            },
                                                                            openPickerButton: {
                                                                                sx: {
                                                                                    "& .MuiSvgIcon-root": {
                                                                                        height: "26px",
                                                                                        width: "26px",
                                                                                        color: "#000",
                                                                                    },
                                                                                },
                                                                            },
                                                                            textField: {
                                                                                variant: "standard",
                                                                                required: true,
                                                                                id: "delivery-time",
                                                                                name: "delivery-time",
                                                                                fullWidth: true,
                                                                                InputProps: {
                                                                                    sx: {
                                                                                        borderRadius: "8px",
                                                                                        "& .MuiInputBase-input": { 
                                                                                            fontSize: "18px", 
                                                                                        },
                                                                                    },
                                                                                }, 
                                                                                InputLabelProps: {
                                                                                    sx: {
                                                                                        "&.MuiInputLabel-root": {
                                                                                            fontSize: "18px",
                                                                                        },
                                                                                    },
                                                                                },
                                                                            }
                                                                        }}
                                                                    /> */}
                                                                    <TimeSelection
                                                                    label={orderType === 'Pickup' ? 'Pickup Time' : 'Delivery Time'}
                                                                    startTime={openingHours} 
                                                                    endTime={closingHours}     
                                                                    value={pickupTime}
                                                                    handleChange={(value) => {
                                                                        const [time, period] = value.split(' ');
                                                                        const [hours, minutes] = time.split(':');
                                                                        let dateTime = moment(laterSchedDate);
                                                                        const numericHours = parseInt(hours);
                                                                        const isPM = period === 'PM';
                                                                        const isNoon = numericHours === 12;
                                                                        const adjustedHours = isPM ? (isNoon ? 12 : numericHours + 12) : (isNoon ? 0 : numericHours);
                                                                        dateTime.hour(adjustedHours).minute(parseInt(minutes));
                                                                        const backendSeconds = new Date(laterSchedDate).getSeconds();
                                                                        dateTime.second(backendSeconds);
                                                                        const newDate = dateTime.toDate();
                                                                        if (newDate.toISOString() !== laterSchedDate.toISOString()) {
                                                                            this.setState({ laterSchedDate: newDate });
                                                                        }
                                                                    }}
                                                                    />
                                                                </Box>
                                                            </LocalizationProvider >
                                                        </div>
                                                    </Grid>}
                                                </Grid>
                                                : ''}
                                                {organizationSettings?.enableGuestCount &&
                                                <>
                                                <Typography gutterBottom={true} sx={{ mt: 4, mb: 2, fontSize: { sm: '20px' }, fontWeight: 600 }}>Guests</Typography>
                                                <Grid container={true} spacing={2} direction='row'>
                                                <Grid item={true} xs={12} sm={6}>
                                                    <StyledTextField
                                                        name="expectedGuestCount"
                                                        type="number"
                                                        className={classes.marginBottom5}
                                                        id="expectedGuestCount"
                                                        value={expectedGuestCount}
                                                        onChange={(e: any) => this.onhandleChange(e, 'expectedGuestCount')}
                                                        label="Expected Guest Count"
                                                        InputLabelProps={{ shrink: true }}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        inputProps={{ style: { textAlign: 'left' } }}
                                                    />
                                                    {this.validator.message('expected guest count', expectedGuestCount, 'noSpecialChar')}
                                                </Grid>
                                            </Grid>
                                            </>}
                                            {/* {organizationSettings?.enableLocation &&
                                                <><Typography gutterBottom={true} sx={{ mt: 4, mb: 2, fontSize: {sm: '20px'}, fontWeight: 600 }}>Location</Typography><Grid container={true} spacing={2} direction='row'>
                                                    <Grid item={true} xs={12} sm={6}>
                                                        {pickListOperationOptions ? <StyledTextField
                                                            name="operation"
                                                            className={classes.marginBottom5}
                                                            id="operation"
                                                            label="Location"
                                                            value={operation}
                                                            onChange={(e: any) => this.onhandleChange(e, 'operation')}
                                                            fullWidth={true}
                                                            variant="outlined"
                                                            required={false}
                                                            select={true}
                                                        >
                                                            {pickListOperationOptions?.sort((a: any, b: any) => a.sortOrder - b.sortOrder).map((operationOption: any) =>
                                                                <MenuItem key={`operation-${operationOption.id}`} value={operationOption.id} sx={{ fontSize: { xs: '18px' } }}>
                                                                    {operationOption.name}
                                                                </MenuItem>
                                                            )}
                                                        </StyledTextField> :
                                                            <Box>
                                                                <CircularProgress color='secondary' size={20} />
                                                            </Box>}
                                                    </Grid>
                                                </Grid></>} */}
                                            <Typography gutterBottom={true} sx={{ mt: 4, mb: 2, fontSize: { sm: '20px' }, fontWeight: 600 }}>Contact</Typography>
                                            <Grid container={true} spacing={2} direction='row'>
                                                <Grid item={true} xs={12} sm={6}>
                                                    <StyledTextField
                                                        autoComplete="given-name"
                                                        name="firstName"
                                                        className={classes.marginBottom5}
                                                        required={true}
                                                        id="firstName"
                                                        label="First name"
                                                        value={firstName}
                                                        onChange={(e: any) => this.onhandleChange(e, 'firstName')}
                                                        onBlur={() => this.validator.message('first name', typeof firstName === "string" ? firstName.trim() : firstName,
                                                            'required|noSpecialChar'
                                                        )}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                    />
                                                    {this.validator.message('first name', firstName, 'required|noSpecialChar')}
                                                </Grid>
                                                <Grid item={true} xs={12} sm={6}>
                                                    <StyledTextField
                                                        name="lastName"
                                                        autoComplete="family-name"
                                                        className={classes.marginBottom5}
                                                        required={true}
                                                        id="lastName"
                                                        value={lastName}
                                                        onChange={(e: any) => this.onhandleChange(e, 'lastName')}
                                                        onBlur={() => this.validator.message('last name', typeof lastName === "string" ? lastName.trim() : lastName,
                                                            'required|noSpecialChar'
                                                        )}
                                                        label="Last name"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                    />
                                                    {this.validator.message('last name', lastName, 'required|noSpecialChar')}
                                                </Grid>
                                                <Grid item={true} xs={12} sm={6}>
                                                    <StyledTextField
                                                        name="email"
                                                        autoComplete="email"
                                                        className={classes.marginBottom5}
                                                        required={true}
                                                        id="email"
                                                        value={email}
                                                        onChange={(e: any) => this.onhandleChange(e, 'email')}
                                                        onBlur={() => this.validator.showMessageFor('email')}
                                                        label="Email"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                    />
                                                    {this.validator.message('email', email, 'required|email')}
                                                </Grid>
                                                <Grid item={true} xs={12} sm={6}>
                                                    <StyledTextField
                                                        name="phoneNumber"
                                                        autoComplete="tel"
                                                        className={classes.marginBottom5}
                                                        required={true}
                                                        id="phoneNumber"
                                                        value={phoneNumber}
                                                        placeholder="(xxx) xxx-xxxx"
                                                        onChange={this.handlePhone}
                                                        onBlur={() => this.validator.message(
                                                            'phone number',
                                                            typeof phoneNumber === "string" ? phoneNumber.trim() : phoneNumber,
                                                            'required|phone'
                                                        )}
                                                        label="Phone Number"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                    />
                                                    {this.validator.message('phone number', phoneNumber, 'required|phone')}
                                                </Grid>
                                                <Grid item={true} xs={12} sm={12}>
                                                    <Typography variant="caption" component='div' gutterBottom={true} mt={2}>
                                                        {organizationSettings?.ordersTermsMessage}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </>}
                                    </Box>
                                </Container>
                                {orderType === 'Delivery' && <>
                                    <Container className={classes.deliveryNote} component="main" disableGutters={false}>
                                        <Box>
                                            {deliveryAdditionalDetails && ( 
                                        <Box>
                                                <Accordion
                                                    sx={{ boxShadow: 'none' }}
                                                    className={classes.expansionPanel}
                                                    expanded={detailsExpended} 
                                                    onChange={() => {
                                                        this.setState({ detailsExpended: !detailsExpended });
                                                    }}>
                                                    <AccordionSummary
                                                        sx={{ p: 0 }}
                                                        className={classes.expansionPanelSummary}
                                                        expandIcon={
                                                            <ExpandMoreOutlined sx={{
                                                                marginLeft: '5px', 
                                                                width: '20px',
                                                                height: '20px',
                                                                color: organizationSettings?.layoutColor,
                                                                marginBottom: '5px',
                                                            }} />
                                                        }>
                                                        <Typography
                                                            className={classes.expansionPanelSummarySubtitle}
                                                            gutterBottom={true}
                                                            sx={{ fontSize: { sm: '20px' }, color: organizationSettings?.layoutColor }}
                                                        >
                                                            Additional Details
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails className={classes.expansionPanelContent}>
                                                        <Grid container={true} spacing={2} direction='row'>
                                                        {customNames.map((name: string, index: number) => {
    if (!name) return null;
    const matchingDelivery = deliveryOverrides.find((event:any) => event.customName === name);

    // Extract pickListOptions if available
    
    const pickListId = matchingDelivery?.pickListId;
    const options = matchingDelivery?.pickList?.pickListOptions || [];

    return (
      <Grid item xs={12} sm={6} key={index}>
        <StyledTextField
          name={name}
          id={`theme-${index}`}
          label={name}
          value={this.state.selectedNames[index] || ''}
          onChange={(e: any) => {
            const newSelectedOptions = [...this.state.selectedNames];
            newSelectedOptions[index] = e.target.value;
            this.setState({ selectedNames: newSelectedOptions });

            const selectedOptionId = e.target.value;
            if (selectedOptionId && pickListId) {
              const orderPickListOptions = this.state.orderPickListOptions || [];
              
              const existingIndex = orderPickListOptions.findIndex(
                (item: any) => item.pickListId === pickListId
              );
              
              if (existingIndex >= 0) {
                const updatedOptions = [...orderPickListOptions];
                updatedOptions[existingIndex] = {
                  pickListId: pickListId,
                  pickListOptionId: selectedOptionId
                };
                this.setState({ orderPickListOptions: updatedOptions });
              } else {
                // Add new entry
                this.setState({
                  orderPickListOptions: [
                    ...orderPickListOptions,
                    {
                      pickListId: pickListId,
                      pickListOptionId: selectedOptionId
                    }
                  ]
                });
              }
            }

            this.onhandleChange(e, name);
          }}
          fullWidth
          variant="outlined"
          required={false}
          select
        >
                         {options.length > 0 ? (
              options.map((option: any) => (
                <MenuItem key={`menu-${option.id}`} value={option.id} sx={{ fontSize: { xs: "18px" } }}>
                  {option.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No options available</MenuItem>
            )}
        </StyledTextField>
      </Grid>
    );
  })}
                                                            
                                                            {/* <Grid item={true} xs={12} sm={6}>
                                                                <StyledTextField
                                                                    name="banquetRoom"
                                                                    select={true}
                                                                    label="Banquet Room"
                                                                    className={classes.marginBottom5}
                                                                    id="banquetRoom"
                                                                    value={!!selectedRoom && selectedRoom.id}
                                                                    onChange={this.handleRoomChange}
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                >
                                                                    <MenuItem key={0} value={0}>
                                                                        None
                                                                    </MenuItem>
                                                                    {rooms?.map((room: any) =>
                                                                        <MenuItem key={room.id} value={room.id}>
                                                                            {room.name} (${formatMoney(room.rate)})
                                                                        </MenuItem>
                                                                    )}
                                                                </StyledTextField>
                                                            </Grid> */}
                                                            {/* <Grid item={true} xs={12} sm={6}>
                                                                <StyledTextField
                                                                    name="setupStyle"
                                                                    className={classes.marginBottom5}
                                                                    id="setupStyle"
                                                                    value={!!selectedSetupStyle && selectedSetupStyle.id}
                                                                    onChange={this.handleSetupStyleChange}
                                                                    label="Setup Style"
                                                                    select={true}
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    required={false}
                                                                >
                                                                    {!!selectedRoom && selectedRoom.roomSetupStyles?.map((style: any) =>
                                                                        <MenuItem key={style.setupStyleId} value={style.setupStyleId}>
                                                                            {style.setupStyle.name}
                                                                        </MenuItem>
                                                                    )}
                                                                </StyledTextField>
                                                            </Grid> */}
                                                           
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Box>
                                            )}
                                            <Typography sx={{ mt: 4, mb: 2, fontSize: { sm: '20px' }, fontWeight: 600}} gutterBottom={true}>Delivery Note</Typography>
                                            <Grid container={true} spacing={2} direction='row'>
                                                <Grid item={true} xs={12} sm={12}>
                                                    <StyledTextareaAutosize
                                                        name="notes"
                                                        autoComplete="off"
                                                        className={classes.textareaBoxSize}
                                                        id="notes"
                                                        onChange={(e) => this.onhandleChange(e, 'notes')}
                                                        onBlur={() => this.validator.message(
                                                            'notes',
                                                            typeof notes === "string" ? notes.trim() : notes,
                                                            'noSpecialChar',
                                                        )}
                                                        maxRows={5}
                                                        minRows={5}
                                                        placeholder="e.g. Please double bag. (optional)"
                                                        value={notes}
                                                    />
                                                    {this.validator.message('notes', notes, 'noSpecialChar')}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Container>
                                </>}
                                {orderType === 'Pickup' && <>
                                    <Container className={classes.deliveryNote} component="main" disableGutters={false}>
                                        <Box>
                                        {pickupAdditionalDetails && (
                                            <Box>
                                                <Accordion
                                                    sx={{ boxShadow: 'none' }}
                                                    className={classes.expansionPanel}
                                                    expanded={detailsExpended} 
                                                    onChange={() => {
                                                        this.setState({
                                                            detailsExpended: !this.state.detailsExpended,
                                                        });
                                                    }}>
                                                    <AccordionSummary
                                                        sx={{ p: 0 }}
                                                        className={classes.expansionPanelSummary}
                                                        expandIcon={
                                                            <ExpandMoreOutlined sx={{
                                                                marginLeft: '5px', 
                                                                width: '20px',
                                                                height: '20px',
                                                                color: organizationSettings?.layoutColor,
                                                                marginBottom: '5px',
                                                            }} />
                                                        }>
                                                        <Typography
                                                            className={classes.expansionPanelSummarySubtitle}
                                                            gutterBottom={true}
                                                            sx={{ fontSize: { sm: '20px' }, color: organizationSettings?.layoutColor }}
                                                        >
                                                            Additional Details
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails className={classes.expansionPanelContent}>
                                                        <Grid container={true} spacing={2} direction='row'>
                                                            {customName.map((name: string, index: number) => {
    if (!name) return null;
    const matchingDelivery = pickupOverride.find((event:any) => event.customName === name);

    // Extract pickListOptions if available
    const pickListId = matchingDelivery?.pickListId;
    const options = matchingDelivery?.pickList?.pickListOptions || [];

    return (
      <Grid item xs={12} sm={6} key={index}>
        <StyledTextField
          name={name}
          id={`theme-${index}`}
          label={name}
          value={this.state.selectedNames[index] || ''}
          onChange={(e: any) => {
            const newSelectedOptions = [...this.state.selectedNames];
            newSelectedOptions[index] = e.target.value;
            this.setState({ selectedNames: newSelectedOptions });

            const selectedOptionId = e.target.value;
            if (selectedOptionId && pickListId) {
              const orderPickListOptions = this.state.orderPickListOptions || [];
              
              const existingIndex = orderPickListOptions.findIndex(
                (item: any) => item.pickListId === pickListId
              );
              
              if (existingIndex >= 0) {
                const updatedOptions = [...orderPickListOptions];
                updatedOptions[existingIndex] = {
                  pickListId: pickListId,
                  pickListOptionId: selectedOptionId
                };
                this.setState({ orderPickListOptions: updatedOptions });
              } else {
                // Add new entry
                this.setState({
                  orderPickListOptions: [
                    ...orderPickListOptions,
                    {
                      pickListId: pickListId,
                      pickListOptionId: selectedOptionId
                    }
                  ]
                });
              }
            }

            this.onhandleChange(e, name);
          }}
          fullWidth
          variant="outlined"
          required={false}
          select
        >
                         {options.length > 0 ? (
              options.map((option: any) => (
                <MenuItem key={`menu-${option.id}`} value={option.id} sx={{ fontSize: { xs: "18px" } }}>
                  {option.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No options available</MenuItem>
            )}
        </StyledTextField>
      </Grid>
    );
  })}
                                                            {/* <Grid item={true} xs={12} sm={6}>
                                                                {pickListCategoryOptions ? <StyledTextField
                                                                    name="category"
                                                                    className={classes.marginBottom5}
                                                                    id="category"
                                                                    value={category}
                                                                    onChange={(e: any) => this.onhandleChange(e, 'category')}
                                                                    label="Category"
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    required={false}
                                                                    select={true}
                                                                >
                                                                    {pickListCategoryOptions?.map((categoryOption: any) =>
                                                                        <MenuItem key={`category-${categoryOption.id}`} value={categoryOption.id} sx={{ fontSize: { xs: '18px' } }}>
                                                                            {categoryOption.name}
                                                                        </MenuItem>
                                                                    )}
                                                                </StyledTextField>:
                                                                <Box>
                                                                    <CircularProgress color='secondary' size={20} />
                                                                </Box>}
                                                            </Grid> */}
                                                            {/* <Grid item={true} xs={12} sm={6}>
                                                                <StyledTextField
                                                                    name="banquetRoom"
                                                                    select={true}
                                                                    label="Banquet Room"
                                                                    className={classes.marginBottom5}
                                                                    id="banquetRoom"
                                                                    value={!!selectedRoom && selectedRoom.id}
                                                                    onChange={this.handleRoomChange}
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                >
                                                                    <MenuItem key={0} value={0}>
                                                                        None
                                                                    </MenuItem>
                                                                    {rooms?.map((room: any) =>
                                                                        <MenuItem key={room.id} value={room.id}>
                                                                            {room.name} (${formatMoney(room.rate)})
                                                                        </MenuItem>
                                                                    )}
                                                                </StyledTextField>
                                                            </Grid> */}
                                                            {/* <Grid item={true} xs={12} sm={6}>
                                                                <StyledTextField
                                                                    name="setupStyle"
                                                                    className={classes.marginBottom5}
                                                                    id="setupStyle"
                                                                    value={!!selectedSetupStyle && selectedSetupStyle.id}
                                                                    onChange={this.handleSetupStyleChange}
                                                                    label="Setup Style"
                                                                    select={true}
                                                                    fullWidth={true}
                                                                    variant="outlined"
                                                                    required={false}
                                                                >
                                                                    {!!selectedRoom && selectedRoom.roomSetupStyles?.map((style: any) =>
                                                                        <MenuItem key={style.setupStyleId} value={style.setupStyleId}>
                                                                            {style.setupStyle.name}
                                                                        </MenuItem>
                                                                    )}
                                                                </StyledTextField>
                                                            </Grid> */}
                                                           
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Box>
                                        )}
                                        <Typography sx={{ mt: 4, mb: 2, fontSize: { sm: '20px' }, fontWeight: 600}} gutterBottom={true}>Pickup Note</Typography>
                                        <Grid container={true} spacing={2} direction='row'>
                                            <Grid item={true} xs={12} sm={12}>
                                                <StyledTextareaAutosize
                                                    name="notes"
                                                    autoComplete="off"
                                                    className={classes.textareaBoxSize}
                                                    id="notes"
                                                    onChange={(e) => this.onhandleChange(e, 'notes')}
                                                    onBlur={() => this.validator.message(
                                                        'notes',
                                                        typeof notes === "string" ? notes.trim() : notes,
                                                        'noSpecialChar',
                                                    )}
                                                    maxRows={5}
                                                    minRows={5}
                                                    placeholder="e.g. Please double bag. (optional)"
                                                    value={notes || ''}
                                                    
                                                />
                                                {this.validator.message('notes', notes, 'noSpecialChar')}
                                            </Grid>
                                        </Grid>
                                        </Box>
                                    </Container>
                                </>}
                            </Grid>
                            <Hidden lgUp={true}>
                                {/* <Grid container={true}>
                                    {organizationSettings?.enableCoupons &&
                                        <Grid item={true} xs={12} sm={12}>
                                            <div className={classes.couponErrorMobile} style={couponError ? {} : { visibility: 'hidden' }}>
                                                {couponErrorMessage}
                                            </div>
                                            <div className={classes.couponContainerMobile}>
                                                <StyledTextField
                                                    name="coupon"
                                                    className={classes.couponField}
                                                    InputProps={{ className: classes.couponFieldInput }}
                                                    required={true}
                                                    id="coupon"
                                                    value={couponCode}
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    onChange={event => this.setState({
                                                        couponCode: (event.target.value.length > 20 ? couponCode : event.target.value),
                                                        couponError: false,
                                                    })}
                                                />
                                                <Button
                                                    className={classes.couponButton}
                                                    variant="contained"
                                                    color="secondary"
                                                    disabled={!removeCouponState && (applyCouponDisabled || !couponCode)}
                                                    onClick={() => this.couponButtonClick(removeCouponState)}
                                                >
                                                    {removeCouponState ? 'Remove' : 'Apply'}
                                                </Button>
                                            </div>
                                        </Grid>}
                                </Grid> */}
                                <Grid item={true} xs={12} sm={12}>
                                    <Grid container={true} mt={1}>
                                        <Grid item={true} xs={6} sm={6}>
                                            <Typography sx={{ fontSize: { xs: '18px' }}}>Items Subtotal:</Typography>
                                        </Grid>
                                        <Grid item={true} xs={6} sm={6}>
                                            <Typography sx={{ fontSize: { xs: '18px' }, textAlign: 'right' }}>{calculatePaymentLoading ? <CircularProgress color='secondary' size={20} /> : "$" + formatMoney(subtotal)}</Typography>
                                        </Grid>
                                        <Grid item={true} xs={6} sm={6}>
                                            <Typography sx={{ fontSize: { xs: '18px' } }}>Service Charge:</Typography>
                                        </Grid>
                                        <Grid item={true} xs={6} sm={6}>
                                            <Typography sx={{ fontSize: { xs: '18px' }, textAlign: 'right' }}>{calculatePaymentLoading ? <CircularProgress color='secondary' size={20} /> : "$" + formatMoney(this.state.serviceChargeTotal)}</Typography>
                                        </Grid>
                                        {this.state.gratuityTotal !== 0 && <><Grid item={true} xs={6} sm={6}>
                                            <Typography sx={{ fontSize: { xs: '18px' } }}>Gratuity:</Typography>
                                        </Grid>
                                        <Grid item={true} xs={6} sm={6}>
                                            <Typography sx={{ fontSize: { xs: '18px' }, textAlign: 'right' }}>{calculatePaymentLoading ? <CircularProgress color='secondary' size={20} /> : "$" + formatMoney(this.state.gratuityTotal)}</Typography>
                                        </Grid></>}
                                        {orderType === "Delivery" && <><Grid item={true} xs={6} sm={6}>
                                            <Typography sx={{ fontSize: { xs: '18px' } }}>Delivery Fee:</Typography>
                                        </Grid>
                                            <Grid item={true} xs={6} sm={6}>
                                                <Typography sx={{ fontSize: { xs: '18px' }, textAlign: 'right' }}>{calculatePaymentLoading ? <CircularProgress color='secondary' size={20} /> : "$" + formatMoney(this.state.deliveryFee || 0)}</Typography>
                                            </Grid></>}
                                        {/* {!!order.couponDiscount && <>
                                            <Grid item={true} xs={6} sm={6}>
                                                <Typography sx={{ fontSize: { sm: '16px' } }} className={classes.marginBottom5}>Coupon Discount:</Typography>
                                            </Grid>
                                            <Grid item={true} xs={6} sm={6} >
                                                <Typography sx={{ fontSize: { sm: '16px', textAlign: 'right'} }} className={classes.productTotalPrice}>${formatMoney(order.couponDiscount)}</Typography>
                                            </Grid>
                                        </>} */}
                                        <Grid item={true} xs={6} sm={6}>
                                            <Typography sx={{ fontSize: { sm: '18px', fontWeight: 600 } }}>TOTAL:</Typography>
                                        </Grid>
                                        <Grid item={true} xs={6} sm={6}>
                                            <Typography sx={{ fontSize: { sm: '18px', fontWeight: 600, textAlign: 'right' } }}>{calculatePaymentLoading ? <CircularProgress color='secondary' size={20} /> : "$" + formatMoney(total)}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Box mt={3} sx={{
                                    display: 'flex',
                                    width: '100%',
                                    flexDirection: 'column'
                                }}>
                                    <>
                                        {!organizationSettings?.useSkipify ? 
                                            <>
                                                {organizationSettings?.enablePayNow &&
                                                    <Grid item={true} xs={12} sm={12}>
                                                        <div style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', margin: '8px' }}>
                                                            <StyledPayButton
                                                                variant="contained"
                                                                color="layoutColor"
                                                                disableElevation
                                                                disabled={isKitchenClosed() || isOrderEmpty || this.state.creatingOrder || this.payDisabled() || orderMinimumNotMet || loadPaymentUrl}
                                                                sx={{ backgroundColor: organizationSettings?.layoutColor, '&.MuiButton-root:hover': { backgroundColor: organizationSettings?.layoutColor }  }}
                                                                onClick={this.payNow}
                                                            >
                                                                <Typography sx={
                                                                    {
                                                                        fontSize: { xs: '16px' },
                                                                        fontWeight: 600,
                                                                        // color: '#000000'
                                                                    }}>
                                                                    Pay Now ${formatMoney(this.state.total)}
                                                                </Typography>
                                                            </StyledPayButton>
                                                        </div>
                                                    </Grid>
                                                }
                                            </>
                                            : 
                                            <>
                                               {this.state.skipifyPayButtonLoading ? 
                                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                    <CircularProgress size={20} color='secondary' /> 
                                                    <p style={{fontSize: 16, paddingLeft: '8px'}}>Loading Skipify</p>
                                                </div>: 
                                                    <>
                                                        {(isKitchenClosed() || isOrderEmpty || this.state.creatingOrderWithSkipify || this.payDisabled() || orderMinimumNotMet || loadPaymentUrl) ? 
                                                            <Grid item={true} xs={12} sm={12}>
                                                                <div style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', margin: '8px' }}>
                                                                    <StyledPayButton
                                                                        variant="contained"
                                                                        color="layoutColor"
                                                                        disableElevation
                                                                        disabled
                                                                        sx={{ backgroundColor: organizationSettings?.layoutColor, '&.MuiButton-root:hover': { backgroundColor: organizationSettings?.layoutColor } }}
                                                                    >
                                                                        {this.state.creatingOrderWithSkipify && <CircularProgress size={24} color="info" />}
                                                                        <Typography sx={
                                                                            {
                                                                                fontSize: { xs: '16px' },
                                                                                fontWeight: 600,
                                                                            }}>
                                                                            Pay Now
                                                                        </Typography>
                                                                    </StyledPayButton>
                                                                </div>
                                                            </Grid>
                                                            : 
                                                            <div style={{ padding: '8px' }}>
                                                                <SkipifyIntegration 
                                                                    cartRefId={this.state.skipifyGuid} 
                                                                    merchantId={this.props.organizationSettings.skipifyMerchantID}
                                                                    onSuccess={(skipifyResponse: any)=> this.setState({skipifyResponse: skipifyResponse}, () => this.skipifySuccess())} 
                                                                    buttonColor={organizationSettings?.layoutColor}
                                                                    email={this.state.email}
                                                                    phone={this.state.phoneNumber}
                                                                    onClose={() => { this.setState({skipifyClose: true}) }}
                                                                    onClick={(value: boolean) => { this.setState({skipifyPopupOpen: value}) }}
                                                                />
                                                            </div>
                                                        }
                                                    </>                                              
                                                }
                                            </>
                                        }
                                    </>
                                    {organizationSettings?.enablePayLater &&
                                        <Grid item={true} xs={12} sm={12}>
                                            <div style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', margin: '8px' }}>
                                                <StyledPayButton
                                                    variant="contained"
                                                    color="layoutColor"
                                                    disableElevation
                                                    // disabled={orderDisabled}
                                                    disabled={isKitchenClosed() || isOrderEmpty || this.state.creatingOrder || this.payDisabled() || orderMinimumNotMet || loadPaymentUrl}
                                                    sx={{ backgroundColor: organizationSettings?.layoutColor, '&.MuiButton-root:hover': { backgroundColor: organizationSettings?.layoutColor } }}
                                                    // onClick={this.payAtStore}
                                                    onClick={this.payLater}
                                                >
                                                    {this.state.creatingOrder && <CircularProgress size={24} color="info" />}
                                                    <Typography sx={
                                                        {
                                                            fontSize: { xs: '16px' },
                                                            fontWeight: 600,
                                                            // color: '#000000'
                                                        }}>
                                                        Pay Later
                                                    </Typography>
                                                </StyledPayButton>
                                            </div>
                                        </Grid>
                                    }
                                </Box>
                            </Hidden>
                            {this.renderOnlineOrderClosedMessage(onlineOrderEnabled, onlineOrderClosedMessage)}
                            {this.renderUndeliverableZoneMessage(undeliverableZone, undeliverableZoneMessage)}
                            {this.renderThrottlingOverloadMessage(throttlingBuffer === -1, throttlingOverloadMessage)}
                        </Grid>
                        <Hidden only={['xs', 'sm', 'md']}>
                            <Grid item={true} xs={12} sm={4}>
                                <Container sx={{
                                    borderRadius: '30% 0% 0% 30%/70% 0% 0% 70%',
                                    padding: '30px 50px',
                                    maxWidth: 460,
                                    width: 460,
                                    position: 'absolute' as 'absolute',
                                    right: 0,
                                    top: 0,
                                    left: 'auto',
                                    zIndex: 9999,
                                    minHeight: '100%', }} 
                                    component="main" disableGutters={true} maxWidth="md" style={{ flexDirection: 'column', backgroundColor: organizationSettings?.layoutColor && lighten(organizationSettings?.layoutColor, 0.5) }}>
                                    <Grid container={true} sx={{ marginLeft: '20px', paddingLeft: '20px' }}>
                                            <Grid item={true} xs={12} sm={12}>
                                                <div className={classes.cartScrollablePanel}>
                                                {!this.props.isLoggedIn && <LoginUser {...this.props} />}
                                                    <Box
                                                        aria-label="show shopping cart"
                                                        color="inherit"
                                                        id="openModel"
                                                        sx={{
                                                            margin: 0,
                                                            padding: '8px 15px',
                                                            border: '1px solid',
                                                            borderColor: organizationSettings?.layoutColor,
                                                            borderRadius: '100px',
                                                            fontSize: '14px',
                                                            display: 'flex',
                                                            backgroundColor: '#fff',
                                                            alignItems: 'center', 
                                                            justifyContent: 'space-between',
                                                    }}
                                                    >
                                                        <Stack direction="row" alignItems="center">
                                                        <img src='/static/images/shopping-bag.svg' alt='' color="secondary" style={{ marginRight: '5px' }} />
                                                            <Typography sx={{ fontSize: { sm: '14px' } }}>My order</Typography>
                                                        </Stack>
                                                    </Box>
                                                {!isOrderEmpty && <Box sx={{
                                                        marginTop: '20px',
                                                        marginBottom: '10px',
                                                        fontSize: '18px',
                                                        fontWeight: 600}}>
                                                        <Typography sx={{
                                                            fontSize: {sm:'16px'},
                                                            fontWeight: 600,
                                                            marginBottom: '5px'
                                                            }}>My orders</Typography>
                                                    </Box>}
                                                    {/* {organizationSettings?.enableCoupons && <div>
                                                        <Typography sx={{
                                                            fontSize: { sm: '16px' },
                                                            fontWeight: 600,
                                                            marginBottom: '5px',
                                                        }}>Coupon Code</Typography>
                                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }}>
                                                            <TextField
                                                                name="coupon"
                                                                // className={classes.couponField}
                                                                InputProps={{ className: classes.couponFieldInput }}
                                                                required={true}
                                                                id="coupon"
                                                                value={couponCode}
                                                                fullWidth={true}
                                                                variant="outlined"
                                                                onChange={event => this.setState({
                                                                    couponCode: (event.target.value.length > 20 ? couponCode : event.target.value),
                                                                    couponError: false,
                                                                })}
                                                            />
                                                            <Button
                                                                className={classes.couponButton}
                                                                variant="contained"
                                                                color="secondary"
                                                                disabled={!removeCouponState && (applyCouponDisabled || !couponCode)}
                                                                onClick={() => this.couponButtonClick(removeCouponState)}
                                                            >
                                                                {removeCouponState ? 'Remove' : 'Apply'}
                                                            </Button>
                                                        </Box>
                                                        <div className={classes.couponError} style={couponError ? {} : { visibility: 'hidden' }}>
                                                            {couponErrorMessage}
                                                        </div>
                                                    </div>} */}
                                                    <Grid
                                                        container={true}
                                                        className={classNames(
                                                            classes.productDetailsItem,
                                                            isOrderEmpty ? classes.cartEmpty : ''
                                                        )}
                                                    >
                                                        {!isOrderEmpty ?
                                                            order.orderItems?.map((orderItem: IOrderItem, index:number) => {
                                                                const { name, price, id, quantity, orderItemModifiers } = orderItem;
                                                                // const modifiers = orderItemModifiers
                                                                //     ?.filter(orderItemModifier => orderItemModifier.recordStatus === 'Active')
                                                                //     ?.map(orderItemModifier => orderItemModifier?.menuItemModifier?.name || '');
                                                                // const modifiers = getCheckedOptions(orderItemModifiers)?.map((orderItemModifier: any) => orderItemModifier?.name || '');

                                                                let modifiers = getCheckedOptions(orderItemModifiers)?.map((orderItemModifier: any) => {
                                                                    // Find the selected sub-option based on selectedSubOption ID
                                                                    const selectedSubOption = orderItemModifier?.subOptions?.find(
                                                                        (sub: any) => sub.id === orderItemModifier.selectedSubOption
                                                                    );
                                                                
                                                                    // Generate the sub-options text only if a selected sub-option exists
                                                                    const subOptionsText = selectedSubOption 
                                                                        ? ` (${selectedSubOption.name})` 
                                                                        : '';
                                                                
                                                                    // Return the modifier name concatenated with the selected sub-option name
                                                                    return `${orderItemModifier?.name || ''}${subOptionsText}`;
                                                                });

                                                                if (modifiers.length === 0 && orderItemModifiers) {
                                                                    modifiers = orderItemModifiers.flatMap((orderItemModifier: any) => {
                                                                        return orderItemModifier.options
                                                                            .filter((option: any) => option.checked !== false) // Process only if checked is not false or if checked is not present
                                                                            .map((option: any) => {
                                                                                const subOptionsText = option.subOptions.length > 0 
                                                                                    ? ` (${option.subOptions.map((sub: any) => sub.name).join(', ')})`
                                                                                    : '';
                                                                                return `${option.name}${subOptionsText}`;
                                                                            });
                                                                    });
                                                                }
                                                                return (
                                                                    <Grid item={true} xs={12} sm={12} key={id+"key"+index} sx={{
                                                                        width: '100%',
                                                                        margin: '4px 0px',
                                                                        justifyContent: 'space-between',
                                                                        alignItems: 'center',
                                                                        backgroundColor: 'white',
                                                                        padding: '5px 15px',
                                                                        borderRadius: '12px',
                                                                        }}>
                                                                        <Grid container={true}>
                                                                            <Button sx={{
                                                                                color: "#000000",
                                                                                textAlign: "left",
                                                                                display: "block",
                                                                                px: 0,
                                                                            }}
                                                                                onClick={() => this.backToProductEdit(orderItem)}>
                                                                                <Typography sx={{
                                                                                    textOverflow: "ellipsis",
                                                                                    overflow: "hidden",
                                                                                    whiteSpace: "nowrap",
                                                                                    fontSize: {xs: '16px'},
                                                                                    fontWeight: 600,
                                                                                }}
                                                                                >
                                                                                    {name}
                                                                                </Typography>
                                                                                <Box sx={{ textAlign: "left" }}>
                                                                                    <Typography sx={{ fontSize: { xs: '12px' }, textTransform: "none", fontWeight: 400 }}>{modifiers.join(', ')}</Typography>
                                                                                </Box>
                                                                                <Box sx={{ textAlign: "left" }}>
                                                                                    <Typography sx={{ fontSize: { xs: '12px' }, textTransform: 'none', fontWeight: 400, color: "#000000" }}>{orderItem.notes}</Typography>
                                                                                </Box>
                                                                            </Button>
                                                                        </Grid>
                                                                        <Grid container={true} sx={{ mt: '-12px', ml: '-8px' }}>
                                                                            <Grid item={true} xs={12} sm={12}>
                                                                                <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                                                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', pr: '20px' }}>
                                                                                        <div>
                                                                                            <ItemQtyDropdown
                                                                                                className={classes?.qtyDropdown}
                                                                                                dropdownOptions={classes?.dropdownOptions}
                                                                                                orderItem={orderItem}
                                                                                                onUpdateItemQuantity={this.onUpdateItemQuantity}
                                                                                            />
                                                                                            <div className={classNames(classes.errorMessage, { [classes.qtyErrorMessageActive]: qtyError[id] })}>
                                                                                                <span style={{ display: qtyError[id] ? 'initial' : 'none' }}>&nbsp;{qtyError[id]}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <ClearOutlined sx={{
                                                                                            fontSize: { sm: '12px' },
                                                                                            marginBottom: '-1px', mr: '10px' }} />
                                                                                        <Typography sx={{ fontSize: { sm: '15px' }}}>${formatMoney(price + getReorderModifiersPrice(orderItemModifiers))}</Typography>
                                                                                    </Box>
                                                                                    <Button sx={{justifyContent:'right'}}>
                                                                                        <DeleteOutlined sx={{ fontSize: { sm: '15px' }, color: '#000000', pr: '8px', width: 'auto' }} onClick={() => this.orderRemoveItem(id)} />
                                                                                    </Button>
                                                                                    <Box sx={{textAlign: 'right', mr: '-10px'}}>
                                                                                        <Typography sx={{fontSize: { sm: '15px' }, color: organizationSettings?.layoutColor, fontWeight: 500}}>$
                                                                                        {formatMoney((price + getReorderModifiersPrice(orderItemModifiers)) * quantity)}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Stack>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                )
                                                            }) : <></>}

                                                    </Grid>
                                                </div>
                                                {!isOrderEmpty ? (
                                                    <Grid item={true} xs={12} sm={12}>
                                                    <Box sx={{
                                                        marginTop: '20px',
                                                        marginBottom: '10px',
                                                        fontSize: '18px',
                                                        fontWeight: 600
                                                    }}>
                                                        <Typography sx={{
                                                            fontSize: { sm: '16px' },
                                                            fontWeight: 600,
                                                            marginBottom: '5px'
                                                        }}>Payment</Typography>
                                                    </Box>
                                                    <Grid container={true} sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        backgroundColor: 'white',
                                                        padding: '10px 15px',
                                                        borderRadius: '12px',
                                                        mb: '20px'}}>
                                                            {!!order.orderEventDetails && !!order.orderEventDetails.banquetRoomRate && <>
                                                                <Grid item={true} xs={12} sm={6}>
                                                                <Typography sx={{ fontSize: { sm: '16px' } }} className={classes.marginBottom5}>Room Rate:</Typography>
                                                                </Grid>
                                                                <Grid item={true} xs={12} sm={2} md={6}>
                                                                <Typography sx={{ fontSize: { sm: '16px', textAlign: 'right' }, fontWeight: 500, color: organizationSettings?.layoutColor }}>${formatMoney(order.orderEventDetails.banquetRoomRate)}</Typography>
                                                                </Grid>
                                                            </>}
                                                            <Grid item={true} xs={12} sm={6}>
                                                            <Typography sx={{ fontSize: { sm: '16px' } }} className={classes.marginBottom5}>Items subtotal:</Typography>
                                                            </Grid>
                                                            <Grid item={true} xs={12} sm={2} md={6}>
                                                            <Typography sx={{ fontSize: { sm: '16px', textAlign: 'right' }, fontWeight: 500, color: organizationSettings?.layoutColor }}>{calculatePaymentLoading ? <CircularProgress color='secondary' size={20} /> : "$" + formatMoney(subtotal)}</Typography>
                                                            </Grid>
                                                            <Grid item={true} xs={12} sm={6}>
                                                            <Typography sx={{ fontSize: { sm: '16px' } }} className={classes.marginBottom5}>Service Charge:</Typography>
                                                            </Grid>
                                                            <Grid item={true} xs={12} sm={2} md={6}>
                                                            <Typography sx={{ fontSize: { sm: '16px', textAlign: 'right' }, fontWeight: 500, color: organizationSettings?.layoutColor }}>{calculatePaymentLoading ? <CircularProgress color='secondary' size={20} /> : "$" + formatMoney(this.state.serviceChargeTotal)}</Typography>
                                                            </Grid>
                                                            {!!order.gratuity && <>
                                                                <Grid item={true} xs={12} sm={6}>
                                                                <Typography sx={{ fontSize: { sm: '16px' } }} className={classes.marginBottom5}>Gratuity:</Typography>
                                                                </Grid>
                                                                <Grid item={true} xs={12} sm={2} md={6}>
                                                                <Typography sx={{ fontSize: { sm: '16px', textAlign: 'right', fontWeight: 500, color: organizationSettings?.layoutColor } }}>${formatMoney(order.gratuity)}</Typography>
                                                                </Grid>
                                                            </>}
                                                            <Grid item={true} xs={12} sm={6}>
                                                            <Typography sx={{ fontSize: { sm: '16px' } }} className={classes.marginBottom5}>Tax:</Typography>
                                                            </Grid>
                                                            <Grid item={true} xs={12} sm={2} md={6}>
                                                            <Typography sx={{ fontSize: { sm: '16px', textAlign: 'right' }, fontWeight: 500, color: organizationSettings?.layoutColor }}>{calculatePaymentLoading ? <CircularProgress color='secondary' size={20} /> : "$" + formatMoney(totalTax)}</Typography>
                                                            </Grid>
                                                            {this.state.gratuityTotal!==0 && <><Grid item={true} xs={12} sm={6}>
                                                            <Typography sx={{ fontSize: { sm: '16px' } }} className={classes.marginBottom5}>Gratuity:</Typography>
                                                            </Grid>
                                                            <Grid item={true} xs={12} sm={2} md={6}>
                                                            <Typography sx={{ fontSize: { sm: '16px', textAlign: 'right' }, fontWeight: 500, color: organizationSettings?.layoutColor }}>{calculatePaymentLoading ? <CircularProgress color='secondary' size={20} /> : "$" + formatMoney(this.state.gratuityTotal)}</Typography>
                                                            </Grid></>}
                                                            {orderType === "Delivery" && <><Grid item={true} xs={12} sm={6}>
                                                            <Typography sx={{ fontSize: { sm: '16px' } }} className={classes.marginBottom5}>Delivery Fee:</Typography>
                                                            </Grid>
                                                                <Grid item={true} xs={12} sm={2} md={6}>
                                                                <Typography sx={{ fontSize: { sm: '16px', textAlign: 'right' }, fontWeight: 500, color: organizationSettings?.layoutColor }}>{calculatePaymentLoading ? <CircularProgress color='secondary' size={20} /> : "$" + formatMoney(this.state.deliveryFee || 0)}</Typography>
                                                                </Grid></>}
                                                            {/* {!!order.couponDiscount && <>
                                                                        <Grid item={true} xs={12} sm={6}>
                                                                            <Typography sx={{ fontSize: {sm: '16px'}}} className={classes.marginBottom5}>Coupon Discount:</Typography>
                                                                        </Grid>
                                                                        <Grid item={true} xs={12} sm={2} md={6}>
                                                                            <Typography sx={{ fontSize: { sm: '16px', textAlign: 'right' }, fontWeight: 500, color: organizationSettings?.layoutColor }}>${formatMoney(order.couponDiscount)}</Typography>
                                                                        </Grid>
                                                                    </>} */}
                                                            <Grid item={true} xs={12} sm={6}>
                                                            <Typography sx={{ fontSize: { sm: '16px', fontWeight: 600 } }} className={classes.marginBottom5}>Total:</Typography>
                                                            </Grid>
                                                            <Grid item={true} xs={12} sm={2} md={6}>
                                                            <Typography sx={{ fontSize: { sm: '16px', fontWeight: 600, textAlign: 'right' }, color: organizationSettings?.layoutColor }}>{calculatePaymentLoading ? <CircularProgress size={20} /> : "$" + formatMoney(total)}</Typography>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                ) : <></>}
                                            {isKitchenClosed() && <Stack
                                                sx={{
                                                    background: "#ffc700",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    width: '100%',
                                                    margin: '10px auto',
                                                    borderRadius: '12px',
                                                }}
                                            >
                                                <Typography variant='caption'>Kitchen is closed at {moment(orderType === 'Event' ? moment(eventDate).format('YYYY-MM-DD') + moment(eventStart).utc().format('THH:mm:ss.SSS[Z]') : laterSchedDate).format('h:mm A')}</Typography>
                                            </Stack>}
                                            {!isOrderEmpty &&
                                                <Box sx={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    flexDirection: 'column'
                                                }}>

                                            {!organizationSettings?.useSkipify ? 
                                                <>
                                                    {organizationSettings?.enablePayNow &&
                                                        <Grid item={true} xs={12} sm={12}>
                                                            <div style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', margin: '8px' }}>
                                                                <StyledPayButton
                                                                    variant="contained"
                                                                    color="layoutColor"
                                                                    disableElevation
                                                                    disabled={isKitchenClosed() || isOrderEmpty || this.state.creatingOrder || this.payDisabled() || orderMinimumNotMet || loadPaymentUrl}
                                                                    sx={{ backgroundColor: organizationSettings?.layoutColor, '&.MuiButton-root:hover': { backgroundColor: organizationSettings?.layoutColor }  }}
                                                                    onClick={this.payNow}
                                                                >
                                                                    <Typography sx={
                                                                        {
                                                                            fontSize: { xs: '16px' },
                                                                            fontWeight: 600,
                                                                            // color: '#000000'
                                                                        }}>
                                                                        Pay Now ${formatMoney(this.state.total)}
                                                                    </Typography>
                                                                </StyledPayButton>
                                                            </div>
                                                        </Grid>
                                                    }
                                                </>
                                                : 
                                                <>
                                                    {this.state.skipifyPayButtonLoading ? 
                                                     <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                        <CircularProgress size={20} color='secondary' /> 
                                                        <p style={{fontSize: 16, paddingLeft: '8px'}}>Loading Skipify</p>
                                                    </div>: 
                                                        <>
                                                        {(isKitchenClosed() || isOrderEmpty || this.state.creatingOrderWithSkipify || this.payDisabled() || orderMinimumNotMet || loadPaymentUrl) ? 
                                                            <Grid item={true} xs={12} sm={12}>
                                                                <div style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', margin: '8px' }}>
                                                                    <StyledPayButton
                                                                        variant="contained"
                                                                        color="layoutColor"
                                                                        disableElevation
                                                                        disabled
                                                                        sx={{ backgroundColor: organizationSettings?.layoutColor, '&.MuiButton-root:hover': { backgroundColor: organizationSettings?.layoutColor } }}
                                                                    >
                                                                        {this.state.creatingOrderWithSkipify && <CircularProgress size={24} color="info" />}
                                                                        <Typography sx={
                                                                            {
                                                                                fontSize: { xs: '16px' },
                                                                                fontWeight: 600,
                                                                            }}>
                                                                            Pay Now
                                                                        </Typography>
                                                                    </StyledPayButton>
                                                                </div>
                                                            </Grid>
                                                            : 
                                                            <div style={{ padding: '8px' }}>
                                                                <SkipifyIntegration 
                                                                    cartRefId={this.state.skipifyGuid}
                                                                    merchantId={this.props.organizationSettings.skipifyMerchantID}
                                                                    onSuccess={(skipifyResponse: any)=> this.setState({skipifyResponse: skipifyResponse}, () => this.skipifySuccess())} 
                                                                    buttonColor={organizationSettings?.layoutColor}
                                                                    email={this.state.email}
                                                                    phone={this.state.phoneNumber}
                                                                    onClose={() => { this.setState({skipifyClose: true}) }}
                                                                    onClick={(value: boolean) => { this.setState({skipifyPopupOpen: value}) }}
                                                                />
                                                            </div>
                                                        }
                                                        </>
                                                    }
                                                </>
                                            }

                                                    {organizationSettings?.enablePayLater &&
                                                        <Grid item={true} xs={12} sm={12}>
                                                            <div style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', margin: '8px' }}>
                                                                <StyledPayButton
                                                                    variant="contained"
                                                                    color="layoutColor"
                                                                    disableElevation
                                                                    // disabled={orderDisabled}
                                                                    disabled={isKitchenClosed() || isOrderEmpty || this.state.creatingOrder || this.payDisabled() || orderMinimumNotMet || loadPaymentUrl}
                                                                    sx={{ backgroundColor: organizationSettings?.layoutColor, '&.MuiButton-root:hover': { backgroundColor: organizationSettings?.layoutColor } }}
                                                                    // onClick={this.payAtStore}
                                                                    onClick={this.payLater}
                                                                >
                                                                    {this.state.creatingOrder && <CircularProgress size={24} color="info" />}
                                                                    <Typography sx={
                                                                        {
                                                                            fontSize: { xs: '16px' },
                                                                            fontWeight: 600,
                                                                            // color: '#000000'
                                                                        }}>
                                                                        Pay Later
                                                                    </Typography>
                                                                </StyledPayButton>
                                                            </div>
                                                        </Grid>
                                                    }
                                                </Box>}
                                            {isOrderEmpty ? <Grid container={true} key={'empt-cart-image'} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                                                <Grid item={true} xs={12} sm={12}>
                                                    <Typography
                                                        variant="body2"
                                                        align="center"
                                                        gutterBottom={true}
                                                        style={{ position: 'sticky', color: '#000000', fontWeight: 600, transform: 'translateY(-70px)' }}
                                                    >
                                                        Your cart is empty.
                                                    </Typography>
                                                </Grid>
                                                <Grid item={true} xs={12} sm={12}>
                                                    <Box sx={{ textAlign: "center", alignItems: "center", justifyContent: "center", margin: "8px",  }}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            disableElevation={true}
                                                            disableRipple={true}
                                                            sx={{ 
                                                                minWidth: '50%',
                                                                margin: '10px', 
                                                                borderRadius: '12px', 
                                                                textTransform: 'none', 
                                                                backgroundColor: organizationSettings?.layoutColor,
                                                                '&.MuiButton-root:hover': { 
                                                                    backgroundColor: organizationSettings?.layoutColor 
                                                                }
                                                            }}
                                                            onClick={this.goToDashboard}
                                                        >
                                                            Add Items to Cart
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid> : <></>}
                                            </Grid>
                                        </Grid>
                                
                                </Container>
                            </Grid>
                        </Hidden>
                    </Grid > : <Stack alignItems={'center'} justifyContent={'center'}><CircularProgress color='secondary' /></Stack>}

                    <Dialog
                        onClose={() => this.handleCloseOpenHoursModel()}
                        aria-labelledby="customized-dialog-title"
                        open={openOpenHoursModel}
                        className={classes.paymentDialog}
                        // disableBackdropClick={true}
                        disableEscapeKeyDown={true}
                        hideBackdrop={true}
                        PaperProps={{
                            style: {
                                border: '1px solid', 
                                borderColor: organizationSettings?.layoutColor && lighten(organizationSettings?.layoutColor, 0.5),
                                borderRadius: '12px',
                                overflowX: 'hidden',
                                width: '660px'
                            },
                        }}
                    >
                        <DialogTitle
                            id="customized-dialog-title"
                            onClose={() => this.handleCloseOpenHoursModel()}
                            className={classes.dialogTitle}
                            classes={{ closeButton: classes.closeButton }}
                            sx={{ 
                                p: '0px 24px',
                                '& svg': {
                                    width: 25,
                                } }}
                        />
                        <DialogContent style={{ height: this.state.paymentDialogHeight}} sx={{p:0}}>
                            <Box>
                            <Typography sx={{ mt: 4, mb: 2, textAlign: 'center', fontSize: {xs: '20px'}, fontFamily: 'Poppins, Roboto, Helvetica, Arial, sans-serif' ,fontWeight:500}}>{organization?.name} Hours of Operation</Typography>
                            
                            <>
    <Grid container={true} spacing={2} direction={'column'} width='90%' m='0px auto'>
      {Object?.entries(schedule)?.map(([day, { timings }]) => (
        <Grid item xs={12} sm={12} key={day}>
          <Grid container sx={{ height: { md: '35px' } }}>
            <Grid item xs={12} sm={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ fontFamily: 'Poppins, Roboto, Helvetica, Arial, sans-serif', fontWeight: 400, fontSize: { xs: '16px' }, color: '#000000' }}>
                  {day?.charAt(0)?.toUpperCase() + day?.slice(1)}
                </Typography>
                <Typography sx={{ fontFamily: 'Poppins, Roboto, Helvetica, Arial, sans-serif', fontWeight: 400, fontSize: { xs: '16px' }, color: '#000000' }}>
                  {timings}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>

    <Grid container={true} spacing={2} direction={'column'} width='90%' m='0px auto'>
      <Grid item xs={12} sm={12}>
        <Grid container sx={{ height: { md: '35px' } }}>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ fontFamily: 'Poppins, Roboto, Helvetica, Arial, sans-serif', fontWeight: 500, fontSize: { xs: '20px' }, color: '#000000' }}>
              Holidays:
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <CustomScrollbarBox>
        {formattedHolidays?.map((item:any) => (
          <Grid item xs={12} sm={12} mb={1} key={item.date}>
            <Grid container sx={{ height: { md: '35px', padding: "12px 0px 12px 12px" } }}>
              <Grid item xs={12} sm={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Grid md={7}>
                    <Typography sx={{ fontFamily: 'Poppins, Roboto, Helvetica, Arial, sans-serif', fontWeight: 400, fontSize: { xs: '16px' }, color: '#000000' }}>
                        {item?.day}
                    </Typography>
                  </Grid>
                  <Grid md={7}>
                    <Typography sx={{ fontFamily: 'Poppins, Roboto, Helvetica, Arial, sans-serif', fontWeight: 400, fontSize: { xs: '16px' }, color: '#000000' }}>
                        {item?.date}
                    </Typography>
                  </Grid>
                  <Grid md={6}>
                    <Typography sx={{ fontFamily: 'Poppins, Roboto, Helvetica, Arial, sans-serif', fontWeight: 400, fontSize: { xs: '16px' }, color: '#000000',textAlign:'right', whiteSpace: "nowrap"  }}>
                        {item?.time ? item.time : 'Closed'}
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </CustomScrollbarBox>
    </Grid>
  </>
                                            
                                           
                            
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Box sx={{width:'100%',px:{xs:0,sm:4,md:6,lg:8},pb:2}}>

                                            <StyledPayButton
                                                    variant="contained"
                                                    color="layoutColor"
                                                    disableElevation
                                                    sx={{ backgroundColor: organizationSettings?.layoutColor, '&.MuiButton-root:hover': { backgroundColor: organizationSettings?.layoutColor }  }}
                                                    onClick={() => this.handleCloseOpenHoursModel()}
                                                    >
                                                    <Typography sx={
                                                        {
                                                            fontSize: { xs: '16px' },
                                                            fontWeight: 600,
                                                            // color: '#000000'
                                                        }}>
                                                        Close
                                                    </Typography>
                                                </StyledPayButton>
                                                            </Box>
                                        </DialogActions>
                    </Dialog>





                    <Dialog
                        onClose={() => this.handleClosePolicyModel()}
                        aria-labelledby="customized-dialog-title"
                        open={openPolicyModel}
                        className={classes.paymentDialog}
                        // disableBackdropClick={true}
                        disableEscapeKeyDown={true}
                        hideBackdrop={true}
                        PaperProps={{
                            style: {
                                border: '1px solid', 
                                borderColor: organizationSettings?.layoutColor && lighten(organizationSettings?.layoutColor, 0.5),
                                borderRadius: '12px',
                                overflowX: 'hidden',
                                width: '660px'
                            },
                        }}
                    >
                        <DialogTitle
                            id="customized-dialog-title"
                            onClose={() => this.handleClosePolicyModel()}
                            className={classes.dialogTitle}
                            classes={{ closeButton: classes.closeButton }}
                            sx={{ 
                                p: '0px 24px',
                                '& svg': {
                                    width: 25,
                                } }}
                        />
                        <DialogContent style={{ height: this.state.paymentDialogHeight}} sx={{p:0}}>
                            <Box>
                            <Typography sx={{ mt: 4, mb: 2, textAlign: 'center', fontSize: {xs: '20px'}, fontFamily: 'Poppins, Roboto, Helvetica, Arial, sans-serif' ,fontWeight:500}}>{organization?.name} Store Policy</Typography>
                            
                            <>
    
    <Grid container={true} spacing={2} direction={'column'} width='90%' m='0px auto'>
      <Grid item xs={12} sm={12}>
        <Grid container >
          <Grid item xs={12} sm={12} mt={2}>
            <Typography sx={{ fontFamily: 'Poppins, Roboto, Helvetica, Arial, sans-serif', fontWeight: 500, fontSize: { xs: '20px' }, color: '#000000' }}>
              Advanced Notice Policy:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} mt={1}>
            <Typography sx={{ fontFamily: 'Poppins, Roboto, Helvetica, Arial, sans-serif', fontWeight: 400, fontSize: { xs: '17px' }, color: '#000000' }}>
               {organization?.name} requires a minimum of {organizationSettings?.ordersAdvanceNoticeHours} Hours notice on all orders submitted.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Grid container >
          <Grid item xs={12} sm={12} mt={2}>
            <Typography sx={{ fontFamily: 'Poppins, Roboto, Helvetica, Arial, sans-serif', fontWeight: 500, fontSize: { xs: '20px' }, color: '#000000' }}>
              Additional Notice:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} mt={1}>
            <Typography sx={{ fontFamily: 'Poppins, Roboto, Helvetica, Arial, sans-serif', fontWeight: 400, fontSize: { xs: '17px' }, color: '#000000' }}>
            Order times might also be affected by menu item prep times or delivery times.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Grid container >
          <Grid item xs={12} sm={12} mt={2}>
            <Typography sx={{ fontFamily: 'Poppins, Roboto, Helvetica, Arial, sans-serif', fontWeight: 500, fontSize: { xs: '20px' }, color: '#000000' }}>
            Store Contact Information:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} mt={2} mb={2}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>

            <Typography sx={{ fontFamily: 'Poppins, Roboto, Helvetica, Arial, sans-serif', fontWeight: 400, fontSize: { xs: '17px' }, color: '#000000' }}>
            Phone
            </Typography>
            <Typography sx={{ fontFamily: 'Poppins, Roboto, Helvetica, Arial, sans-serif', fontWeight: 400, fontSize: { xs: '17px' }, color: '#000000' }}>
            {organization?.contactPhone}
            </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} mt={1} mb={2}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>

            <Typography sx={{ fontFamily: 'Poppins, Roboto, Helvetica, Arial, sans-serif', fontWeight: 400, fontSize: { xs: '17px' }, color: '#000000' }}>
            Email
            </Typography>
            <Typography sx={{ fontFamily: 'Poppins, Roboto, Helvetica, Arial, sans-serif', fontWeight: 400, fontSize: { xs: '17px' }, color: '#000000' }}>
            {organization?.contactEmailAddress}
            </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} mt={1} mb={2}>
    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>

        <Typography sx={{ fontFamily: 'Poppins, Roboto, Helvetica, Arial, sans-serif', fontWeight: 400, fontSize: { xs: '17px' }, color: '#000000', flexGrow: 1, maxWidth: '30%' }}>
            Address
        </Typography>

        <Box sx={{ flexGrow: 1, maxWidth: '70%', textAlign: 'right' }}>
            <Typography sx={{ fontFamily: 'Poppins, Roboto, Helvetica, Arial, sans-serif', fontWeight: 400, fontSize: { xs: '17px' }, color: '#000000' }}>
                {organization?.address1}
            </Typography>
        </Box>
        
    </Box>
</Grid>
        </Grid>
      </Grid>
    </Grid>
  </>
                                            
                                           
                            
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Box sx={{width:'100%',px:{xs:0,sm:4,md:6,lg:8},pb:2}}>

                                            <StyledPayButton
                                                    variant="contained"
                                                    color="layoutColor"
                                                    disableElevation
                                                    sx={{ backgroundColor: organizationSettings?.layoutColor, '&.MuiButton-root:hover': { backgroundColor: organizationSettings?.layoutColor }  }}
                                                    onClick={() => this.handleClosePolicyModel()}
                                                    >
                                                    <Typography sx={
                                                        {
                                                            fontSize: { xs: '16px' },
                                                            fontWeight: 600,
                                                            // color: '#000000'
                                                        }}>
                                                        Close
                                                    </Typography>
                                                </StyledPayButton>
                                                            </Box>
                                        </DialogActions>
                    </Dialog>







                    <Dialog
                        // onClose={() => this.handleClosePayMethodModel()}
                        aria-labelledby="customized-dialog-title"
                        open={openPayMethodModel}
                        className={classes.paymentDialog}
                        // disableBackdropClick={true}
                        disableEscapeKeyDown={true}
                        hideBackdrop={true}
                        PaperProps={{
                            style: {
                                border: '3px solid', 
                                borderColor: organizationSettings?.layoutColor && lighten(organizationSettings?.layoutColor, 0.5),
                                borderRadius: '12px',
                                overflowX: 'hidden',
                                width: '675px',
                                maxWidth: '675px',
                                maxHeight: 'calc(100% - 40px)',
                            },
                        }}
                    >
                        <DialogTitle
                            id="customized-dialog-title"
                            onClose={() => this.handleClosePayMethodModel()}
                            className={classes.dialogTitle}
                            classes={{ closeButton: classes.closeButton }}
                            sx={{ 
                                p: '0px 24px',
                                '& svg': {
                                    width: 25,
                                } }}
                        />
                        <DialogContent style={{ height: '100%'}} sx={{p:0}}>
                            <Box>
                                <Typography sx={{ mb: 2, textAlign: 'center', fontSize: { xs: '20px' }, fontFamily: 'Verdana, sans-serif', fontWeight: 400 }}>Payment Details</Typography>
                              {
                                this.state.paymentDeclineError &&
                                <div>
                                     <Typography sx={{ mb: 2, color:'red', textAlign: 'center', fontSize: { xs: '20px' },
                                      fontFamily: 'Verdana, sans-serif', fontWeight: 400 }}>{this.state.paymentDeclineError}</Typography>
                                <Typography sx={{ mb: 2, textAlign: 'center', fontSize: { xs: '20px' }, fontFamily: 'Verdana, sans-serif',}}>
                                <Button variant="contained" color="info" 
                                onClick={this.payNow}>Retry</Button>
                                </Typography>
                                </div>
                              }
                              {!this.state.paymentDeclineError &&
                              <div>
                              {!paymentPayload &&
                                    <form name='hpay-model-window' style={{ alignItems: 'center' }}>
                                        {/* <p>Tip : </p><input type="text" name="tipValue" id="tipValue" value="" /> */}
                                        {orderType === 'Pickup' &&
                                            <Box sx={{ position: 'relative', marginX: "30px"}}>
                                                <Grid container={true}>
                                                    <Grid item xs={12} sm={12}>
                                                        <Grid container sx={{marginBottom: '10px'}}>
                                                            <Grid item={true} xs={4} sm={4}>
                                                                <Typography sx={{ fontFamily: 'Verdana, sans-serif', fontWeight: 400, fontSize: { xs: '16px', color: '#000000' } }}>Pickup Address:</Typography>
                                                            </Grid>
                                                            <Grid item={true} xs={8} sm={8}>
                                                                <Typography sx={{ fontFamily: 'Verdana, sans-serif', fontWeight: 400, fontSize: { xs: '16px', color: '#000000' } }}>{`${organization?.address1 ? `${organization?.address1},` : ""} ${organization?.address2 ? `${organization?.address2}` : ""}`} {organization?.city ? `${organization?.city},` : ""} {organization?.stateProvince ? `${organization?.stateProvince},` : ""} {organization?.postalCode ? `${organization?.postalCode}` : ""}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <Grid container sx={{marginBottom: '10px'}}>
                                                            <Grid item={true} xs={4} sm={4}>
                                                                <Typography sx={{ fontFamily: 'Verdana, sans-serif', fontWeight: 400, fontSize: { xs: '16px' }, color: '#000000', paddingRight: { xs: '1px', sm: '0px' } }}>Pickup Time:</Typography>
                                                            </Grid>
                                                            <Grid item={true} xs={8} sm={8}>
                                                                <Typography sx={{ fontFamily: 'Verdana, sans-serif', fontWeight: 400, fontSize: { xs: '16px', color: '#000000' } }}>{moment(laterSchedDate).format('h:mma')}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        }
                                        {orderType === 'Delivery' &&
                                                    <Box sx={{ position: 'relative', marginX: "31px" }}>
                                                <Grid container={true}>
                                                    <Grid item xs={12} sm={12}>
                                                        <Grid container sx={{marginBottom: '10px'}}>
                                                            <Grid item={true} xs={4} sm={4}>
                                                                <Typography sx={{ fontFamily: 'Verdana, sans-serif', fontWeight: 400, fontSize: { xs: '16px', color: '#000000' } }}>Delivery Address:</Typography>
                                                            </Grid>
                                                            <Grid item={true} xs={8} sm={8}>
                                                                <Typography sx={{ fontFamily: 'Verdana, sans-serif', fontWeight: 400, fontSize: { xs: '16px', color: '#000000' } }}>{replaceComma(this.getOrderDeliveryAddress()?.address1 || "")} {this.getOrderDeliveryAddress()?.city} {this.getOrderDeliveryAddress()?.stateProvince} {this.getOrderDeliveryAddress()?.postalCode}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12}>
                                                        <Grid container sx={{marginBottom: '10px'}}>
                                                            <Grid item={true} xs={4} sm={4}>
                                                                <Typography sx={{ fontFamily: 'Verdana, sans-serif', fontWeight: 400, fontSize: { xs: '16px' }, color: '#000000' }}>Delivery Time:</Typography>
                                                            </Grid>
                                                            <Grid item={true} xs={8} sm={8}>
                                                                <Typography sx={{ fontFamily: 'Verdana, sans-serif', fontWeight: 400, fontSize: { xs: '16px', color: '#000000' } }}>{moment(laterSchedDate).format('h:mma')}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    {this.state.orderType === "Delivery" && <Grid item xs={12} sm={12}>
                                                        <Grid container sx={{marginBottom: '10px'}}>
                                                            <Grid item={true} xs={4} sm={4}>
                                                                <Typography sx={{ fontFamily: 'Verdana, sans-serif', fontWeight: 400, fontSize: { xs: '16px' }, color: '#000000' }}>Delivery Fee:</Typography>
                                                            </Grid>
                                                            <Grid item={true} xs={8} sm={8}>
                                                                <Typography sx={{ fontFamily: 'Verdana, sans-serif', fontWeight: 400, fontSize: { xs: '16px', color: '#000000' } }}>{"$" + formatMoney(this.state.deliveryFee || 0)}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>}
                                                </Grid>
                                            </Box>
                                        }
                                        <iframe id="payment-form" title="Payment form" scrolling="auto" className={classes.iframeRoot}
                                            src={this.state.paymentURL || ''} >
                                        </iframe>
                                    </form>
                            }
                                {paymentPayload && this.state.cardDetailsLoader && <Stack sx={{ p: 10 }} alignItems={'center'} justifyContent={'center'}>
                                    <CircularProgress size={50} color="info" />
                                </Stack>}
                            {(paymentPayload && !this.state.cardDetailsLoader) && <>
                                <Grid container={true} spacing={1} style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                    <Grid item={true} xs={6} sm={6}>
                                        <Typography variant="body1" className={classes.title} >Total Due:</Typography>
                                    </Grid>
                                    <Grid item={true} xs={6} sm={6}>
                                        <Typography variant="body1" className={classes.marginBottom5}>${formatMoney((paymentPayload.amount / 100))}</Typography>
                                    </Grid>

                                    <Grid item={true} xs={6} sm={6}>
                                        <Typography variant="body1" className={classes.orderTitle}>Tip Amount:</Typography>
                                    </Grid>

                                    <Grid item={true} xs={6} sm={6}>
                                        <Typography variant="body1" className={classes.marginBottom5}>${formatMoney(paymentPayload.tip / 100)}</Typography>
                                    </Grid>

                                    {this.state.orderType === "Delivery" && <><Grid item={true} xs={6} sm={6}>
                                        <Typography variant="body1" className={classes.orderTitle}>Delivery Fee:</Typography>
                                    </Grid>

                                        <Grid item={true} xs={6} sm={6}>
                                            <Typography variant="body1" className={classes.marginBottom5}>${formatMoney(this.state.deliveryFee || 0)}</Typography>
                                        </Grid></>}


                                    <Grid item={true} xs={6} sm={6}>
                                        <Typography className={classes.title}>Sub Total:</Typography>
                                    </Grid>

                                    <Grid item={true} xs={6} sm={6}>
                                        <Typography variant="body1" className={classes.marginBottom5}>${formatMoney((paymentPayload.amount / 100) + (paymentPayload.tip / 100))}</Typography>
                                    </Grid>

                                    <Grid item={true} xs={6} sm={6}>
                                        <Typography variant="body1" className={classes.title}>Card Type</Typography>
                                    </Grid>

                                    <Grid item={true} xs={6} sm={6}>
                                        <Typography variant="body1" className={classes.marginBottom5}>{paymentConfirmation?.credit_card?.display_name}</Typography>
                                    </Grid>

                                    <Grid item={true} xs={12} sm={12}>
                                        <div style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', margin: '8px' }}>
                                            <Button
                                                variant="contained"
                                                sx={{ bgcolor: 'green' }}
                                                color="success"
                                                disabled={this.state.creatingOrder}
                                                style={{ minWidth: '50%', margin: '10px', borderRadius: '50px' }}
                                                onClick={this.makePayment}
                                            >
                                                {this.state.creatingOrder && <CircularProgress size={24} color="info" />} &nbsp; Confirm Pay
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </>}
                              </div>
                              }
                          
                            </Box>
                        </DialogContent>
                    </Dialog>
                    <Snackbar
                        open={this.state.orderCreateError}
                        autoHideDuration={3000}
                        onClose={() => this.setState({ orderCreateError: false, orderCreateErrorMessage: "" })}
                    >
                        <SnackbarContent sx={{
                            backgroundColor: '#d32f2f',
                        }}
                            message={<Stack direction={'row'} alignItems={'center'} spacing={2}>
                                <span>{this.state.orderCreateErrorMessage || "Something went wrong, can't create order."}</span>
                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => {
                                        this.setState({ orderCreateError: false, orderCreateErrorMessage: "" })
                                    }}
                                >
                                    <Close fontSize="small" />
                                </IconButton></Stack>}
                        />
                    </Snackbar>

                </div >
             </Auth0Provider>
        )
    }
}




const mapStateToProps = (state: AppState) => ({
    utility: state.utility,
    oidc: state.user?.user,
    order: state.order,
    user: state?.user?.auth0UserDetails,
    appUserDetails: state?.user?.appUserDetails,
    isGuestLogin: !!state.user && state.user?.user?.profile?.name === 'Guest',
    isLoggedIn: !!state.user.auth0UserDetails,
    locationId: state.user ? state.user?.user?.profile?.location_id : '',
    settings: state.settings?.SettingsKeyValue,
    customer: state.customer,
    rooms: state?.room?.rooms,
    eventPickLists: state?.pickList?.eventPickLists,
    organizationSettings: state?.organizations?.organizationSettings,
    organizationOrdersOpenSchedule: state?.organizations?.organizationOrdersOpenSchedule,
    organizations: state?.organizations,
    organization: state?.organizations?.organization,
    userPersonalDetails: state?.organizations?.userDetails,
    layoutColor: state?.organizations?.organizationSettings?.layoutColor,
})

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators({
        orderGet: OrderActions.get,
        orderPut: OrderActions.put,
        orderSetDefault: OrderActions.setDefault,
        orderRemoveItem: OrderActions.removeItem,
        // onlineOrdersEnabled: OrderActions.onlineOrdersEnabled,
        applyCoupon: OrderActions.ApplyCoupon,
        removeCoupon: OrderActions.RemoveCoupon,
        contactPost: ContactActions.post,
        handleClickOpenSignInModel: AppActions.handleClickOpenSignInModel,
        makePayment: PaymentActions.makePayment,
        openProductDetailPageModel,
        selectedCategoryMenuProduct,
        getSettingAreas,
        getRooms,
        // getEventPickLists,
        upsertAddress: UpsertAddress,
        createOrderGuest: createGuestOrder,
        clearCart: OrderActions.removeCart,
        getPaymentCardDetails: getPaymentCardDetails,
        getSetupIntentDetails: getSetupIntentDetail,
        postPaymentForOrder: postPaymentForOrder,
        getUserByEmailId: OrderActions.getUserByEmailId,
        registerUser: OrderActions.registerUser,
        fetchCalculatedPayment: getCalculatedPayment,
        fetchCalculatedEstimatedTime: getCalculatedEstimatedTime,
        addressesGet: ContactActions.getSavedAddresses,
        fetchUndeliverablePostalCodes: getUndeliverablePostalCodes,
        postCreatePaymentLink: postCreatePaymentLink,
        getPickListOptionsFromAPI: getEventPicklistsOptions,
        getPickLists: getPickListsByOrganization,
        createNewAddress:ContactActions.createNewAddress
    }, dispatch);

const ProceedToCheckoutView = withRouter(ProceedToCheckout);
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(ProceedToCheckoutView as any));