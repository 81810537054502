import React, { useEffect, useRef } from "react";

interface SkipifyIntegrationProps {
  cartRefId: string;
  onSuccess: any;
  onClose: any;
  buttonColor: string;
  email?: string;
  phone?: string;
  merchantId: string;
  onClick: any;
}

declare global {
  interface Window {
    skipify: any;
  }
}

const SkipifyIntegration: React.FC<SkipifyIntegrationProps> = ({
  cartRefId,
  onSuccess,
  onClose,
  buttonColor,
  email,
  phone,
  merchantId,
  onClick,
}) => {
  const buttonInstanceRef = useRef<any>(null);
  const skipifyClientRef = useRef<any>(null);

  // Update Skipify options when email/phone change
  useEffect(() => {
    if (buttonInstanceRef.current) {
      buttonInstanceRef.current.setOptions({
        email: email,
        phone: phone,
      });
    }
  }, [email, phone]);

  // Main initialization effect
  useEffect(() => {
    const initializeSkipify = () => {
      if (typeof window.skipify !== "undefined") {
        // Initialize client if needed
        if (!skipifyClientRef.current || merchantId !== skipifyClientRef.current.merchantId) {
          skipifyClientRef.current = new window.skipify({
            merchantId: merchantId || "",
          });
        }

        const options = {
          onClose: (myReference: string, success: boolean) => {
            onClick(false);
            if (success) onClose();
          },
          onApprove: (myReference: string, data: any) => {
            onSuccess({ myReference, data });
          },
          onClick: (myReference: string) => {
            onClick(true);
          },
          textColor: "#FFFFFF",
          bgColor: buttonColor || "#1E1E1E",
          bgHoverColor: buttonColor || "#444444",
          logoPlacement: "below",
          buttonLabel: "Pay Now",
          email: email,
          phone: phone,
        };

        const container = document.getElementById("skipify-pay-checkout-container");
        if (container) container.innerHTML = "";

        // Create new button instance
        const buttonInstance = skipifyClientRef.current.button(cartRefId, options);
        buttonInstance.render(container);
        buttonInstanceRef.current = buttonInstance;
      }
    };

    const scriptId = "skipify-sdk-script";
    let script = document.getElementById(scriptId) as HTMLScriptElement | null;

    if (!script) {
      script = document.createElement("script");
      script.id = scriptId;
      script.src = process.env?.REACT_APP_ENVIRONMENT === "production" 
        ? "https://cdn.skipify.com/sdk/checkoutsdk.js" 
        : "https://stagecdn.skipify.com/sdk/checkoutsdk.js";
      script.async = true;
      script.onload = initializeSkipify;
      document.body.appendChild(script);
    } else {
      initializeSkipify();
    }

    return () => {
      // Cleanup if needed
    };
  }, [merchantId, cartRefId]); // Only reinitialize when these change

  return <div id="skipify-pay-checkout-container" />;
};

export default SkipifyIntegration;